import React from "react";
import {
  ChooseLang,
  CopyRight,
  MainContainer,
  Nav,
  RightContainer,
  Signin,
  Signintext,
} from "../styles/Styles";
import image from "../assests/image.png";
import "react-toastify/dist/ReactToastify.css";
import SignUpForm from "../components/SignUpForm";
import FormBanner from "../components/FormBanner";
import FormHeader from "../components/FormHeader";
import LanguageSelect from "../components/LanguageSelect";
import { useLiterals } from "../context/LiteralsContext";

export default function SignUp() {
  const literals = useLiterals();

  return (
    <MainContainer>
      <FormBanner />
      <ChooseLang
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          right: 0,
          top: 0,
          margin: 20,
        }}
      >
        {/* <Signintext style={{ marginTop: "5%" }}>
          {literals?.placeholder_text?.choose_language}
        </Signintext> */}
        <LanguageSelect width="100%" />
      </ChooseLang>
      <RightContainer>
        <div
          style={{
            marginTop: "10%",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormHeader
            image={image}
            title={literals?.placeholder_text?.signup_title}
            subTitle={literals?.placeholder_text?.signup_desc}
          />
          <SignUpForm
            emailpattern={"^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"}
            literals={literals}
          />
          <Signin>
            <Signintext>{literals?.placeholder_text?.signup_info}</Signintext>
            <Nav to="/login">{literals?.buttons?.signin_btn}</Nav>
          </Signin>
        </div>
        <CopyRight>{literals?.email_content?.copyrights}</CopyRight>
      </RightContainer>
    </MainContainer>
  );
}
