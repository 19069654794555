import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout, Menu, Spin, theme } from "antd";
import { FaBuffer } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { GiSettingsKnobs } from "react-icons/gi";
import { PiTreeStructure } from "react-icons/pi";
import { IoLibrary } from "react-icons/io5";
import { GiConversation } from "react-icons/gi";
import image from "../assests/image.png";
import AlertMessage from "../components/AlertMessage";
import MainHeader from "../components/MainHeader";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import AnswerModal from "../components/AnswerModal";
import { EmailClient } from "@azure/communication-email";
import EditUserModal from "../components/EditUserModal";
import InstructionModal from "../components/InstructionModal";
import {
  AzureConnectionUrl,
  EnteriesHeaders,
  fetchSignUpEmailData,
  getContentEntriesEndpoint,
  updateContentEntriesEndpoint,
} from "../context/constants";
import UpdateCompanyModal from "../components/UpdateCompanyModal";
import AddUserModal from "../components/AddUserModal";
import CompanyPage from "../components/CompanyPage";
import ContactsPage from "../components/ContactsPage";
import SubscriptionPage from "../components/SubscriptionPage";
import OrganizationTabs from "../components/OrganizationTabs";
import SubMenu from "antd/es/menu/SubMenu";
import { MdCollectionsBookmark } from "react-icons/md";
import debounce from "lodash/debounce";
import _ from "lodash";
import { useLiterals } from "../context/LiteralsContext";
import { BsQuestionCircle } from "react-icons/bs";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";

// import EditUserModal from "../components/EditUserModal";

const { Sider, Content } = Layout;

const marks = {
  0: "0%",
  20: "20%",
  40: "40%",
  60: "60%",
  80: "80%",
  100: "100%",
};

export default function Organization() {
  const literals = useLiterals();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("company");
  const [modalVisible, setModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [showUserTable, setShowUserTable] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [contactUsers, setContactUsers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  // const [temp, setTemp] = useState("0.5");
  const [signUpEmailData, setSignUpEmailData] = useState(null);
  const [deniedDomains, setDeniedDomains] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [isdialogVisible, setIsDialogModalVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenSentSuccessfully, setTokenSentSuccessfully] = useState(false);
  const [pleaseTryAgain, setPleaseTryAgain] = useState(false);
  const [companyDetailsUpdated, setCompanyDetailsUpdated] = useState(false);
  const [failedToUpdateCompanyDetails, setFailedToUpdateCompanyDetails] =
    useState(false);
  const [
    errorUpdatingCreativePrecisionValue,
    setErrorUpdatingCreativePrecisionValue,
  ] = useState(false);

  const handleOpenErrorUpdatingCreativePrecisionValue = () => {
    setErrorUpdatingCreativePrecisionValue(true);
  };
  const handleCloseErrorUpdatingCreativePrecisionValue = () => {
    setErrorUpdatingCreativePrecisionValue(false);
  };

  const handleOpenTokenSentSuccessfully = () => {
    setTokenSentSuccessfully(true);
  };
  const handleCloseTokenSentSuccessfully = () => {
    setTokenSentSuccessfully(false);
  };

  const handleOpenPleaseTryAgain = () => {
    setPleaseTryAgain(true);
  };
  const handleClosePleaseTryAgain = () => {
    setPleaseTryAgain(false);
  };

  const handleOpenCompanyDetailsUpdated = () => {
    setCompanyDetailsUpdated(true);
  };
  const handleCloseCompanyDetailsUpdated = () => {
    setCompanyDetailsUpdated(false);
  };

  const handleOpenFailedToUpdateCompanyDetails = () => {
    setFailedToUpdateCompanyDetails(true);
  };
  const handleCloseFailedToUpdateCompanyDetails = () => {
    setFailedToUpdateCompanyDetails(false);
  };

  const locale = localStorage.getItem("locale");

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const [temp, setTemp] = useState(() => {
    const storedTemp = localStorage.getItem("temp");
    return storedTemp !== null ? parseFloat(storedTemp) : 0.5;
  });

  // useEffect(() => {
  //   setSelectedCategory("subscriptionPlan");
  // }, []);

  const paymentStat = location.state?.paymentStatus;

  useEffect(() => {
    if (location.state?.activeTab) {
      setSelectedCategory(location.state.activeTab);
    }
  }, [location.state]);

  const handleSliderChange = (value) => {
    switch (value) {
      case 0:
        setTemp(1.0);
        break;
      case 20:
        setTemp(0.8);
        break;
      case 40:
        setTemp(0.6);
        break;
      case 60:
        setTemp(0.4);
        break;
      case 80:
        setTemp(0.2);
        break;
      default:
        setTemp(0.0);
    }
  };

  const textareaRef = useRef(null);
  const navigate = useNavigate();

  const userDataString = localStorage.getItem("userData");
  const userInfo = JSON.parse(userDataString);

  const primaryUser = localStorage.getItem("primaryUser");

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [searchInput]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleOpenAdduserModal = () => {
    setAddUserModalVisible(true);
  };

  const handleCloseAdduserModal = () => {
    setAddUserModalVisible(false);
  };

  const handleOpenAnswerModal = () => {
    setAnswerModalVisible(true);
  };

  const handleCloseAnswerModal = () => {
    setAnswerModalVisible(false);
  };
  const handleOpenIsDialogModal = () => {
    setIsDialogModalVisible(true);
  };

  const handleCloseIsDailogModal = () => {
    setIsDialogModalVisible(false);
  };

  // console.log("values", editModalRecord)

  const handleManageUsers = () => {
    setShowUserTable(true);
  };

  const fetchDeniedDomains = async () => {
    try {
      const ddContentType = "denied_domains";
      const endPoint = getContentEntriesEndpoint(ddContentType, locale);
      const headers = EnteriesHeaders;
      const response = await axios.get(endPoint, { headers });
      setDeniedDomains(response.data.entries);
    } catch (error) {
      console.log("Failed to fetch denied domains");
    }
  };

  useEffect(() => {
    fetchDeniedDomains();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSignUpEmailData(locale);
        setSignUpEmailData(response);
      } catch (error) {
        console.error("Error fetching signup email data:", error);
      }
    };

    fetchData();
  }, []);

  const handleAddNewUser = async (values) => {
    try {
      const emailDomain = values.title.split("@")[1];
      const deniedDomainsList = deniedDomains.map((entry) => entry.title);
      if (deniedDomainsList.includes(emailDomain)) {
        throw new Error("Only professional emails are allowed");
      }

      const euContentType = "application_user";
      const euEndpoint = getContentEntriesEndpoint(euContentType, locale);
      const existingUserResponse = await axios.get(euEndpoint, {
        headers: EnteriesHeaders,
      });
      if (
        existingUserResponse.data.entries.find(
          (userEmail) => userEmail.title === values.title
        )
      ) {
        throw new Error("This email is already registered.");
      }

      const endPoint = euEndpoint;
      const headers = EnteriesHeaders;
      const payload = {
        entry: {
          user_name: values.user_name,
          company_name: companyData.title,
          title: values.title,
          contact_number: values.contact_number,
          preferences_and_settings: {
            library: values.library,
          },
        },
      };
      const res = await axios.post(endPoint, payload, { headers });
      const connectionString = AzureConnectionUrl;
      const client = new EmailClient(connectionString);

      const emailMessage = {
        senderAddress: signUpEmailData?.sender_email,
        content: {
          subject: signUpEmailData?.email_subject,
          html: `<!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <script
                src="https://kit.fontawesome.com/fab1f8bdcb.js"
                crossorigin="anonymous"
              ></script>
              <title>Email Template</title>
            </head>
            <body>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #dcf0ec;
                "
              >
                <div
                  style="
                    background-color: #ffffff;
                    margin-top: 5%;
                    margin-bottom: 5%;
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                <img
                src="https://images.contentstack.io/v3/assets/blt6f0f282d20bbe00b/blt58e0bed74aba1c39/65dcad6b6e7edbf719b4ec54/WhatsApp_Image_2024-02-08_at_17.16.53.jpeg"
                style="object-fit: cover; padding-top: 20px"
                alt="ChemAId Logo"
                width="158px"
              />
              <hr style="height: 1px; border: none; background-color: #3ac1cb" />
              <div style="padding-left: 30px; padding-right: 30px;">
                <p
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 36px;
                    opacity: 80%;
                  "
                >
                  Dear <strong>${values.user_name}</strong>,
                </p>
                <p
                style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              "
                >
                  ${signUpEmailData?.email_message?.json?.children[2]?.children[0]?.text}
                </p>
      
                <div
                  style="display: flex; align-items: center; justify-content: center"
                >
                  <div style="background-color: #eef0f8; padding: 3px 20px">
                    <p
                      id="textToCopy"
                      style="font-size: 16px; font-weight: 400; opacity: 40%"
                    >
                      <i class="fa-solid fa-lock"></i>
                      ${res.data.entry.uid}
                    </p>
                  </div>
      
                  <button
                    onclick="copyText()"
                    style="
                      font-size: 16px;
                      font-weight: 500;
                      color: #ffffff;
                      border: none;
                      background-color: #3ac1cb;
                      padding: 20px;
                      cursor: pointer;
                    "
                  >
                    <i class="fa-regular fa-copy"></i> Copy
                  </button>
                </div>      
                <p
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  "
                >
                  ${signUpEmailData?.email_message?.json?.children[6]?.children[0]?.text}
                </p>
                <p
                style="
                  font-size: 14px;
                  font-weight: 400;
                  opacity: 80%;
                  line-height: 20px;
                "
              >
                ${signUpEmailData?.email_message?.json?.children[8]?.children[0]?.text}
              </p>
                <ul>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[0]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[0]?.children[0]?.children[1]?.text}</p>
                  </li>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[1]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[1]?.children[0]?.children[1]?.text}</p>
                  </li>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[1]?.text}<strong>${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[2]?.text}</strong></p>
                  </li>
                </ul>
                <p style="
                font-size: 14px;
                opacity: 80%;
                line-height: 20px;
              "><strong>${signUpEmailData?.email_message?.json?.children[10]?.children[0]?.text}</strong>${signUpEmailData?.email_message?.json?.children[10]?.children[1]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[11]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[12]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[14]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[15]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                opacity: 80%;
                line-height: 20px;
              "><strong>${signUpEmailData?.email_message?.json?.children[15]?.children[2]?.text}</strong></p>
                <p
                  style="
                    font-size: 15px;
                    font-weight: 400;
                    opacity: 60%;
                    text-align: center;
                    line-height: 22.5px;
                  "
                >
                2023 CHEM&#8226;AID<sup>TM</sup>&#9135; All rights reserved.
                </p>
              </div>
                </div>
              </div>
              <script>
                  function copyText() {
                  const textToCopy = document.getElementById("textToCopy").innerText;
                  navigator.clipboard.writeText(textToCopy)
                  .then(() => {
                  alert('Copied to clipboard!');
                  })
                  .catch((error) => {
                  console.error('Unable to copy:', error);
                  alert('Failed to copy text!');
                  });
                  }
              </script>
            </body>
          </html>
          `,
        },
        recipients: {
          to: [{ address: values.title }],
          bcc: [{ address: signUpEmailData.bcc }],
        },
      };

      const poller = await client.beginSend(emailMessage);
      await poller.pollUntilDone();

      // showToast("User registered and token send successfully", "success");
      // showToast(
      //   `${literals?.toast_messages_text?.user_registered_and_token_send_successfully}`,
      //   "success"
      // );
      handleOpenTokenSentSuccessfully();
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (error) {
      // showToast("Registration failed Please try again", "error");
      // showToast(
      //   `${literals?.toast_messages_text?.registration_failed_please_try_again}`,
      //   "error"
      // );
      handleOpenPleaseTryAgain();
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const udCOntentType = "application_user";
        const uduid = userInfo?.uid;
        const endPoint = updateContentEntriesEndpoint(
          udCOntentType,
          uduid,
          locale
        );
        const headers = EnteriesHeaders;

        const response = await axios.get(endPoint, { headers });
        setUserData(response.data);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    fetchUserData();
  }, [locale]);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const fcContentType = "companies";
        const fcUid = userInfo?.company_uid;
        const endPoint = updateContentEntriesEndpoint(
          fcContentType,
          fcUid,
          locale
        );

        const fcauContentType = "application_user";
        const usersEndPoint = getContentEntriesEndpoint(
          fcauContentType,
          locale
        );

        const headers = EnteriesHeaders;
        const response = await axios.get(endPoint, {
          headers,
        });
        const userResponse = await axios.get(usersEndPoint, {
          headers,
        });

        const employees = userResponse.data.entries.filter(
          (emp) => emp.company_name === companyData?.title
        );
        setTableData(employees);

        const companyInfo = response.data.entry;
        setContactUsers(companyInfo);
        setCompanyData(companyInfo);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    fetchCompany();
  }, [locale, userInfo, companyData]);

  const updateCompanyEntries = async (values) => {
    const ucContentType = "companies";
    const ucuid = companyData?.uid;
    const endpoint = updateContentEntriesEndpoint(ucContentType, ucuid, locale);
    const headers = EnteriesHeaders;
    const payload = {
      entry: {
        title: values.title,
        email: values.email,
        street: values.street,
        city: values.city,
        state: values.state,
        zip: values.zip,
        country: values.country,
      },
    };
    const ucauContentType = "application_user";
    const ucauuid = userInfo?.uid;
    const userEndpoint = updateContentEntriesEndpoint(
      ucauContentType,
      ucauuid,
      locale
    );
    const userPayload = {
      entry: {
        company_name: values.title,
      },
    };
    try {
      await axios.put(endpoint, payload, { headers });
      await axios.put(userEndpoint, userPayload, { headers });
      // showToast(
      //   `${literals?.toast_messages_text?.company_details_updated_successfully}`,
      //   "success"
      // );
      handleOpenCompanyDetailsUpdated();
    } catch (error) {
      // showToast(
      //   `${literals?.toast_messages_text?.failed_to_update_company_details_please_check_your_input_and_try_again}`,
      //   "error"
      // );
      handleOpenFailedToUpdateCompanyDetails();
    }
  };
  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };
  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };

  const handlePaymentStatus = () => {
    const params = new URLSearchParams(window.location.search);
    const paymentStatusParam = params.get("payment_status");

    if (paymentStatusParam !== null && paymentStatusParam !== undefined) {
      setPaymentStatus(paymentStatusParam);
    }
  };

  useEffect(() => {
    handlePaymentStatus();
  }, []);

  const handleToggleTableState = () => {
    setShowUserTable(!showUserTable);
  };

  const setUserPrefSettings = async () => {
    try {
      const contentType = "application_user";
      const contentUid = userInfo?.uid;
      if (!contentUid) {
        console.error("User data is missing or invalid.");
        return;
      }

      const endpoint = updateContentEntriesEndpoint(
        contentType,
        contentUid,
        locale
      );
      const headers = EnteriesHeaders;

      const payload = {
        entry: {
          preferences_and_settings: {
            creativity_precision: parseFloat(temp),
          },
        },
      };
      const res = await axios.put(endpoint, payload, { headers });

      // console.log(res.data);

      const updatedTemp =
        res.data.entry.preferences_and_settings?.creativity_precision;
      if (updatedTemp !== undefined && updatedTemp !== null) {
        setTemp(updatedTemp);
        localStorage.setItem("temp", updatedTemp.toString());
      }
    } catch (error) {
      // showToast(
      //   `${literals?.toast_messages_text?.error_updating_creative_and_precision_value}`,
      //   "error"
      // );
      handleOpenErrorUpdatingCreativePrecisionValue();
    }
  };

  useEffect(() => {
    setUserPrefSettings();
  }, [temp, locale]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {isLoading && <Spin fullscreen />}
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{ maxWidth: "12%" }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={95} alt="Logo" />
        </div>

        <Menu theme="light" mode="inline" onClick={() => navigate("/home")}>
          <Menu.Item key="1" icon={<FaBuffer />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu theme="light" mode="inline">
          <Menu.Item
            key="7"
            icon={<GiConversation />}
            onClick={() => navigate("/dialogue")}
          >
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>

        {userInfo?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
            >
              <Menu.Item key="5" icon={<IoLibrary />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
            >
              <Menu.Item key="6" icon={<MdCollectionsBookmark />}>
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}
        <Menu theme="light" mode="inline" defaultSelectedKeys={["3"]}>
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<IoMdSettings />}
          >
            {/* <Menu.Item
              key="2"
              icon={<GiSettingsKnobs />}
              onClick={handleOpenAnswerModal}
            >
              {literals?.sidebar?.answers_menu}
            </Menu.Item> */}
            {/* {primaryUser === "true" && ( */}
            <Menu.Item
              key="3"
              icon={<PiTreeStructure />}
              onClick={() => navigate("/organization")}
            >
              {literals?.sidebar?.organization_menu}
            </Menu.Item>
            {/* )} */}
            {/* <Menu.Item
              key="4"
              icon={<MdOutlineSubscriptions />}
              onClick={() => navigate("/subscription")}
            >
              {literals?.sidebar?.subscription_menu}
            </Menu.Item> */}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            textDecoration: "none",
          }}
        >
          <BsQuestionCircle
            className="question me-3"
            style={{ color: "#fff", fontSize: 22 }}
          />
          <p style={{ fontSize: 15, fontWeight: 500, marginTop: "9%" }}>
            Help & Support
          </p>
        </Link>
      </Sider>

      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
          onClick={handleOpenhelpuserModal}
        />
        <Content
          style={{
            margin: "24px 16px",
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
        >
          <div>
            <AlertMessage currentPage={currentPage} />
            <OrganizationTabs
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              literals={literals}
            />

            {selectedCategory === "company" && (
              <CompanyPage
                handleOpenModal={handleOpenModal}
                companyData={companyData}
                literals={literals}
              />
            )}
            {selectedCategory === "contacts" && (
              <ContactsPage
                showUserTable={showUserTable}
                // handleOpenEdituserModal={handleOpenEdituserModal}
                tableData={tableData}
                contactUsers={contactUsers}
                handleManageUsers={handleManageUsers}
                handleOpenAdduserModal={handleOpenAdduserModal}
                handleToggleTableState={handleToggleTableState}
                literals={literals}
                setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber}
              />
            )}
            {selectedCategory === "subscriptionPlan" && (
              <SubscriptionPage paymentStat={paymentStat} />
            )}
            {paymentStatus && (
              <div>
                {paymentStatus === "success" ? (
                  <p>Payment successful! Thank you for your purchase.</p>
                ) : (
                  <p>Payment failed. Please try again later.</p>
                )}
              </div>
            )}
            {selectedCategory === "billing" && <div>Billing tab</div>}
          </div>
          <UpdateCompanyModal
            visible={modalVisible}
            onClose={handleCloseModal}
            updateCompanyEntries={updateCompanyEntries}
            companyData={companyData}
            literals={literals}
          />
          <AddUserModal
            visible={addUserModalVisible}
            onClose={handleCloseAdduserModal}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            onFinish={handleAddNewUser}
            literals={literals}
          />
          <AnswerModal
            visible={answerModalVisible}
            onClose={handleCloseAnswerModal}
            marks={marks}
            handleSliderChange={handleSliderChange}
            temp={temp}
          />
          <InstructionModal
            visible={helpModalVisible}
            onClose={handleClosehelpuserModal}
          />
          <SuccessModal
            open={tokenSentSuccessfully}
            onCancel={handleCloseTokenSentSuccessfully}
            message={
              literals?.toast_messages_text
                ?.user_registered_and_token_send_successfully
            }
          />
          <ErrorModal
            open={pleaseTryAgain}
            onCancel={handleClosePleaseTryAgain}
            message={
              literals?.toast_messages_text
                ?.registration_failed_please_try_again
            }
          />
          <SuccessModal
            open={companyDetailsUpdated}
            onCancel={handleCloseCompanyDetailsUpdated}
            message={
              literals?.toast_messages_text
                ?.company_details_updated_successfully
            }
          />
          <ErrorModal
            open={failedToUpdateCompanyDetails}
            onCancel={handleCloseFailedToUpdateCompanyDetails}
            message={
              literals?.toast_messages_text
                ?.error_updating_creative_and_precision_value
            }
          />
          <ErrorModal
            open={errorUpdatingCreativePrecisionValue}
            onCancel={handleCloseErrorUpdatingCreativePrecisionValue}
            message={
              literals?.toast_messages_text
                ?.failed_to_update_company_details_please_check_your_input_and_try_again
            }
          />
        </Content>
      </Layout>
    </Layout>
  );
}
