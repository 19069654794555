import React, { useEffect, useState } from "react";
import { Select } from "antd";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
} from "../context/constants";
import axios from "axios";
import Flag from "react-world-flags";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const LanguageSelect = ({ width, dropdownWidth = 130, className }) => {
  const [languages, setLanguages] = useState([]);
  const [open, setOpen] = useState(false);

  const [locale, setLocale] = useState(
    localStorage.getItem("locale") || "en-us"
  );

  const handleChange = async (value) => {
    try {
      localStorage.setItem("locale", value);
      setLocale(value);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownChange = (open) => {
    setOpen(open);
  };

  useEffect(() => {
    const fetchLanguageOptions = async () => {
      try {
        const contentType = "available_languages";
        const endpoint = getContentEntriesEndpoint(contentType, locale);
        const headers = EnteriesHeaders;

        const res = await axios.get(endpoint, { headers });

        if (res.data.entries.length > 0) {
          setLanguages(res.data.entries[0].languages || []);
        }
      } catch (error) {
        console.log("Error fetching language options", error);
      }
    };
    fetchLanguageOptions();
  }, [locale]);

  const languageOptions =
    languages.map((lang) => {
      const [label, value] = lang.split(" (");
      const countryCode = value.slice(0, -1).trim().split("-")[1].toUpperCase();

      return {
        label: (
          <span style={{ fontWeight: 500 }}>
            <Flag
              code={countryCode}
              style={{
                marginRight: 8,
                objectFit: "cover",
                borderRadius: 50,
                height: 20,
              }}
              width={20}
            />
            {label.trim()}
          </span>
        ),
        value: value.slice(0, -1).trim(),
      };
    }) || [];

  return (
    <>
      <Select
        defaultValue={locale}
        style={{
          width: width,
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          color: "inherit",
          marginRight: "-7%",
        }}
        onChange={handleChange}
        options={languageOptions}
        dropdownStyle={{
          width: dropdownWidth,
          border: "none",
        }}
        bordered={false}
        open={open}
        onDropdownVisibleChange={handleDropdownChange}
        suffixIcon={
          open ? (
            <FaAngleUp
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color:
                  className === "welcome-page-lang-select"
                    ? "white"
                    : "#1B1B1B",
                position: "absolute",
                marginLeft: -10,
              }}
            />
          ) : (
            <FaAngleDown
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color:
                  className === "welcome-page-lang-select"
                    ? "white"
                    : "#1B1B1B",
                position: "absolute",
                marginLeft: -10,
              }}
            />
          )
        }
      />
    </>
  );
};

export default LanguageSelect;
