import React from "react";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import { Drawer } from "antd";
import ReactHtmlParser from "react-html-parser";
import articleImage from "../assests/article_image.png";

export default function ArticleDrawer({
  visible,
  onClose,
  articleData,
  clickedCardIndex,
  isLoading,
}) {
  const clickedCard = articleData?.[clickedCardIndex] || null;
  let convertedFormat = null;

  if (clickedCard) {
    const articleContent = clickedCard?.article_content;
    convertedFormat = jsonToHtml(articleContent);
  }

  // if (
  //   clickedCardIndex !== null &&
  //   clickedCardIndex >= 0 &&
  //   clickedCardIndex < articleData.length
  // ) {
  //   clickedCard = articleData[clickedCardIndex];
  //   const articleContent = clickedCard?.article_content;
  //   convertedFormat = jsonToHtml(articleContent);
  // }

  return (
    <Drawer
      destroyOnClose
      loading={isLoading}
      title="Article Detail"
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
      maskClosable={false}
      width="45%"
    >
      <div>
        {clickedCard && (
          <>
            <h4>{clickedCard.title}</h4>
            <img
              alt="Article Banner Image"
              src={clickedCard?.image?.url || articleImage}
              style={{
                height: 400,
                width: "100%",
                paddingBottom: 20,
                paddingTop: 20,
                objectFit: "fill",
              }}
            />
            <div
              style={{
                marginBottom: 20,
              }}
            >
              {ReactHtmlParser(jsonToHtml(clickedCard.article_description))}
            </div>
            <div className="content_drawer">
              {ReactHtmlParser(convertedFormat)}
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
}
