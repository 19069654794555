import { jsonToHtml } from "@contentstack/json-rte-serializer";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Modal, Spin } from "antd";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

export default function SearchArticleModal({
  visible,
  onClose,
  articleData,
  isLoading,
}) {
  const content = articleData?.entry?.article_content || "";

  useEffect(() => {
    if (visible) {
      const modalContent = document.querySelector(".ant-modal-body");
      if (modalContent) {
        modalContent.scrollTo(0, 0);
      }
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={1000}
      centered
      className="modal_content"
    >
      <button
        onClick={onClose}
        style={{
          border: "none",
          boxShadow: "none",
          backgroundColor: "#F3F5F5",
          position: "absolute",
          right: 5,
          top: 5,
        }}
      >
        <Icon icon="mingcute:close-line" width={24} />
      </button>
      {/* {!isLoading ? ( */}
      <div>
        <div className="content_modal">
          {ReactHtmlParser(jsonToHtml(content))}
        </div>
      </div>
      {/* ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <Spin size="large" />
          <p style={{ marginLeft: 10 }}>
            Please wait, article is being fetched...
          </p>
        </div>
      )} */}
    </Modal>
  );
}
