import { Button, Form, Modal } from "antd";
import React from "react";
import { Formfield, Logintext } from "../styles/Styles";

export default function DeleteCollection({
  visible,
  onClose,
  deleteCollection,
  selectedCollectionName,
  literals,
}) {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={500}
      centered
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Formfield>
          <p
            style={{
              fontSize: 22,
              fontWeight: 500,
              color: "#1B1B1B",
              // opacity: "60%",
              textAlign: "center",
            }}
          >
            {literals?.library_page?.confirm_to_delete} {selectedCollectionName}
            !
          </p>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Form.Item>
              <Button
                className="login-form-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffffff",
                  borderColor: "#2B8E96",
                  color: "#3AC1CB",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  paddingLeft: "30%",
                  paddingRight: "30%",
                  textTransform: "uppercase",
                  borderRadius: "200px",
                }}
                onClick={onClose}
              >
                <Logintext>{literals?.buttons?.cancel_btn}</Logintext>
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                  color: "#FFFFFF",
                  borderStyle: "none",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  paddingLeft: "30%",
                  paddingRight: "30%",
                  textTransform: "uppercase",
                  borderRadius: "200px",
                }}
                onClick={deleteCollection}
              >
                <Logintext>{literals?.buttons?.delete_btn}</Logintext>
              </Button>
            </Form.Item>
          </div>
        </Formfield>
      </div>
    </Modal>
  );
}
