import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, Form, Select, Modal, Row, Col } from "antd";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { CopyRight, Formfield, InputField, Logintext } from "../styles/Styles";
import { EmailClient } from "@azure/communication-email";
import {
  AzureConnectionUrl,
  EnteriesHeaders,
  getContentEntriesEndpoint,
} from "../context/constants";
import { Icon } from "@iconify/react/dist/iconify.js";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

export default function SignUpForm({ emailPattern, literals }) {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deniedDomains, setDeniedDomains] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [signUpEmailData, setSignUpEmailData] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [alreadyRegiter, setAlreadyRegister] = useState(false);
  const [emailAlreadyRegiter, setEmailAlreadyRegister] = useState(false);
  const [checkYourMail, setCheckYourMail] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const locale = localStorage.getItem("locale");

  const handleOpenModal = () => {
    setModalVisible(true);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleOpenRegisterSuccess = () => {
    setRegisterSuccess(true);
  };
  const handleCloseRegisterSuccess = () => {
    setRegisterSuccess(false);
  };

  const handleOpenAlreadyRegister = () => {
    setAlreadyRegister(true);
  };
  const handleCloseAlreadyRegister = () => {
    setAlreadyRegister(false);
  };

  const handleOpenEmailAlreadyRegister = () => {
    setEmailAlreadyRegister(true);
  };
  const handleCloseEmailAlreadyRegister = () => {
    setEmailAlreadyRegister(false);
  };

  const handleOpenCheckYourMail = () => {
    setCheckYourMail(true);
  };
  const handleCloseCheckYourMail = () => {
    setCheckYourMail(false);
  };

  const handleOpenTryAgain = () => {
    setTryAgain(true);
  };
  const handleCloseTryAgain = () => {
    setTryAgain(false);
  };

  const navigate = useNavigate();

  const fetchDeniedDomains = async () => {
    try {
      const ddContent_type = "denied_domains";
      const ddEndpoint = getContentEntriesEndpoint(ddContent_type, locale);
      const headers = EnteriesHeaders;
      const response = await axios.get(ddEndpoint, { headers });
      setDeniedDomains(response.data.entries);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDeniedDomains();
  }, []);

  useEffect(() => {
    const fetchAllCompanies = async () => {
      try {
        const cContent_type = "companies";
        const endPoint = getContentEntriesEndpoint(cContent_type, locale);
        const headers = EnteriesHeaders;
        const response = await axios.get(endPoint, { headers });
        const sortedEntries = response.data.entries.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        setCompanyOptions(sortedEntries);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllCompanies();
  }, [companyOptions, locale]);

  useEffect(() => {
    const fetchSignUpEmailData = async () => {
      try {
        const data = {
          query: `query FetchEmailContents {
            all_emails(where: {select_type: "Sign Up"}) {
              total
              items {
                title
                select_type
                email_subject
                sender_email
                bcc
                email_message {
                  json
                }
              }
            }
          }`,
        };
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://graphql.contentstack.com/stacks/blt6f0f282d20bbe00b?environment=prod",
          headers: {
            access_token: "cs534278b3e38a059d0cd52cdc",
            "Content-Type": "application/json",
          },
          data: JSON.stringify(data),
        };
        const response = await axios.request(config);
        setSignUpEmailData(response.data.data.all_emails.items[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSignUpEmailData();
  }, [locale]);

  const handleCreateCompany = async (values) => {
    try {
      const cContent_type = "companies";
      const endPoint = getContentEntriesEndpoint(cContent_type, locale);
      const headers = EnteriesHeaders;
      const payload = {
        entry: {
          title: values.title,
          email: values.email,
          street: values.street,
          city: values.city,
          zip: values.zip,
          country: values.country,
          state: values.state,
        },
      };
      await axios.post(endPoint, payload, { headers });
      handleOpenRegisterSuccess();
      setModalVisible(false);
    } catch (error) {
      handleOpenAlreadyRegister();
    }
  };

  const handleSignup = async (values) => {
    setLoading(true);
    try {
      const emailDomain = values.title.split("@")[1];
      const deniedDomainsList = deniedDomains.map((entry) => entry.title);
      if (deniedDomainsList.includes(emailDomain)) {
        throw new Error("Only professional emails are allowed");
      }

      const content_type = "application_user";
      const endpoint = getContentEntriesEndpoint(content_type, locale);
      const headers = EnteriesHeaders;

      const existingUserResponse = await axios.get(endpoint, {
        headers,
      });
      if (
        existingUserResponse.data.entries.find(
          (userEmail) => userEmail.title === values.title
        )
      ) {
        handleOpenEmailAlreadyRegister();
        throw new Error();
      }

      const companyName = companyOptions.find(
        (company) => company.uid === selectedCompany
      )?.title;

      const companyUid = companyOptions.find(
        (company) => company.uid === selectedCompany
      )?.uid;

      const payload = {
        entry: {
          user_name: values.user_name,
          company_name: companyName,
          company_uid: companyUid,
          title: values.title,
          contact_number: values.contact_number,
        },
      };

      const cContent_type = "companies";
      const cEndpoint = getContentEntriesEndpoint(cContent_type, locale);
      const url = cEndpoint.split("?")[0];
      const endpointLocale = cEndpoint.split("?")[1];
      const companyEndPoint =
        url + "/" + selectedCompany + "?" + endpointLocale;
      let existingContactDetails = await axios.get(companyEndPoint, {
        headers,
      });

      console.log(
        "Existing Contact Details Response:",
        existingContactDetails.data
      );

      existingContactDetails =
        existingContactDetails.data.entry.contact_details || [];

      if (!existingContactDetails || existingContactDetails.length === 0) {
        const contactDetails = [
          {
            name: values.user_name,
            email: values.title,
            contact_type: "PRIMARY",
          },
        ];
        if (!existingContactDetails || existingContactDetails.length === 1) {
          contactDetails.push({
            name: values.user_name,
            email: values.title,
            contact_type: "SECONDARY",
          });
        }
        const companyPayload = {
          entry: {
            contact_details: contactDetails,
          },
        };
        await axios.put(companyEndPoint, companyPayload, {
          headers,
        });
      }

      const res = await axios.post(endpoint, payload, { headers });
      const connectionString = AzureConnectionUrl;
      const client = new EmailClient(connectionString);

      const emailMessage = {
        senderAddress: signUpEmailData?.sender_email,
        content: {
          subject: signUpEmailData?.email_subject,
          html: `<!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <script
                src="https://kit.fontawesome.com/fab1f8bdcb.js"
                crossorigin="anonymous"
              ></script>
              <title>Email Template</title>
            </head>
            <body>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #dcf0ec;
                "
              >
                <div
                  style="
                    background-color: #ffffff;
                    margin-top: 5%;
                    margin-bottom: 5%;
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                <img
                src="https://images.contentstack.io/v3/assets/blt6f0f282d20bbe00b/blt58e0bed74aba1c39/65dcad6b6e7edbf719b4ec54/WhatsApp_Image_2024-02-08_at_17.16.53.jpeg"
                style="object-fit: cover; padding-top: 20px"
                alt="ChemAId Logo"
                width="158px"
              />
              <hr style="height: 1px; border: none; background-color: #3ac1cb" />
              <div style="padding-left: 30px; padding-right: 30px;">
                <p
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 36px;
                    opacity: 80%;
                  "
                >
                  Dear <strong>${values.user_name}</strong>,
                </p>
                <p
                style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              "
                >
                  ${signUpEmailData?.email_message?.json?.children[2]?.children[0]?.text}
                </p>

                <div
                  style="display: flex; align-items: center; justify-content: center"
                >
                  <div style="background-color: #eef0f8; padding: 3px 20px">
                    <p
                      id="textToCopy"
                      style="font-size: 16px; font-weight: 400; opacity: 40%"
                    >
                      <i class="fa-solid fa-lock"></i>
                      ${res.data.entry.uid}
                    </p>
                  </div>

                  <button
                    onclick="copyText()"
                    style="
                      font-size: 16px;
                      font-weight: 500;
                      color: #ffffff;
                      border: none;
                      background-color: #3ac1cb;
                      padding: 20px;
                      cursor: pointer;
                    "
                  >
                    <i class="fa-regular fa-copy"></i> Copy
                  </button>
                </div>
                <p
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  "
                >
                  ${signUpEmailData?.email_message?.json?.children[6]?.children[0]?.text}
                </p>
                <p
                style="
                  font-size: 14px;
                  font-weight: 400;
                  opacity: 80%;
                  line-height: 20px;
                "
              >
                ${signUpEmailData?.email_message?.json?.children[8]?.children[0]?.text}
              </p>
                <ul>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[0]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[0]?.children[0]?.children[1]?.text}</p>
                  </li>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[1]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[1]?.children[0]?.children[1]?.text}</p>
                  </li>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[1]?.text}<strong>${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[2]?.text}</strong></p>
                  </li>
                </ul>
                <p style="
                font-size: 14px;
                opacity: 80%;
                line-height: 20px;
              "><strong>${signUpEmailData?.email_message?.json?.children[10]?.children[0]?.text}</strong>${signUpEmailData?.email_message?.json?.children[10]?.children[1]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[11]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[12]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[14]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[15]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                opacity: 80%;
                line-height: 20px;
              "><strong>${signUpEmailData?.email_message?.json?.children[15]?.children[2]?.text}</strong></p>
                <p
                  style="
                    font-size: 15px;
                    font-weight: 400;
                    opacity: 60%;
                    text-align: center;
                    line-height: 22.5px;
                  "
                >
                2023 CHEM&#8226;AID<sup>TM</sup>&#9135; All rights reserved.
                </p>
              </div>
                </div>
              </div>
              <script>
                  function copyText() {
                  const textToCopy = document.getElementById("textToCopy").innerText;
                  navigator.clipboard.writeText(textToCopy)
                  .then(() => {
                  alert('Copied to clipboard!');
                  })
                  .catch((error) => {
                  console.error('Unable to copy:', error);
                  alert('Failed to copy text!');
                  });
                  }
              </script>
            </body>
          </html>
          `,
        },
        recipients: {
          to: [{ address: values.title }],
          bcc: [{ address: signUpEmailData.bcc }],
        },
      };

      const poller = await client.beginSend(emailMessage);
      await poller.pollUntilDone();

      console.log(
        `${literals?.toast_messages_text?.user_registered_successfully_please_check_your_mail_for_token}`
      );

      handleOpenCheckYourMail();
      setLoading(false);

      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (error) {
      console.error("Error in handleSignup:", {
        message: error.message,
        response: error.response ? error.response.data : "No response data",
      });
      handleOpenTryAgain();
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
  };

  return (
    <>
      <Formfield
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={handleSignup}
        emailPattern={emailPattern}
      >
        <Form.Item
          name="user_name"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <InputField
            autoComplete="off"
            // prefix={
            //   <FontAwesomeIcon icon={faUser} style={{ color: "#BBBBBB" }} />
            // }
            type="text"
            placeholder={literals?.placeholder_text?.full_name_text}
            className="boldPlaceholder"
          />
        </Form.Item>
        <Form.Item
          name="company_name"
          rules={[
            {
              required: true,
              message: "Please select your company!",
            },
          ]}
        >
          <Select
            showSearch
            style={{ height: 44, gap: 5 }}
            placeholder={literals?.placeholder_text?.company_name_text}
            className="boldPlaceholder"
            onChange={handleCompanyChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            // prefix={
            //   <FontAwesomeIcon icon={faBuilding} style={{ color: "#BBBBBB" }} />
            // }
            options={companyOptions.map((company) => ({
              value: company.uid,
              label: company.title,
            }))}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Button
                  onClick={() => handleOpenModal()}
                  style={{
                    width: "100%",
                    marginTop: 10,
                    fontWeight: 600,
                    color: "#3ac1cb",
                    border: "none",
                    // borderWidth: 1,
                    // borderColor: "#3ac1cb",
                    textTransform: "capitalize",
                  }}
                >
                  {literals?.buttons?.add_new_company}...
                </Button>
              </>
            )}
          />
        </Form.Item>
        <Form.Item
          name="contact_number"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <PhoneInput
            inputStyle={{
              height: 44,
              width: "100%",
              borderColor: "none",
              borderRadius: "50px",
              // boxShadow: "0 0 0 1px rgba(22, 119, 255, 0.2)",
              "&::focus": {
                // borderColor: "#1677ff",
                boxShadow: "0 0 0 1px rgba(22, 119, 255, 0.2)",
              },
            }}
            country={"us"}
            value={phoneNumber}
            onChange={(text) => setPhoneNumber(text)}
            className="boldPlaceholder"
          />
        </Form.Item>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
            {
              type: "email",
              message: "This email is invalid!",
            },
          ]}
        >
          <InputField
            autoComplete="off"
            // prefix={
            //   <FontAwesomeIcon icon={faEnvelope} style={{ color: "#BBBBBB" }} />
            // }
            type="email"
            placeholder={literals?.placeholder_text?.email_address}
            className="boldPlaceholder"
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={loading ? true : false}
            htmlType="submit"
            className="login-form-button"
            block
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: loading
                ? "#BEC8C8"
                : "linear-gradient(180deg, #3AC1CB 0%, #1D6065 150%)",
              color: "#FFFFFF",
              borderStyle: "none",
              paddingTop: "5%",
              paddingBottom: "5%",
              textTransform: "uppercase",
              borderRadius: "50px",
            }}
          >
            <Logintext>
              {literals?.buttons?.signup_btn}
              {!loading ? (
                <></>
              ) : (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </Logintext>
          </Button>
        </Form.Item>
      </Formfield>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
      <MyModal
        visible={modalVisible}
        onClose={handleCloseModal}
        registerCompany={handleCreateCompany}
        loading={loading}
        literals={literals}
      />
      <SuccessModal
        open={registerSuccess}
        onCancel={handleCloseRegisterSuccess}
        message={literals?.toast_messages_text?.company_registered_successfully}
      />
      <ErrorModal
        open={alreadyRegiter}
        onCancel={handleCloseAlreadyRegister}
        message={literals?.toast_messages_text?.company_already_registered}
      />
      <ErrorModal
        open={emailAlreadyRegiter}
        onCancel={handleCloseEmailAlreadyRegister}
        message={
          literals?.toast_messages_text?.this_email_is_already_registered
        }
      />
      <SuccessModal
        open={checkYourMail}
        onCancel={handleCloseCheckYourMail}
        message={
          literals?.toast_messages_text
            ?.user_registered_successfully_please_check_your_mail_for_token
        }
      />
      <ErrorModal
        open={tryAgain}
        onCancel={handleCloseTryAgain}
        message={
          literals?.toast_messages_text
            ?.failed_to_register_user_please_try_again
        }
      />
    </>
  );
}

function MyModal({ visible, onClose, registerCompany, literals }) {
  const [isEmpty, setIsEmpty] = useState(true);

  const handleInputChange = (event) => {
    setIsEmpty(event.target.value === "");
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={700}
      centered
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 10,
          marginLeft: 30,
          marginRight: 30,
          marginBottom: 20,
        }}
      >
        <p style={{ color: "#1B1B1B", fontSize: 24, fontWeight: 700 }}>
          Company Details
        </p>
        <button
          onClick={onClose}
          style={{
            border: "none",
            boxShadow: "none",
            backgroundColor: "#F3F5F5",
          }}
        >
          <Icon icon="mingcute:close-line" width={24} />
        </button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#F3F5F5",
        }}
      >
        <Formfield
          initialValues={{
            remember: true,
          }}
          onFinish={registerCompany}
        >
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{ borderColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.placeholder_text?.company_name_text}
                  className="Placeholder"
                  onChange={handleInputChange}
                  // suffix={
                  //   isEmpty ? (
                  //     <span
                  //       style={{
                  //         color: "red",
                  //         position: "absolute",
                  //         left: "21%",
                  //       }}
                  //     >
                  //       *
                  //     </span>
                  //   ) : null
                  // }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "email domain should starts from @domain.com",
                  },
                ]}
              >
                <InputField
                  style={{ borderColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.placeholder_text?.email_domain}
                  className="Placeholder"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="street"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{ borderColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.placeholder_text?.address_text}
                  className="Placeholder"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="address2">
                <InputField
                  style={{ borderColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.placeholder_text?.address_2}
                  className="Placeholder"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{ borderColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.placeholder_text?.city_text}
                  className="Placeholder"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{ borderColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.placeholder_text?.state_text}
                  className="Placeholder"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="zip"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{ borderColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.placeholder_text?.zip_text}
                  className="Placeholder"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                name="country"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{ borderColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.placeholder_text?.country_text}
                  className="Placeholder"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              htmlType="submit"
              className="login-form-button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor: "#3AC1CB",
                background: "linear-gradient(180deg, #3AC1CB 0%, #1D6065 150%)",
                color: "#FFFFFF",
                borderStyle: "none",
                paddingTop: "4%",
                paddingBottom: "4%",
                paddingLeft: "10%",
                paddingRight: "10%",
                textTransform: "uppercase",
                width: "100%",
                borderRadius: "50px",
              }}
            >
              <Logintext>{literals?.buttons?.save_btn}</Logintext>
            </Button>
          </Form.Item>
        </Formfield>
      </div>
    </Modal>
  );
}
