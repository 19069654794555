import {
  Breadcrumb,
  Button,
  Dropdown,
  Layout,
  Menu,
  Spin,
  Table,
  theme,
} from "antd";
import Sider from "antd/es/layout/Sider";
import SubMenu from "antd/es/menu/SubMenu";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import image from "../assests/image.png";
import { PiTreeStructure } from "react-icons/pi";
import AnswerModal from "../components/AnswerModal";
import { Content } from "antd/es/layout/layout";
import MainHeader from "../components/MainHeader";
import InstructionModal from "../components/InstructionModal";
import { LuFolderOpen } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";
import { Logintext } from "../styles/Styles";
import CreateFolder from "../components/CreateFolder";
import axios from "axios";
import DeleteFolder from "../components/DeleteFolder";
import debounce from "lodash/debounce";
import _ from "lodash";
import UploadDocument from "../components/UploadDocument";
import DeleteDocument from "../components/DeleteDocument";
import { IoMdFolderOpen } from "react-icons/io";
import { IoDocumentOutline } from "react-icons/io5";
import EditFolder from "../components/EditFolder";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import {
  EnteriesHeaders,
  updateContentEntriesEndpoint,
} from "../context/constants";
import { useLiterals } from "../context/LiteralsContext";
import { BsQuestionCircle } from "react-icons/bs";
import { ReactComponent as HomeIcon } from "../assests/home.svg";
import { ReactComponent as Dialogues } from "../assests/dialogues.svg";
import { ReactComponent as LibraryIcon } from "../assests/library-active.svg";
import { ReactComponent as Collections } from "../assests/collections.svg";
import { ReactComponent as Settings } from "../assests/settings.svg";
import { Icon } from "@iconify/react/dist/iconify.js";
import { EllipsisOutlined } from "@ant-design/icons";
import ErrorModal from "../components/ErrorModal";
import Link from "antd/es/typography/Link";

const marks = {
  0: "0%",
  20: "20%",
  40: "40%",
  60: "60%",
  80: "80%",
  100: "100%",
};

export default function Library() {
  const literals = useLiterals();
  const [collapsed, setCollapsed] = useState(false);
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  const [temp, setTemp] = useState(() => {
    const storedTemp = localStorage.getItem("temp");
    return storedTemp !== null ? parseFloat(storedTemp) : 0.5;
  });
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [folderModal, setFolderModal] = useState(false);
  const [deletefolderModal, setDeleteFolderModal] = useState(false);
  const [newdocModal, setNewDocModal] = useState(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [editFolderModal, setEditFolderModal] = useState(false);
  const [folderPath, setFolderPath] = useState([]);
  const [activeFolderUID, setActiveFolderUID] = useState(null);
  const [activeCollection, setActiveCollection] = useState(null);
  const [isdialogVisible, setIsDialogModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [foldersData, setFoldersData] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [folderDeletedSuccessfully, setFolderDeletedSuccessfully] =
    useState(false);
  const [errorDeletingFolder, setErrorDeletingFolder] = useState(false);

  const handleOpenFolderDeletedSuccessfully = () => {
    setFolderDeletedSuccessfully(true);
  };
  const handleCloseFolderDeletedSuccessfully = () => {
    setFolderDeletedSuccessfully(false);
  };

  const handleOpenErrorDeletingFolder = () => {
    setErrorDeletingFolder(true);
  };
  const handleCloseErrorDeletingFolder = () => {
    setErrorDeletingFolder(false);
  };

  const locale = localStorage.getItem("locale");

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const handleFolderClick = (folderName, folderUID, parentPath = []) => {
    if (!folderPath.includes(folderName)) {
      setActiveCollection(folderName);
      setActiveFolderUID(folderUID);
      // setFolderPath([folderName]);
      setFolderPath([...parentPath, folderName]);
    }
  };

  // const handleBreadcrumbClick = (index) => {
  //   setFolderPath(folderPath.slice(0, index + 1));
  // };

  const handleBreadcrumbClick = (index) => {
    setFolderPath(folderPath.slice(0, index + 1));
    const newActiveFolder = foldersData.find(
      (folder) => folder.name === folderPath[index]
    );
    setActiveCollection(newActiveFolder?.name || null);
    setActiveFolderUID(newActiveFolder?.uid || null);
  };

  const navigate = useNavigate();

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const handleOpenAnswerModal = () => {
    setAnswerModalVisible(true);
  };
  const handleCloseAnswerModal = () => {
    setAnswerModalVisible(false);
  };
  const handleOpenIsDialogModal = () => {
    setIsDialogModalVisible(true);
  };

  const handleCloseIsDailogModal = () => {
    setIsDialogModalVisible(false);
  };

  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };
  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };
  const handleOpenFolderModal = () => {
    setFolderModal(true);
  };
  const handleCloseFolderModal = () => {
    setFolderModal(false);
  };
  const handleOpenDeleteFolderModal = () => {
    setDeleteFolderModal(true);
  };
  const handleCloseDeleteFolderModal = () => {
    setDeleteFolderModal(false);
  };
  const handleOpenNewDocModal = () => {
    setNewDocModal(true);
  };
  const handleCloseNewDocModal = () => {
    setNewDocModal(false);
  };
  const handleOpenDeleteDocumentModal = (item) => {
    setDeleteDocumentModal(true);
    setSelectedDocument(item);
  };
  const handleCloseDeleteDocumentModal = () => {
    setDeleteDocumentModal(false);
  };
  const handleOpenEditModal = (item) => {
    setEditFolderModal(true);
    setSelectedFolder(item);
  };

  const handleCloseEditModal = () => {
    setEditFolderModal(false);
  };
  const primaryUser = localStorage.getItem("primaryUser");

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const handleSliderChange = (value) => {
    switch (value) {
      case 0:
        setTemp(1.0);
        break;
      case 20:
        setTemp(0.8);
        break;
      case 40:
        setTemp(0.6);
        break;
      case 60:
        setTemp(0.4);
        break;
      case 80:
        setTemp(0.2);
        break;
      default:
        setTemp(0.0);
    }
  };

  const rowSelection = {
    type: selectionType,
    onSelect: (record, selected) => {
      record.selected = selected;
      setTableData([...tableData]);
    },
    getCheckboxProps: (record) => ({
      checked: record.selected,
    }),
  };

  const userDataString = localStorage.getItem("userData");
  const userInfo = JSON.parse(userDataString);

  useEffect(() => {
    const fetchCompanyAuth = async () => {
      setLoading(true);
      try {
        const contentType = "companies";
        const uid = userInfo?.company_uid;
        const endPoint = updateContentEntriesEndpoint(contentType, uid, locale);
        const headers = EnteriesHeaders;

        const res = await axios.get(endPoint, { headers });
        setApiKey(res?.data?.entry?.api);
        setAuthToken(res?.data?.entry?.token);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching company auth details: ", error);
      }
    };
    fetchCompanyAuth();
  }, []);

  useEffect(() => {
    const getFolder = async () => {
      try {
        const endPoint = `https://api.contentstack.io/v3/assets?include_folders=true`;

        const headers = {
          api_key: apiKey,
          authorization: authToken,
        };

        const response = await axios.get(endPoint, {
          headers,
        });
        const filteredFolders = response.data.assets.filter(
          (folder) => folder.parent_uid === null
        );

        const filterSubFolders = response.data.assets.filter(
          (subFolder) => subFolder.parent_uid === activeFolderUID
        );
        setFoldersData(filteredFolders);
        setTableData(filterSubFolders);
        // console.log("filter", filterSubFolders);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    getFolder();
  }, [activeFolderUID, foldersData, apiKey, authToken]);

  const sortedFoldersData = [...foldersData].sort((a, b) =>
    (a.name || "").localeCompare(b.name || "")
  );

  const deleteSelectedFolder = async () => {
    setLoading(true);
    try {
      const endPoint = "https://query3.azurewebsites.net/api/delete-folder";
      const payload = {
        folder_uid: activeFolderUID,
      };

      const headers = {
        api_key: apiKey,
        auth_token: authToken,
      };

      const res = await axios.post(endPoint, payload, { headers });
      setFolderPath((prevPath) =>
        prevPath.filter((folder) => folder !== activeCollection)
      );

      setActiveCollection(null);
      setActiveFolderUID(null);
      setDeleteFolderModal(false);
      setLoading(false);
      // showToast("Folder deleted successfully!", "success");
      // showToast(
      //   `${literals?.toast_messages_text?.folder_deleted_successfully}`,
      //   "success"
      // );
      handleOpenFolderDeletedSuccessfully();
      console.log(res.data);
    } catch (error) {
      console.log(error);
      // showToast("Error deleting folder", "error");
      // showToast(
      //   `${literals?.toast_messages_text?.error_deleting_folder}`,
      //   "error"
      // );
      handleOpenErrorDeletingFolder();
    } finally {
      setLoading(false);
    }
  };

  const data = tableData.map((item) => ({
    key: item?.uid,
    name: item?.name,
    docName: item?.title,
    type: item?.content_type,
    url: item?.is_dir ? null : item?.permanent_url,
    parentUid: item?.parent_uid,
    is_dir: item?.is_dir,
  }));

  const sortTableData = (data) => {
    const folders = data.filter((item) => item.is_dir);
    const documents = data.filter((item) => !item.is_dir);

    folders.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    documents.sort((a, b) => (a.title || "").localeCompare(b.title || ""));
    return [...folders, ...documents];
  };

  const sortedTableData = sortTableData(data);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {loading && <Spin fullscreen />}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
      <ErrorModal
        open={folderDeletedSuccessfully}
        onCancel={handleCloseFolderDeletedSuccessfully}
        message={literals?.toast_messages_text?.no_data_to_generate_pdf}
      />
      <ErrorModal
        open={errorDeletingFolder}
        onCancel={handleCloseErrorDeletingFolder}
        message={literals?.toast_messages_text?.error_deleting_folder}
      />
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{ maxWidth: "12%" }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={collapsed ? 55 : 95} alt="Logo" />
        </div>

        <Menu theme="light" mode="inline" onClick={() => navigate("/home")}>
          <Menu.Item key="1" icon={<HomeIcon />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu theme="light" mode="inline" onClick={() => navigate("/dialogue")}>
          <Menu.Item key="7" icon={<Dialogues />}>
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>
        {userInfo?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
              defaultSelectedKeys={["5"]}
            >
              <Menu.Item key="5" icon={<LibraryIcon />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
            >
              <Menu.Item
                key="6"
                icon={<Collections />}
                // onClick={handleOpenIsDialogModal}
              >
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}

        <Menu theme="light" mode="inline">
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<Settings />}
          >
            {primaryUser === "true" && (
              <>
                <Menu.Item
                  key="1"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/company")}
                >
                  {literals?.buttons?.company_btn}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/contacts")}
                >
                  {literals?.buttons?.contacts_btn}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/subscription")}
                >
                  {literals?.sidebar?.subscription_menu}
                </Menu.Item>
              </>
            )}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            justifyContent: collapsed ? "center" : "flex-start",
          }}
        >
          <BsQuestionCircle
            className="question"
            style={{ color: "#fff", fontSize: 22 }}
          />
          {!collapsed && (
            <p style={{ fontSize: 15, fontWeight: 500, marginLeft: "1rem" }}>
              Help & Support
            </p>
          )}
        </Link>
      </Sider>
      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
          onClick={handleOpenhelpuserModal}
        />
        <Content
          style={{
            margin: "0px",
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginLeft: "2%",
                marginRight: "2%",
                marginTop: "3%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Breadcrumb>
                  <Breadcrumb.Item
                    key="home"
                    onClick={() => {
                      handleBreadcrumbClick(null);
                      setFolderPath([]);
                    }}
                  >
                    {/* <HomeOutlined
                      style={{
                        color: "#3ac1cb",
                        fontSize: 20,
                        marginRight: 5,
                        cursor: "pointer",
                      }}
                    /> */}
                    Home
                  </Breadcrumb.Item>
                  {folderPath &&
                    folderPath.map((folder, index) => (
                      <Breadcrumb.Item
                        key={folder.uid}
                        onClick={() => handleBreadcrumbClick(index)}
                      >
                        {index === 0 && (
                          <span>
                            {/* <LuFolderOpen
                              style={{
                                color: "#3ac1cb",
                                fontSize: 22,
                                marginRight: 5,
                              }}
                            /> */}
                            {folder}
                          </span>
                        )}
                        {index !== 0 && <span>{folder}</span>}
                      </Breadcrumb.Item>
                    ))}
                </Breadcrumb>

                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                  {/* <Link style={{ marginRight: 10, color: "#3ac1cb" }}>
                    <MdOutlineCreateNewFolder
                      style={{ fontSize: 25 }}
                      onClick={handleOpenFolderModal}
                    />
                  </Link> */}
                  <Link
                    style={{
                      // color: "#3a3a5d",
                      borderRadius: "200px",
                      backgroundColor: "#D500051A",
                      padding: 10,
                    }}
                  >
                    <Icon
                      icon="mingcute:delete-fill"
                      style={{
                        color: "#D50005",
                        fontSize: 20,
                        opacity: activeFolderUID ? 1 : 0.4,
                        cursor: activeFolderUID ? "pointer" : "not-allowed",
                      }}
                      onClick={() => {
                        if (activeFolderUID) {
                          handleOpenDeleteFolderModal();
                        }
                      }}
                    />
                  </Link>
                  <Button
                    htmlType="submit"
                    className="login-form-button"
                    style={{
                      display: "flex",
                      background:
                        "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                      color: "#FFFFFF",
                      borderStyle: "none",
                      textTransform: "uppercase",
                      marginLeft: 10,
                      opacity: activeFolderUID ? 1 : 0.4,
                      cursor: activeFolderUID ? "pointer" : "not-allowed",
                      borderRadius: "200px",
                      paddingTop: "5%",
                      paddingBottom: "15%",
                    }}
                    onClick={() => {
                      if (activeFolderUID) {
                        handleOpenNewDocModal();
                      }
                    }}
                  >
                    <Logintext>
                      <FaPlus
                        style={{
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      />
                      {literals?.buttons?.new_document_btn}
                    </Logintext>
                  </Button>
                </div>
              </div>
              <div>
                <Table
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                  dataSource={sortedTableData}
                  columns={[
                    {
                      title: `${literals?.library_page?.document_name_table}`,
                      dataIndex: "name",
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            if (record.is_dir) {
                              handleFolderClick(
                                record.name,
                                record.key,
                                folderPath
                              );
                            }
                          }}
                          href={record.url}
                          style={{ textDecoration: "none", color: "black" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {record.url ? (
                            <IoDocumentOutline
                              style={{
                                marginRight: 8,
                                fontSize: 18,
                                fontWeight: 600,
                              }}
                            />
                          ) : (
                            <IoMdFolderOpen
                              style={{
                                marginRight: 8,
                                fontSize: 18,
                                fontWeight: 600,
                              }}
                            />
                          )}
                          {text || record.docName}
                        </a>
                      ),
                    },
                    {
                      title: `${literals?.library_page?.type_name}`,
                      dataIndex: "type",
                      render: (text, record) => (
                        <a>
                          {text && text.split(".")[2]
                            ? text.split(".")[2].charAt(0).toUpperCase() +
                              text.split(".")[2].slice(1)
                            : (
                                record?.type?.split("/")?.[1] || ""
                              ).toUpperCase()}

                          {/* {text && text.split(".")[2]
                            ? text.split(".")[2].charAt(0).toUpperCase() +
                                text.split(".")[2].slice(1) || ""
                            : (
                                record?.type?.split("/")?.[1] || ""
                              ).toUpperCase()} */}
                        </a>
                      ),
                    },
                    // {
                    //   title: `${literals?.library_page?.actions_name}`,
                    //   dataIndex: "action",
                    //   render: (_, record) => (
                    //     <>
                    //       {record.name && (
                    //         <Button
                    //           style={{
                    //             borderStyle: "solid",
                    //             borderWidth: "2px",
                    //             borderColor: "#3AC1CB",
                    //             color: "#3AC1CB",
                    //             borderRadius: "8px",
                    //             paddingTop: 3,
                    //             paddingBottom: 5,
                    //             paddingLeft: 15,
                    //             paddingRight: 15,
                    //             marginRight: "10px",
                    //           }}
                    //           onClick={() => {
                    //             // editFolder(record.uid);
                    //             handleOpenEditModal(record);
                    //           }}
                    //         >
                    //           {literals?.buttons?.edit_btn}
                    //         </Button>
                    //       )}
                    //       <Button
                    //         style={{
                    //           borderStyle: "solid",
                    //           borderWidth: "2px",
                    //           borderColor: "#3AC1CB",
                    //           color: "#3AC1CB",
                    //           borderRadius: "8px",
                    //           paddingTop: 3,
                    //           paddingBottom: 5,
                    //           paddingLeft: 15,
                    //           paddingRight: 15,
                    //         }}
                    //         // onClick={() => deleteDocument(record.uid)}
                    //         onClick={() => {
                    //           handleOpenDeleteDocumentModal(record);
                    //         }}
                    //       >
                    //         {literals?.buttons?.remove_btn}
                    //       </Button>
                    //     </>
                    //   ),
                    // },
                    {
                      title: `${literals?.library_page?.actions_name}`,
                      dataIndex: "action",
                      render: (_, record) => {
                        const menu = (
                          <Menu>
                            <Menu.Item
                              key="edit"
                              onClick={() => handleOpenEditModal(record)}
                            >
                              {literals?.buttons?.edit_btn}
                            </Menu.Item>
                            <Menu.Item
                              key="delete"
                              onClick={() =>
                                handleOpenDeleteDocumentModal(record)
                              }
                            >
                              {literals?.buttons?.remove_btn}
                            </Menu.Item>
                          </Menu>
                        );

                        return (
                          <Dropdown overlay={menu} trigger={["click"]}>
                            <EllipsisOutlined
                              style={{
                                fontSize: "24px",
                                cursor: "pointer",
                                color: "#000",
                              }}
                            />
                          </Dropdown>
                        );
                      },
                    },
                  ]}
                  pagination={{
                    pageSize: 12,
                    showSizeChanger: false,
                    itemRender: (current, type, originalElement) => {
                      if (type === "prev") {
                        return (
                          <a
                            style={{
                              backgroundColor: "#CDD4E3",
                              padding: 8,
                              borderRadius: 5,
                            }}
                          >
                            <ArrowLeftOutlined style={{ color: "white" }} />
                          </a>
                        );
                      }
                      if (type === "next") {
                        return (
                          <a
                            style={{
                              backgroundColor: "#CDD4E3",
                              padding: 8,
                              borderRadius: 5,
                            }}
                          >
                            <ArrowRightOutlined style={{ color: "white" }} />
                          </a>
                        );
                      }
                      return originalElement;
                    },
                  }}
                />
              </div>
            </div>

            <div
              style={{
                backgroundColor: "white",
                width: "30%",
                height: "100%",
                padding: 20,
                alignSelf: "start",
                position: "relative",
              }}
            >
              <h5 style={{ marginBottom: 20, fontSize: 18, fontWeight: 500 }}>
                {literals?.library_page?.folders_name}
              </h5>

              {sortedFoldersData &&
                sortedFoldersData.map((folder, index) => (
                  <div key={index}>
                    <div style={{ marginBottom: 10 }}>
                      <p
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            activeCollection === folder?.name ? "#F3F5F8" : "",
                          borderRadius: "200px",
                          padding: 10,
                        }}
                        onClick={() =>
                          handleFolderClick(folder?.name, folder.uid)
                        }
                      >
                        <LuFolderOpen
                          style={{
                            // color:
                            //   activeCollection === folder?.name
                            //     ? "#3ac1cb"
                            //     : "#000000",
                            color: "#06152B",

                            fontSize: 18,
                            marginRight: 5,
                          }}
                        />
                        {folder?.name}
                      </p>
                    </div>
                  </div>
                ))}
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{
                  display: "flex",
                  background:
                    "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",

                  color: "#FFFFFF",
                  borderStyle: "none",
                  textTransform: "uppercase",
                  opacity: activeFolderUID ? 1 : 0.4,
                  cursor: activeFolderUID ? "pointer" : "not-allowed",
                  borderRadius: 200,
                  position: "fixed",
                  bottom: "3%",
                  right: "5%",
                  height: "6%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleOpenFolderModal}
              >
                <Logintext>
                  <FaPlus
                    style={{
                      marginRight: 8,
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  />
                  CREATE FOLDER
                </Logintext>
              </Button>
            </div>
          </div>
        </Content>
      </Layout>
      <AnswerModal
        visible={answerModalVisible}
        onClose={handleCloseAnswerModal}
        marks={marks}
        handleSliderChange={handleSliderChange}
        temp={temp}
      />
      <InstructionModal
        visible={helpModalVisible}
        onClose={handleClosehelpuserModal}
      />
      <CreateFolder
        visible={folderModal}
        onClose={handleCloseFolderModal}
        // onAddFolder={handleAddFolder}
        parentUid={activeFolderUID}
        apiKey={apiKey}
        authToken={authToken}
        literals={literals}
      />
      <DeleteFolder
        visible={deletefolderModal}
        onClose={handleCloseDeleteFolderModal}
        selectedFolderName={activeCollection}
        deleteSelectedFolder={deleteSelectedFolder}
        loading={loading}
        literals={literals}
      />
      <UploadDocument
        visible={newdocModal}
        onClose={handleCloseNewDocModal}
        parentUid={activeFolderUID}
        apiKey={apiKey}
        authToken={authToken}
        literals={literals}
      />
      <DeleteDocument
        visible={deleteDocumentModal}
        onClose={handleCloseDeleteDocumentModal}
        data={selectedDocument}
        apiKey={apiKey}
        authToken={authToken}
        literals={literals}
      />
      <EditFolder
        visible={editFolderModal}
        onClose={handleCloseEditModal}
        item={selectedFolder}
        apiKey={apiKey}
        authToken={authToken}
        literals={literals}
      />
    </Layout>
  );
}
