import { Modal } from "antd";
import React from "react";
import { ReactComponent as Success } from "../assests/success.svg";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function SuccessModal({ open, onCancel, message }) {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={400}
      maskClosable={false}
      centered
      footer={null}
    >
      <button
        onClick={onCancel}
        style={{
          border: "none",
          boxShadow: "none",
          backgroundColor: "#F3F5F5",
          position: "absolute",
          right: 5,
          top: 5,
        }}
      >
        <Icon icon="mingcute:close-line" width={24} />
      </button>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 20,
          width: "100%",
          padding: 40,
        }}
      >
        <div
          style={{
            height: 80,
            width: 80,
            background: "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
            borderRadius: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 25,
          }}
        >
          <Success />
        </div>
        <p
          style={{
            color: "#1B1B1B",
            fontWeight: 500,
            fontSize: 22,
            textAlign: "center",
          }}
        >
          {message}
        </p>
      </div>
    </Modal>
  );
}
