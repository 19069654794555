import { Button, Col, Form, Row, Drawer } from "antd";
import { useEffect, useState } from "react";
import { Formfield, InputField, Logintext } from "../styles/Styles";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function UpdateCompanyModal({
  visible,
  onClose,
  updateCompanyEntries,
  companyData,
  literals,
}) {
  const [isEmpty, setIsEmpty] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible && companyData) {
      form.setFieldsValue(companyData);
    }
  }, [visible, companyData, form]);

  const handleFinish = (values) => {
    updateCompanyEntries(values);
    onClose();
  };

  const handleInputChange = (event) => {
    setIsEmpty(event.target.value === "");
  };

  return (
    <Drawer
      destroyOnClose
      title="Edit Company details"
      open={visible}
      onClose={onClose}
      maskClosable={false}
      placement="right"
      closable={true}
      width="30%"
    >
      <div>
        <Formfield initialValues={companyData} onFinish={handleFinish}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div>
              <p
                style={{
                  color: "#06152B",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                {literals?.placeholder_text?.company_name_text}
              </p>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    borderColor: "#F3F5F8",
                    borderRadius: 12,
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div>
              <p
                style={{
                  color: "#06152B",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                {`${literals?.placeholder_text?.email_domain}` +
                  "(@domain.com)"}
              </p>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "email domain should starts from @domain.com",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    borderColor: "#F3F5F8",
                    borderRadius: 12,
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div>
              <p
                style={{
                  color: "#06152B",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                {literals?.placeholder_text?.address_text}
              </p>
              <Form.Item
                name="street"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    borderColor: "#F3F5F8",
                    borderRadius: 12,
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div>
              <p
                style={{
                  color: "#06152B",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                {literals?.placeholder_text?.address_2}
              </p>
              <Form.Item name="address2">
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    borderColor: "#F3F5F8",
                    borderRadius: 12,
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div>
              <p
                style={{
                  color: "#06152B",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                {literals?.placeholder_text?.state_text}
              </p>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    borderColor: "#F3F5F8",
                    borderRadius: 12,
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div>
              <p
                style={{
                  color: "#06152B",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                {literals?.placeholder_text?.city_text}
              </p>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    borderColor: "#F3F5F8",
                    borderRadius: 12,
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div>
              <p
                style={{
                  color: "#06152B",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                {literals?.placeholder_text?.zip_text}
              </p>
              <Form.Item
                name="zip"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    borderColor: "#F3F5F8",
                    borderRadius: 12,
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div>
              <p
                style={{
                  color: "#06152B",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 5,
                }}
              >
                {literals?.placeholder_text?.country_text}
              </p>
              <Form.Item
                name="country"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    borderColor: "#F3F5F8",
                    borderRadius: 12,
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <Button
              htmlType="submit"
              className="login-form-button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                color: "#FFFFFF",
                borderStyle: "none",
                paddingTop: "6%",
                paddingBottom: "6%",
                paddingLeft: "10%",
                paddingRight: "10%",
                textTransform: "uppercase",
                borderRadius: 200,
              }}
            >
              <Logintext>{literals?.buttons?.save_btn}</Logintext>
            </Button>
          </Form.Item>
        </Formfield>
      </div>
    </Drawer>
  );
}
