import "./App.css";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import Home from "./screens/Home";
import { Route, Routes } from "react-router-dom";
import Forget from "./screens/Forget";
import Protected from "./components/Protected";
import Organization from "./screens/Organization";
import SetPassword from "./screens/SetPassword";
import Subscription from "./screens/Subscription";
import Library from "./screens/Library";
import Collection from "./screens/Collection";
import Success from "./screens/Success";
import Cancel from "./screens/Cancel";
import Welcome from "./screens/Welcome";
import Verify from "./screens/VerifyToken";
import ResetPassword from "./screens/ResetPassword";
import Dialogue from "./screens/Dialogue";
import Company from "./screens/Company";
import Contacts from "./screens/Contacts";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Protected Component={Welcome} />} />
      <Route path="/login" element={<Protected Component={Login} />} />
      <Route path="/signup" element={<Protected Component={SignUp} />} />
      <Route path="/forget" element={<Protected Component={Forget} />} />
      <Route
        path="/setpassword"
        element={<Protected Component={SetPassword} />}
      />
      <Route path="/verify" element={<Protected Component={Verify} />} />
      <Route path="/reset" element={<Protected Component={ResetPassword} />} />
      <Route path="/home" element={<Protected Component={Home} />} />
      <Route path="/dialogue" element={<Protected Component={Dialogue} />} />
      <Route path="/library" element={<Protected Component={Library} />} />
      <Route
        path="/collection"
        element={<Protected Component={Collection} />}
      />
      <Route
        path="/organization"
        element={<Protected Component={Organization} />}
      />
      <Route path="/company" element={<Protected Component={Company} />} />
      <Route path="/contacts" element={<Protected Component={Contacts} />} />
      <Route
        path="/subscription"
        element={<Protected Component={Subscription} />}
      />
      <Route path="/success" element={<Protected Component={Success} />} />
      <Route path="/cancel" element={<Protected Component={Cancel} />} />
    </Routes>
  );
}

export default App;
