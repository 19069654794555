import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { EnteriesHeaders, getContentEntriesEndpoint } from "./constants";

const LiteralsContext = createContext();

export const useLiterals = () => {
  return useContext(LiteralsContext);
};

export const LiteralsProvider = ({ children }) => {
  const [literals, setLiterals] = useState([]);
  const [locale, setLocale] = useState(
    () => localStorage.getItem("locale") || "en-us"
  );

  const fetchLiterals = async (locale) => {
    try {
      const contentType = "literals";
      const endPoint = getContentEntriesEndpoint(contentType, locale);
      const headers = EnteriesHeaders;
      const res = await axios.get(endPoint, { headers });
      setLiterals(res.data.entries[0]);
    } catch (error) {
      console.log("Error fetching literals", error);
    }
  };

  useEffect(() => {
    fetchLiterals(locale);
  }, [locale]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedLocale = localStorage.getItem("locale");
      setLocale(storedLocale || "en-us");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <LiteralsContext.Provider value={literals}>
      {children}
    </LiteralsContext.Provider>
  );
};
