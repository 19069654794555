import { ArrowLeftOutlined, EditFilled } from "@ant-design/icons";
import { Button, Modal, Spin, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  EnteriesHeaders,
  updateContentEntriesEndpoint,
} from "../context/constants";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import _ from "lodash";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

export default function ContactsPage({
  showUserTable,
  handleOpenEdituserModal,
  tableData,
  contactUsers,
  handleManageUsers,
  handleOpenAdduserModal,
  handleToggleTableState,
  literals,
}) {
  const data =
    contactUsers &&
    contactUsers.contact_details &&
    contactUsers?.contact_details.map((user) => user.contact_details);

  const dataIndexMap = {
    name: showUserTable ? "user_name" : "name",
    email: showUserTable ? "title" : "email",
  };

  const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [enable, setEnable] = useState(true);
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [libraryEnableDisable, setLibraryEnableDisable] = useState(false);
  const [enableLibraryAccess, setEnableLibraryAccess] = useState(false);

  const handleOpenLibraryEnableDisable = () => {
    setLibraryEnableDisable(true);
  };
  const handleCloseLibraryEnableDisable = () => {
    setLibraryEnableDisable(false);
  };

  const handleOpenEnableLibraryAccess = () => {
    setEnableLibraryAccess(true);
  };
  const handleCloseEnableLibraryAccess = () => {
    setEnableLibraryAccess(false);
  };

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const locale = localStorage.getItem("locale");

  const handleRemoveUser = (record) => {
    setUserToRemove(record);
    setRemoveUserModalVisible(true);
  };

  const handleConfirmRemoveUser = async () => {
    setRemoveUserModalVisible(false);
    await markRemoveEmp(userToRemove.uid);
  };

  const handleCancelRemoveUser = () => {
    setRemoveUserModalVisible(false);
  };

  const handleEnableClick = () => {
    setEnable((prevEnable) => !prevEnable);
  };

  const markRemoveEmp = async (recordUid) => {
    try {
      const contentType = "application_user";
      const uid = recordUid;
      const headers = EnteriesHeaders;
      const endpoint = updateContentEntriesEndpoint(contentType, uid, locale);

      const payload = {
        entry: {
          user_status: {
            removed: true,
          },
        },
      };

      await axios.put(endpoint, payload, { headers });
    } catch (error) {
      console.log("Error removing user!");
    }
  };

  const filteredTableData = tableData.filter(
    (user) => !user.user_status || !user.user_status.removed
  );

  const enableLibrary = async (record) => {
    setLoading(true);
    try {
      const contentType = "application_user";
      const endPoint = updateContentEntriesEndpoint(
        contentType,
        record.uid,
        locale
      );
      const headers = EnteriesHeaders;

      const payload = {
        entry: {
          preferences_and_settings: {
            library: !record.preferences_and_settings.library,
          },
        },
      };

      await axios.put(endPoint, payload, { headers });

      // Update localStorage
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      userData.preferences_and_settings.library =
        !record.preferences_and_settings.library;
      localStorage.setItem("userData", JSON.stringify(userData));

      setLoading(false);
      // showToast( `Library access ${ !record.preferences_and_settings.library ? "enabled" : "disabled" } successfully!`, "success" );
      handleOpenLibraryEnableDisable();
    } catch (error) {
      // showToast(
      //   `${literals?.toast_messages_text?.error_enabling_library_access_for_this_user}`,
      //   "error"
      // );
      handleOpenEnableLibraryAccess();
    } finally {
      setLoading(false);
    }
  };

  const sortedData = _.sortBy(data, [dataIndexMap["name"]]);
  const sortedFilteredTableData = _.sortBy(filteredTableData, [
    dataIndexMap["name"],
  ]);

  return (
    <div style={{ marginTop: 25 }}>
      {!showUserTable ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          {loading && <Spin fullscreen />}
          <Table
            columns={[
              {
                title: `${literals?.placeholder_text?.contact_name}`,
                dataIndex: "name",
                render: (text) => (
                  <a
                    style={{
                      fontSize: "18px",
                      fontWeight: 400,
                      opacity: "60%",
                    }}
                  >
                    {text}
                  </a>
                ),
              },
              {
                title: `${literals?.placeholder_text?.email_address}`,
                dataIndex: "email",
                render: (text) => (
                  <a
                    style={{
                      fontSize: "18px",
                      fontWeight: 400,
                      opacity: "60%",
                    }}
                  >
                    {text}
                  </a>
                ),
              },
              {
                title: `${literals?.placeholder_text?.telephone}`,
                dataIndex: "contact_number",
                render: (text) => (
                  <a
                    style={{
                      fontSize: "18px",
                      fontWeight: 400,
                      opacity: "60%",
                    }}
                  >
                    {text}
                  </a>
                ),
              },
              {
                title: `${literals?.placeholder_text?.contact_type}`,
                dataIndex: "contact_type",
                render: (text) => (
                  <div>
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: 400,
                        opacity: "60%",
                      }}
                    >
                      {text}
                    </a>
                  </div>
                ),
              },
              {
                title: `${literals?.library_page?.actions_name}`,
                dataIndex: "",
                key: "x",
                render: (text, record, index) => {
                  const { contact_type } = record;
                  let buttonText = literals?.buttons?.remove_btn;
                  if (contact_type === "PRIMARY") {
                    buttonText =
                      literals?.buttons?.remove_primary_btn || "Remove Primary";
                  } else if (contact_type === "SECONDARY") {
                    buttonText =
                      literals?.buttons?.remove_secondary_btn ||
                      "Remove Secondary";
                  }
                  return (
                    <div>
                      {index === 0 && (
                        <Button
                          style={{
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderColor: "#3AC1CB",
                            color: "#3AC1CB",
                            borderRadius: "8px",
                            paddingTop: "0.8%",
                            paddingBottom: "2.8%",
                            paddingLeft: "3%",
                            paddingRight: "3%",
                          }}
                        >
                          {/* {literals?.buttons?.remove_primary_btn} */}
                          {buttonText}
                        </Button>
                      )}
                      {index === 1 && (
                        <Button
                          style={{
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderColor: "#3AC1CB",
                            color: "#3AC1CB",
                            borderRadius: "8px",
                            paddingTop: "0.8%",
                            paddingBottom: "2.8%",
                            paddingLeft: "3%",
                            paddingRight: "3%",
                          }}
                        >
                          {/* {literals?.buttons?.remove_secondary_btn} */}
                          {buttonText}
                        </Button>
                      )}
                      {index > 1 && (
                        <Button
                          style={{
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderColor: "#3AC1CB",
                            color: "#3AC1CB",
                            borderRadius: "8px",
                            paddingTop: "0.8%",
                            paddingBottom: "2.8%",
                            paddingLeft: "3%",
                            paddingRight: "3%",
                          }}
                        >
                          {literals?.buttons?.remove_btn}
                        </Button>
                      )}
                      {/* <a>
                        <EditFilled
                          style={{
                            color: "#FFFFFF",
                            backgroundColor: "#3AC1CB",
                            marginLeft: "5%",
                            padding: "3.5%",
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderRadius: "5px",
                          }}
                          onClick={() => handleOpenEdituserModal(record)}
                        />
                      </a> */}
                    </div>
                  );
                },
              },
            ]}
            dataSource={sortedData}
            // dataSource={tableData.filter(
            //   (record, index) => index === 0 || index === 1
            // )}
            bordered
            title={() => (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#1B1B1B",
                }}
              >
                {literals?.buttons?.contacts_btn}
              </div>
            )}
          />
          <div style={{ textAlign: "right" }}>
            <Button
              style={{
                backgroundColor: "#3AC1CB",
                borderColor: "#3AC1CB",
                color: "#FFFFFF",
                borderStyle: "solid",
                textTransform: "uppercase",
                fontSize: 14,
                fontWeight: 600,
                paddingTop: "0.8%",
                paddingBottom: "2.5%",
                paddingLeft: "1%",
                paddingRight: "1%",
              }}
              onClick={handleManageUsers}
            >
              {literals?.buttons?.manage_users_btn}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div style={{ marginBottom: 10 }}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={handleToggleTableState}
            >
              {literals?.placeholder_text?.go_back_contacts}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Table
              columns={[
                {
                  title: `${literals?.placeholder_text?.contact_name}`,
                  dataIndex: dataIndexMap["name"],
                  render: (text) => (
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: 400,
                        opacity: "60%",
                      }}
                    >
                      {text}
                    </a>
                  ),
                },
                {
                  title: `${literals?.placeholder_text?.email_address}`,
                  dataIndex: dataIndexMap["email"],
                  render: (text) => (
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: 400,
                        opacity: "60%",
                      }}
                    >
                      {text}
                    </a>
                  ),
                },
                {
                  title: `${literals?.placeholder_text?.telephone}`,
                  dataIndex: "contact_number",
                  render: (text) => (
                    <a
                      style={{
                        fontSize: "18px",
                        fontWeight: 400,
                        opacity: "60%",
                      }}
                    >
                      {text}
                    </a>
                  ),
                },
                {
                  title: "Library Access",
                  dataIndex: "library",
                  render: (text, record) => {
                    return (
                      <Button
                        onClick={() => enableLibrary(record)}
                        style={{
                          borderStyle: "solid",
                          borderWidth: "2px",
                          borderColor: "#3AC1CB",
                          color: "#3AC1CB",
                          borderRadius: "8px",
                          paddingTop: 3,
                          paddingBottom: 5,
                          paddingLeft: 15,
                          paddingRight: 15,
                          cursor: "pointer",
                        }}
                      >
                        {record?.preferences_and_settings?.library === false
                          ? "Enable"
                          : "Disable"}
                      </Button>
                    );
                  },
                },
                // {
                //   title: "Contact Type",
                //   dataIndex: "contact_type",
                //   render: (text) => (
                //     <div>
                //       <a
                //         style={{
                //           fontSize: "18px",
                //           fontWeight: 400,
                //           opacity: "60%",
                //         }}
                //       >
                //         {text}
                //       </a>
                //     </div>
                //   ),
                // },
                {
                  title: `${literals?.library_page?.actions_name}`,
                  dataIndex: "",
                  key: "x",
                  render: (record) => (
                    <div>
                      <Button
                        onClick={() => handleRemoveUser(record)}
                        style={{
                          borderStyle: "solid",
                          borderWidth: "2px",
                          borderColor: "#3AC1CB",
                          color: "#3AC1CB",
                          borderRadius: "8px",
                          paddingTop: "0.8%",
                          paddingBottom: "2.8%",
                          paddingLeft: "3%",
                          paddingRight: "3%",
                        }}
                      >
                        {literals?.buttons?.remove_btn}
                      </Button>
                      <a>
                        <EditFilled
                          style={{
                            color: "#FFFFFF",
                            backgroundColor: "#3AC1CB",
                            marginLeft: "5%",
                            padding: "3.5%",
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderRadius: "5px",
                          }}
                          onClick={() => handleOpenEdituserModal(record)}
                        />
                      </a>
                    </div>
                  ),
                },
              ]}
              dataSource={sortedFilteredTableData}
              bordered
              title={() => (
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#1B1B1B",
                  }}
                >
                  {literals?.placeholder_text?.users}
                </div>
              )}
            />
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#EEF1F0",
                  borderColor: "#3AC1CB",
                  color: "#3AC1CB",
                  borderStyle: "solid",
                  textTransform: "uppercase",
                  fontSize: 14,
                  fontWeight: 600,
                  paddingTop: "0.7%",
                  paddingBottom: "2.5%",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                  marginRight: "1%",
                }}
                onClick={() => handleOpenAdduserModal()}
              >
                {literals?.buttons?.add_new_user_btn}
              </Button>
              {/* <Button
              style={{
                backgroundColor: "#3AC1CB",
                borderColor: "#3AC1CB",
                color: "#FFFFFF",
                borderStyle: "solid",
                textTransform: "uppercase",
                fontSize: 14,
                fontWeight: 600,
                paddingTop: "0.5%",
                paddingBottom: "2.5%",
                paddingLeft: "3%",
                paddingRight: "3%",
                // marginLeft: "2%",
              }}
              onClick={}
            >
              Next
            </Button> */}
            </div>
          </div>
        </>
      )}
      <Modal
        maskClosable={false}
        title={
          <div style={{ paddingLeft: "2%", paddingTop: "2%" }}>
            {literals?.buttons?.remove_user}
          </div>
        }
        visible={removeUserModalVisible}
        onOk={handleConfirmRemoveUser}
        onCancel={handleCancelRemoveUser}
        okButtonProps={{
          style: {
            backgroundColor: "#3AC1CB",
            borderColor: "#3AC1CB",
            color: "#FFFFFF",
          },
        }}
      >
        <p
          style={{
            paddingLeft: "2%",
            paddingTop: "1%",
            fontSize: 15,
          }}
        >
          {
            literals?.placeholder_text
              ?.are_you_sure_you_want_to_remove_this_user_
          }
        </p>
      </Modal>
      <SuccessModal
        open={libraryEnableDisable}
        onCancel={handleCloseLibraryEnableDisable}
        message="Library updated successfully"
      />
      <ErrorModal
        open={enableLibraryAccess}
        onCancel={handleCloseEnableLibraryAccess}
        message={
          literals?.toast_messages_text
            ?.error_enabling_library_access_for_this_user
        }
      />
    </div>
  );
}
