import React, { useEffect, useState } from "react";
import { AlertBox } from "../styles/Styles";
import { Alert } from "antd";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import ReactHtmlParser from "react-html-parser";
import { fetchAlertMessage } from "../context/constants";

export default function AlertMessage({ currentPage }) {
  const [data, setData] = useState([]);
  // const [shouldDisplayAlert, setShouldDisplayAlert] = useState(true);

  const locale = localStorage.getItem("locale");

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const responseData = await fetchAlertMessage(locale);
        setData(responseData);
      } catch (error) {
        // Handle error here
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromApi();
  }, [currentPage]);

  return (
    <div>
      {data && data.items && data.items.length > 0 && (
        <Alert
          className="custom-alert"
          message={
            <AlertBox>
              <b style={{ fontSize: 14 }}>{data?.items[0]?.message_type}</b>
              <div style={{ fontSize: 14, marginBottom: -16 }}>
                {ReactHtmlParser(jsonToHtml(data?.items[0]?.message?.json))}
              </div>
            </AlertBox>
          }
          type="success"
          closable
          // onClose={handleCloseAlert}
        />
      )}
    </div>
  );
}
