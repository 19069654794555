import { Button, Form, Modal, Upload, Spin, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Formfield, Logintext } from "../styles/Styles";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";
import debounce from "lodash/debounce";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
  updateContentEntriesEndpoint,
} from "../context/constants";
import { InboxOutlined } from "@ant-design/icons";
import { message } from "antd";
import { ReactComponent as Uploadimg } from "../assests/upload.svg";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: true,
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

export default function UploadDocument({
  visible,
  onClose,
  parentUid,
  apiKey,
  authToken,
  literals,
}) {
  const [inputDesc, setInputDesc] = useState("");
  const [inputTitle, setInputTitle] = useState("");
  const [toastMessage, setToastMessage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState([]);
  const [uploadDocumentSuccess, setUploadDocumentSuccess] = useState(false);
  const [errorUploadingDocument, setErrorUploadingDocument] = useState(false);

  const handleOpenUploadDocumentSuccess = () => {
    setUploadDocumentSuccess(true);
  };
  const handleCloseUploadDocumentSuccess = () => {
    setUploadDocumentSuccess(false);
  };

  const handleOpenErrorUploadingDocument = () => {
    setErrorUploadingDocument(true);
  };
  const handleCloseErrorUploadingDocument = () => {
    setErrorUploadingDocument(false);
  };

  const userData = localStorage.getItem("userData");
  const userInfo = JSON.parse(userData);

  const locale = localStorage.getItem("locale");

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const uploadDocument = async () => {
    setLoading(true);
    const uploadEndPoint = "https://query3.azurewebsites.net/api/create-asset";

    try {
      for (const file of fileList) {
        const formData = new FormData();
        formData.append("parent_folder_uid", parentUid);
        formData.append("document_description", inputDesc);
        formData.append("title", inputTitle);
        formData.append("document_upload", file.originFileObj);

        const res = await axios.post(uploadEndPoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            api_key: apiKey,
            auth_token: authToken,
          },
        });
        const uidFromResponse = res.data;

        setUid(uidFromResponse);
      }
      onClose();
      setLoading(false);
      // showToast(
      //   `${literals?.toast_messages_text?.document_uploaded_successfully}`,
      //   "success"
      // );
      const contentType1 = "documents";
      const endPoint = getContentEntriesEndpoint(contentType1, locale);

      const headers = EnteriesHeaders;

      const content_uid = userInfo.company_uid;
      const contentType = "companies";
      const getCollectionEndpoint = updateContentEntriesEndpoint(
        contentType,
        content_uid,
        locale
      );
      const response = await axios.get(getCollectionEndpoint, { headers });
      const companyEntry = response.data.entry;
      console.log(uid);
      const documents = `${uid?.title} - ${uid?.permanent_url}`;

      const payload = {
        entry: {
          title: uid?.uid,
          upload_email: userInfo?.title,
          stack_uid: companyEntry?.api,
          stack_auth: companyEntry?.token,
          documents: [documents],
        },
      };
      await axios.post(endPoint, payload, { headers });

      onClose();
      setLoading(false);
      // showToast(
      //   `${literals?.toast_messages_text?.document_uploaded_successfully}`,
      //   "success"
      // );
      handleOpenUploadDocumentSuccess();
    } catch (error) {
      // showToast(
      //   `${literals?.toast_messages_text?.error_uploading_document}`,
      //   "error"
      // );
      handleOpenErrorUploadingDocument();
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputDesc(e.target.value);
    setInputTitle(e.target.value);
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  useEffect(() => {
    if (visible) {
      setFileList([]);
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={500}
      centered
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Formfield onFinish={uploadDocument}>
          <p
            style={{
              fontSize: 22,
              fontWeight: 500,
              color: "#1B1B1B",
              textAlign: "center",
            }}
          >
            {literals?.modal_literals?.upload_new_document}
          </p>
          {/* <Form.Item name="title_name">
            <InputField
              style={{ backgroundColor: "#F3F5F5" }}
              autoComplete="off"
              type="text"
              placeholder={literals?.modal_literals?.enter_title_name}
              className="Placeholder"
              value={inputTitle}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item name="description_name">
            <InputField
              style={{ backgroundColor: "#F3F5F5" }}
              autoComplete="off"
              type="text"
              placeholder={literals?.modal_literals?.enter_description_name}
              className="Placeholder"
              value={inputDesc}
              onChange={handleInputChange}
            />
          </Form.Item> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            {/* <div
              style={{
                backgroundColor: "#EEF1F0",
                padding: 10,
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Upload
                maxCount={10}
                fileList={fileList}
                onChange={handleFileChange}
              >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <Button>{literals?.buttons?.choose_file_btn} </Button>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "5%",
                    fontSize: 14,
                  }}
                >
                  {fileList.length}
                  {literals?.modal_literals?.file_selected}
                </p>
              </div>
              </Upload>
            </div> */}
            <Dragger {...props} width="100%">
              <p className="ant-upload-drag-icon">{<Uploadimg />}</p>
              <div
                style={{ display: "flex", gap: 10, justifyContent: "center" }}
              >
                <p className="ant-upload-text">Drag and Drop here or</p>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="login-form-button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "rgba(24, 43, 55, 0.07)",
                      color: "#182B37",
                      borderStyle: "none",
                      paddingTop: "6%",
                      paddingBottom: "6%",
                      paddingLeft: "30%",
                      paddingRight: "30%",
                      textTransform: "uppercase",
                      borderRadius: "200px",
                      // marginTop: "20px",
                    }}
                  >
                    <Logintext>{literals?.buttons?.upload_btn}</Logintext>
                  </Button>
                </Form.Item>
              </div>
            </Dragger>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#ffffff",
                    borderColor: "#2B8E96",
                    color: "#1B1B1B",
                    paddingTop: "20%",
                    paddingBottom: "20%",
                    paddingLeft: "30%",
                    paddingRight: "30%",
                    textTransform: "uppercase",
                    borderRadius: "200px",
                  }}
                  onClick={onClose}
                >
                  <Logintext>{literals?.buttons?.cancel_btn}</Logintext>
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background:
                      "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                    color: "#FFFFFF",
                    borderStyle: "none",
                    paddingTop: "6%",
                    paddingBottom: "6%",
                    paddingLeft: "30%",
                    paddingRight: "30%",
                    textTransform: "uppercase",
                    borderRadius: "200px",
                  }}
                >
                  <Logintext>{literals?.buttons?.upload_btn}</Logintext>
                </Button>
              </Form.Item>
              {loading && <Spin fullscreen />}
            </div>
          </div>
        </Formfield>
      </div>
      <SuccessModal
        open={uploadDocumentSuccess}
        onCancel={handleCloseUploadDocumentSuccess}
        message={literals?.toast_messages_text?.document_uploaded_successfully}
      />
      <ErrorModal
        open={errorUploadingDocument}
        onCancel={handleCloseErrorUploadingDocument}
        message={literals?.toast_messages_text?.error_uploading_document}
      />
    </Modal>
  );
}
