import React, { useEffect, useRef, useState } from "react";
import {
  Layout,
  Menu,
  theme,
  Row,
  Col,
  Card,
  Pagination,
  Spin,
  Select,
  Dropdown,
  Button,
} from "antd";
import { IoSend } from "react-icons/io5";
import { PiTreeStructure } from "react-icons/pi";
import Link from "antd/es/typography/Link";
import image from "../assests/image.png";
import AlertMessage from "../components/AlertMessage";
import MainHeader from "../components/MainHeader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import ReactHtmlParser from "react-html-parser";
import { SearchInput } from "../styles/Styles";
import Categories from "../components/Categories";
import { useNavigate } from "react-router-dom";
import AnswerModal from "../components/AnswerModal";
import InstructionModal from "../components/InstructionModal";
import {
  CDNHeaders,
  EnteriesHeaders,
  getContentEntriesEndpoint,
  getTaxonomy,
  searchQuery,
  updateContentEntriesEndpoint,
} from "../context/constants";
import ArticleModal from "../components/ArticleModal";
import SubMenu from "antd/es/menu/SubMenu";
import DialogModal from "../components/DialogModal";
import { useLiterals } from "../context/LiteralsContext";
import { BsQuestionCircle } from "react-icons/bs";
import { Icon } from "@iconify/react/dist/iconify.js";
import article_img from "../assests/article_image.png";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { ReactComponent as HomeIcon } from "../assests/home-active.svg";
import { ReactComponent as Dialogues } from "../assests/dialogues.svg";
import { ReactComponent as Library } from "../assests/library.svg";
import { ReactComponent as Collections } from "../assests/collections.svg";
import { ReactComponent as Settings } from "../assests/settings.svg";

const { Option } = Select;

const { Sider, Content } = Layout;
// const onFinish = (values) => {
//   console.log("Success:", values);
// };
// const onFinishFailed = (errorInfo) => {
//   console.log("Failed:", errorInfo);
// };

const marks = {
  0: "0%",
  20: "20%",
  40: "40%",
  60: "60%",
  80: "80%",
  100: "100%",
};

const getTaxonomyByLocale = (locale) => {
  switch (locale) {
    case "pt-br":
      return "ehsands_categories_pt_br";
    case "es-419":
      return "ehss_category";
    case "en-us":
    default:
      return "ehss_category";
  }
};

export default function Home() {
  const literals = useLiterals();
  const [collapsed, setCollapsed] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [dialogVisible, setDialogModalVisible] = useState(false);
  const [isdialogVisible, setIsDialogModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [clickedCardIndex, setClickedCardIndex] = useState(null);
  const [articleData, setArticleData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef(null);
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  // const [temp, setTemp] = useState(0.5);
  const [subCategories, setSubCategories] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [primaryUser, setPrimaryUser] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [copy, setCopy] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [settingsUpdated, setSettingsUpdated] = useState(false);
  const [collectionsData, setCollectionsData] = useState([]);
  const [categories, setCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [locale, setLocale] = useState(
    () => localStorage.getItem("locale") || "en-us"
  );
  const [apiKey, setApiKey] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [selectedCol, setSelectedCol] = useState("Chem.Aid");

  const handleMenuClick = (e) => {
    setSelectedCol(e.item.props.value);
  };

  useEffect(() => {
    const handleLocaleChange = () => {
      const storedLocale = localStorage.getItem("locale");
      setLocale(storedLocale || "en-us");
    };

    window.addEventListener("storage", handleLocaleChange);

    return () => {
      window.removeEventListener("storage", handleLocaleChange);
    };
  }, [locale]);

  const [temp, setTemp] = useState(() => {
    const storedTemp = localStorage.getItem("temp");
    return storedTemp !== null ? parseFloat(storedTemp) : 0.5;
    // const storedTemp = localStorage.getItem("userData");
    // const userData = JSON.parse(storedTemp);
    // return userData && userData.temp ? parseFloat(userData.temp) : 0.5;
  });

  // useEffect(() => {
  //   const userDataString = localStorage.getItem("userData");
  //   const userData = JSON.parse(userDataString);
  //   userData.temp = temp;
  //   localStorage.setItem("userData", JSON.stringify(userData));
  // }, [temp]);

  // const userDataString = localStorage.getItem("userData");
  // const userData = JSON.parse(userDataString);

  useEffect(() => {
    userData.preferences_and_settings.creativity_precision = temp;
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [temp]);

  const modalContentRef = useRef(null);

  const handleSliderChange = (value) => {
    switch (value) {
      case 0:
        setTemp(() => {
          localStorage.setItem("temp", "1.0");
          setUserPrefSettings(1.0);
          return 1.0;
        });
        break;
      case 20:
        setTemp(() => {
          localStorage.setItem("temp", "0.8");
          setUserPrefSettings(0.8);
          return 0.8;
        });
        break;
      case 40:
        setTemp(() => {
          localStorage.setItem("temp", "0.6");
          setUserPrefSettings(0.6);
          return 0.6;
        });
        break;
      case 60:
        setTemp(() => {
          localStorage.setItem("temp", "0.4");
          setUserPrefSettings(0.4);
          return 0.4;
        });
        break;
      case 80:
        setTemp(() => {
          localStorage.setItem("temp", "0.2");
          setUserPrefSettings(0.2);
          return 0.2;
        });
        break;
      default:
        setTemp(() => {
          localStorage.setItem("temp", "0.0");
          setUserPrefSettings(0.0);
          return 0.0;
        });
    }
  };

  // const parseResponseData = (text) => {
  //   return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  // };

  const navigate = useNavigate();

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const updateArticleViews = async (articleId) => {
    try {
      const contentType = "article_control";
      const endpoint = getContentEntriesEndpoint(contentType, locale);
      const headers = EnteriesHeaders;

      const res = await axios.get(endpoint, { headers });
      const existingArticle = res?.data?.entry;

      if (existingArticle && existingArticle.title === articleId) {
        const updatedViews = (existingArticle.number_of_views || 0) + 1;

        const payload = {
          entry: {
            number_of_views: updatedViews,
            indexed: true,
          },
        };

        await axios.put(endpoint, payload, { headers });

        console.log(
          `Number of views updated for article ${articleId}: ${updatedViews}`
        );
      } else {
        console.log(
          `Article with id ${articleId} not found. Creating new entry...`
        );

        const payload = {
          entry: {
            title: articleId,
            number_of_views: 1,
            indexed: true,
          },
        };

        await axios.post(endpoint, payload, { headers });

        console.log(`New entry created for article ${articleId}`);
      }
    } catch (error) {
      console.error("Error updating article views:", error);
    }
  };

  const handleOpenModal = (index) => {
    const clickedArticle = articleData[index];
    if (clickedArticle) {
      updateArticleViews(clickedArticle.uid);
    }
    setModalVisible(true);
    setClickedCardIndex(index);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setClickedCardIndex(null);
  };

  const handleOpenAnswerModal = () => {
    setAnswerModalVisible(true);
  };

  const handleCloseAnswerModal = () => {
    setAnswerModalVisible(false);
  };

  const handleOpenIsDialogModal = () => {
    setIsDialogModalVisible(true);
  };

  const handleCloseIsDailogModal = () => {
    setIsDialogModalVisible(false);
  };

  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };

  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };
  const handleCloseDailogModal = () => {
    setDialogModalVisible(false);
  };
  useEffect(() => {
    if (helpModalVisible && modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }, [helpModalVisible]);

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    if (input.length <= 8192) {
      setSearchInput(input);
    }
  };

  useEffect(() => {
    const fetchAllCategories = async () => {
      // const contentType = "literals";
      // const endPoint = getContentEntriesEndpoint(contentType, locale);
      // const headers = EnteriesHeaders;
      // try {
      //   const res = await axios.get(endPoint, { headers });

      //   setCategories(res.data.entries[0].categories);
      //   setIsLoading(false);
      // } catch (error) {
      //   console.log(error.message);
      // }

      setIsLoading(true);
      const url = getTaxonomy;
      const payload = {
        taxonomy_name: getTaxonomyByLocale(locale),
        depth: "2",
      };
      try {
        const res = await axios.post(url, payload);
        setCategories(res.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchAllCategories();
  }, [locale]);

  // console.log("selectedCategory", selectedCategory);

  useEffect(() => {
    const fetchAllArticles = async () => {
      let endpoint = `https://cdn.contentstack.io/v3/content_types/articles/entries?query=`;
      if (selectedSubCategory) {
        endpoint += `{"taxonomies.ehss_category" : { "$in" : ["${selectedSubCategory}"] }}`;
      } else if (selectedCategory) {
        endpoint += `{"taxonomies.ehss_category" : { "$in" : ["${selectedCategory}"] }}`;
      } else {
        if (categories && categories.length > 0) {
          const categoryUids = categories.map((category) => category.uid);
          endpoint += `{"taxonomies.ehss_category" : { "$in" : ${JSON.stringify(
            categoryUids
          )} }}`;
        }
      }
      endpoint += `&locale=${locale}&limit=20&sort={"date":"desc"}`;

      const headers = CDNHeaders;
      setIsLoading(true);
      try {
        const res = await axios.get(endpoint, { headers });
        setIsLoading(false);
        setArticleData(res.data.entries);
        setResponseData(null);
        setSearchInput("");
      } catch (error) {
        toast.error(
          `${literals?.toast_messages_text?.failed_to_fetch_articles_please_try_again}`
        );
      }
    };
    fetchAllArticles();
  }, [selectedCategory, selectedSubCategory, currentPage, categories, locale]);

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const params = {
        question: searchInput,
        temperature: temp,
      };
      if (searchInput.trim() !== "") {
        const response = await axios.post(searchQuery, params);
        setResponseData(response.data);
        setDialogModalVisible(true);
        setClickedCardIndex(-1);
        setLiked(false);
        setDisliked(false);
        setCopy(false);
      } else {
        setResponseData(null);
      }
    } catch (error) {
      console.error(
        "Error submitting data:",
        error.response ? error.response.data : error.message
      );
      toast.error(`${literals?.toast_messages_text?.submission_failed}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (responseData !== null) {
      PostQnA();
    }
  }, [responseData]);

  const PostQnA = async () => {
    try {
      const qaContentType = "qanda";
      const endpoint = getContentEntriesEndpoint(qaContentType, locale);
      const headers = EnteriesHeaders;
      const RandomTitle = generateRandomString(8);
      const data = {
        entry: {
          title: RandomTitle,
          qanda_user: userData?.title,
          question: searchInput,
          answer: responseData,
        },
      };
      const res = await axios.post(endpoint, data, { headers });
      const dTitle = JSON.parse(localStorage.getItem("dTitle")) || [];
      dTitle.push({ dialogueTitle: res.data.entry.title });
      localStorage.setItem("dTitle", JSON.stringify(dTitle));
    } catch (error) {
      toast.error(
        `${literals?.toast_messages_text?.unable_to_perform_the_action_please_try_again_later}`
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const setSelectCategoryAndResetPage = (category) => {
    setCurrentPage(1);
    setSelectedCategory(category);
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [searchInput]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const fcContentType = "companies";
        const fcUid = userData?.company_uid;
        const endPoint = updateContentEntriesEndpoint(
          fcContentType,
          fcUid,
          locale
        );

        const headers = EnteriesHeaders;
        const response = await axios.get(endPoint, {
          headers,
        });
        const companies = response.data.entry;
        setApiKey(companies?.api);
        setAuthToken(companies?.token);
        const filteredData =
          companies?.contact_details[0]?.contact_details?.name ===
          userData.user_name;

        const filteredData1 =
          companies?.contact_details[1]?.contact_details?.name ===
          userData.user_name;
        setFilterData(filteredData || filteredData1);
        if (filteredData === true) {
          setPrimaryUser(true);
        } else {
          setPrimaryUser(false);
        }
        localStorage.setItem("primaryUser", filteredData || filteredData1);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    fetchCompany();
  }, []);

  const setUserPrefSettings = async (temp) => {
    try {
      const contentType = "application_user";
      const contentUid = userData?.uid;
      if (!contentUid) {
        console.error("User data is missing or invalid.");
        return;
      }

      const endpoint = updateContentEntriesEndpoint(
        contentType,
        contentUid,
        locale
      );

      const headers = EnteriesHeaders;

      const payload = {
        entry: {
          preferences_and_settings: {
            creativity_precision: temp,
          },
        },
      };
      if (
        !settingsUpdated &&
        parseFloat(temp) !==
          userData?.preferences_and_settings?.creativity_precision
      ) {
        const res = await axios.put(endpoint, payload, { headers });

        // console.log(res.data);

        const updatedTemp =
          res.data.entry.preferences_and_settings?.creativity_precision;
        if (updatedTemp !== undefined && updatedTemp !== null) {
          setTemp(updatedTemp);
          localStorage.setItem("temp", updatedTemp);
        }
        setSettingsUpdated(true);
      } else {
        console.log("creativity_precision is already up to date or unchanged");
      }
    } catch (error) {
      toast.error(
        `${literals?.toast_messages_text?.error_updating_creative_and_precision_value}`
      );
    }
  };

  // useEffect(() => {
  //   setUserPrefSettings(temp);
  // }, [temp]);
  useEffect(() => {
    const getCollections = async () => {
      try {
        const contentType = "collections";
        const headers = {
          api_key: apiKey,
          authorization: authToken,
        };
        const endPoint = getContentEntriesEndpoint(contentType, locale);

        const res = await axios.get(endPoint, { headers });
        setCollectionsData(res?.data?.entries);
      } catch (error) {
        console.log("Error fetching collections");
      }
    };
    getCollections();
  }, [locale, apiKey, authToken]);

  const collections = (
    <Menu onClick={handleMenuClick} style={{ minWidth: "140px" }}>
      <Menu.Item value="Chem.Aid">Chem.Aid</Menu.Item>
      <Menu.Item value="Company (all) collections">
        Company (all) collections
      </Menu.Item>
      {/* {collectionsData.map((item, index) => (
        <Option value={item.title} key={index}>
          {item.title.length > 18
            ? `${item.title.slice(0, 18)}...`
            : item.title}
        </Option>
      ))} */}
      {collectionsData
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((item, index) => (
          <Menu.Item value={item.title} key={index}>
            {item.title.length > 18
              ? `${item.title.slice(0, 18)}...`
              : item.title}
          </Menu.Item>
        ))}
    </Menu>
  );

  const itemRender = (page, type, originalElement) => {
    if (type === "prev") {
      return (
        <a style={{ backgroundColor: "#CDD4E3", padding: 8, borderRadius: 5 }}>
          <ArrowLeftOutlined style={{ color: "white" }} />
        </a>
      );
    }
    if (type === "next") {
      return (
        <a style={{ backgroundColor: "#CDD4E3", padding: 8, borderRadius: 5 }}>
          <ArrowRightOutlined style={{ color: "white" }} />
        </a>
      );
    }
    return originalElement;
  };

  // console.log("article data from home", articleData);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {/* // <Layout> */}
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{
          maxWidth: "12%",
        }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={collapsed ? 55 : 95} alt="Logo" />
        </div>
        <Menu
          theme="light"
          mode="inline"
          onClick={() => navigate("/home")}
          defaultSelectedKeys={["1"]}
        >
          <Menu.Item key="1" icon={<HomeIcon />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu theme="light" mode="inline" onClick={() => navigate("/dialogue")}>
          <Menu.Item key="7" icon={<Dialogues />}>
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>
        {userData?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
            >
              <Menu.Item key="5" icon={<Library />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>

            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
            >
              <Menu.Item key="6" icon={<Collections />}>
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}
        <Menu theme="light" mode="inline">
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<Settings />}
          >
            {/* <Menu.Item
                key="2"
                icon={<GiSettingsKnobs />}
                onClick={handleOpenAnswerModal}
              >
                {literals?.sidebar?.answers_menu}
              </Menu.Item> */}
            {filterData === true && (
              <>
                <Menu.Item
                  key="1"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/company")}
                >
                  {literals?.buttons?.company_btn}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/contacts")}
                >
                  {literals?.buttons?.contacts_btn}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/subscription")}
                >
                  {literals?.sidebar?.subscription_menu}
                </Menu.Item>
              </>
            )}
            {/* <Menu.Item
              key="4"
              icon={<MdOutlineSubscriptions />}
              onClick={() => navigate("/subscription")}
            >
              {literals?.sidebar?.subscription_menu}
            </Menu.Item> */}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            justifyContent: collapsed ? "center" : "flex-start",
          }}
        >
          <BsQuestionCircle
            className="question"
            style={{ color: "#fff", fontSize: 22 }}
          />
          {!collapsed && (
            <p style={{ fontSize: 15, fontWeight: 500, marginLeft: "1rem" }}>
              Help & Support
            </p>
          )}
        </Link>
      </Sider>

      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
          visible={modalVisible}
          onClose={handleCloseModal}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          clickedCardIndex={clickedCardIndex}
          handleOpenModal={handleOpenModal}
        />
        <Content
          style={{
            // margin: "24px 16px",
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
        >
          <div>
            <AlertMessage currentPage={currentPage} />
            <div className="my-2 mx-4">
              {/* <div className="d-flex justify-content-between align-items-center responce_search"> */}
              <div
                className="d-flex justify-content-between align-items-center responce_search"
                style={{
                  position: "relative",
                  gap: "10px",
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 20,
                }}
              >
                {/* <SearchInput
                  addonAfter={collections}
                  placeholder={literals?.placeholder_text?.search_query}
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && !event.shiftKey) {
                      event.preventDefault();
                      handleSubmit();
                    }
                  }}
                /> */}
                <SearchInput
                  placeholder={literals?.placeholder_text?.search_query}
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && !event.shiftKey) {
                      event.preventDefault();
                      handleSubmit();
                    }
                  }}
                  style={{
                    height: "50px",
                    paddingLeft: "16px",
                    paddingRight: "120px",
                    fontSize: "14px",
                    color: "#667085",
                    borderRadius: "24px",
                    border: "1px solid #F5F5F5",
                    backgroundColor: "#FFFFFF",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "70px",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Dropdown overlay={collections} trigger={["click"]}>
                    <Button
                      type="text"
                      style={{
                        fontSize: "14px",
                        color: "#667085",
                        borderRadius: "200px",
                        backgroundColor: "#182B3712",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {selectedCol} <DownOutlined />
                    </Button>
                  </Dropdown>
                  <Button
                    onClick={handleOpenAnswerModal}
                    type="text"
                    icon={<Icon icon="system-uicons:filtering" />}
                    style={{
                      color: "#182B37",
                      opacity: "70%",
                      borderRadius: "200px",
                      backgroundColor: "#182B3712",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                </div>
                {/* <Charlimit>
                  <span
                    style={{
                      position: "absolute",
                      bottom: 5,
                      right: 45,
                      fontSize: 13,
                      color: searchInput.length <= 8192 ? "gray" : "red",
                    }}
                  >
                    {searchInput.length}/8192
                  </span>
                </Charlimit> */}
                <Link
                  onClick={handleSubmit}
                  className="search_button text-center"
                  htmltype="submit"
                  style={{
                    background:
                      "linear-gradient(90deg, #3AC1CB 0%, #1D6065 150%)",
                    height: 46,
                    width: 46,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    borderRadius: 200,
                  }}
                >
                  {!loading ? <IoSend /> : <Spin size="large" fullscreen />}
                </Link>
              </div>
            </div>
            <Categories
              selectedCategory={selectedCategory}
              categories={categories}
              setSelectedSubCategory={setSelectedSubCategory}
              selectedSubCategory={selectedSubCategory}
              setSelectCategoryAndResetPage={setSelectCategoryAndResetPage}
              setSubCategories={setSubCategories}
              subCategories={subCategories}
              // isLoading={isLoading}
              literals={literals}
            />
            <div className="mt-4">
              <Row
                gutter={[10, 10]}
                style={{
                  marginLeft: 30,
                  marginRight: 40,
                  marginTop: 40,
                }}
              >
                {/* {articleData
                  .slice((currentPage - 1) * 12, currentPage * 12)
                  .map((card, index) => (
                    <Col md={12} span={24} key={index}>
                      <Card
                        style={{
                          cursor: "pointer",
                          // height: "100%",
                          // paddingTop: 40,
                        }}
                        className={`content_wrapper ${
                          index === clickedCardIndex ? "clicked" : ""
                        }`}
                        onClick={() => handleOpenModal(index)} //() => handleOpenModal(index)
                      >
                        <Row gutter={[10, 10]}>
                          <Col md={4} style={{ marginRight: 40 }}>
                            <img
                              src={
                                card?.image?.url ? card.image.url : article_img
                              }
                              style={{
                                objectFit: "cover",
                                height: 112,
                                width: 124,
                                borderRadius: 8,
                              }}
                              alt="article img"
                            />
                          </Col>
                          <Col md={18}>
                            <h4>{card.title}</h4>
                            <div
                              className="mb-0"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {ReactHtmlParser(
                                jsonToHtml(card?.article_description)
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))} */}
                {articleData
                  .slice((currentPage - 1) * 12, currentPage * 12)
                  .map((card, index) => {
                    const globalIndex = (currentPage - 1) * 12 + index;

                    return (
                      <Col md={12} span={24} key={globalIndex}>
                        <Card
                          style={{
                            cursor: "pointer",
                          }}
                          className={`content_wrapper ${
                            globalIndex === clickedCardIndex ? "clicked" : ""
                          }`}
                          onClick={() => handleOpenModal(globalIndex)}
                        >
                          <Row gutter={[10, 10]}>
                            <Col md={4} style={{ marginRight: 40 }}>
                              <img
                                src={
                                  card?.image?.url
                                    ? card.image.url
                                    : article_img
                                }
                                style={{
                                  objectFit: "cover",
                                  height: 112,
                                  width: 124,
                                  borderRadius: 8,
                                }}
                                alt="article img"
                              />
                            </Col>
                            <Col md={18}>
                              <h4>{card.title}</h4>
                              <div
                                className="mb-0"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {ReactHtmlParser(
                                  jsonToHtml(card?.article_description)
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
              <Pagination
                defaultCurrent={1}
                total={articleData.length}
                size="default"
                onChange={handlePageChange}
                current={currentPage}
                className="text-center my-4"
                pageSize={12}
                itemRender={itemRender}
              />
            </div>
          </div>
        </Content>
      </Layout>

      <ArticleModal
        visible={modalVisible}
        onClose={handleCloseModal}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        articleData={articleData}
        clickedCardIndex={clickedCardIndex}
        isLoading={isLoading}
      />

      <AnswerModal
        visible={answerModalVisible}
        onClose={handleCloseAnswerModal}
        marks={marks}
        handleSliderChange={handleSliderChange}
        temp={temp}
        right="5%"
        top="25%"
      />

      <InstructionModal
        visible={helpModalVisible}
        onClose={handleClosehelpuserModal}
        modalContentRef={modalContentRef}
      />
      <DialogModal
        visible={dialogVisible}
        onClose={handleCloseDailogModal}
        resdata={responseData}
        handleSubmit={handleSubmit}
        question={searchInput}
        temp={temp}
        collections={collections}
        literals={literals}
      />
    </Layout>
  );
}
