import React, { useState } from "react";
import {
  ChooseLang,
  CopyRight,
  MainContainer,
  Nav,
  RightContainer,
  Signin,
  Signintext,
} from "../styles/Styles";
import image from "../assests/image.png";
import FormBanner from "../components/FormBanner";
import FormHeader from "../components/FormHeader";
import { useLocation } from "react-router-dom";
import LanguageSelect from "../components/LanguageSelect";
import { useLiterals } from "../context/LiteralsContext";
import VerifyForm from "../components/VerifyForm";
import { CiEdit } from "react-icons/ci";

export default function Verify() {
  const literals = useLiterals();
  const location = useLocation();
  const email = location.state?.email;

  const [loading, setLoading] = useState(false);

  // const [filteredUser, setFilteredUser] = useState(null);

  return (
    <div>
      <MainContainer>
        <FormBanner />
        <ChooseLang
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            right: 0,
            top: 0,
            margin: 20,
          }}
        >
          <LanguageSelect width="100%" />
        </ChooseLang>
        <RightContainer>
          <div
            style={{
              marginTop: "10%",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormHeader
              image={image}
              title={"Verify Token"}
              subTitle={"We have sent Token to this email address"}
            />
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "center",
              }}
            >
              <p style={{ fontSize: 16, fontWeight: 500, marginLeft: "-65%" }}>
                {email}
              </p>
              <a href="/forget">
                <CiEdit />
              </a>
            </div>

            <VerifyForm
              emailpattern={"^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"}
              literals={literals}
              loading={loading}
              setLoading={setLoading}
              email={email}
            />
            <Signin>
              <Signintext>{literals?.placeholder_text?.signin_info}</Signintext>
              <Nav to="/signup">{literals?.buttons?.signup_btn}</Nav>
            </Signin>
          </div>
          <CopyRight>{literals?.email_content?.copyrights}</CopyRight>
        </RightContainer>
      </MainContainer>
    </div>
  );
}
