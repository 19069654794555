import { Button } from "antd";
import React from "react";

export default function CompanyPage({
  companyData,
  handleOpenModal,
  literals,
}) {
  return (
    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundColor: "#fff",
          width: "100%",
          height: "100%",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 25,
          padding: 20,
        }}
      >
        {/* Company name */}
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "30%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                {literals?.placeholder_text?.company_name_text}
                {/* <span style={{ color: "red" }}>*</span> */}
              </p>
            </div>
            {/* <div>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: "60%",
                }}
              >
                {companyData?.city}
              </p>
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 400, opacity: "60%" }}>
                {companyData?.title}
                {/* <span style={{ color: "red" }}>*</span> */}
              </p>
            </div>
            {/* <div>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: "60%",
                }}
              >
                {companyData?.zip}
              </p>
            </div> */}
          </div>
        </div>

        {/* Email Domain */}
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "30%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                {literals?.placeholder_text?.email_domain}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 400, opacity: "60%" }}>
                {companyData?.email}
              </p>
            </div>
          </div>
        </div>

        {/* Address */}
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "30%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                {literals?.placeholder_text?.address_text}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 400, opacity: "60%" }}>
                {companyData?.street || "-"}
              </p>
            </div>
          </div>
        </div>

        {/* City */}
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "30%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                {literals?.placeholder_text?.city_text}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: "60%",
                }}
              >
                {companyData?.city}
              </p>
            </div>
          </div>
        </div>

        {/* State */}
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "30%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                {literals?.placeholder_text?.state_text}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: "60%",
                }}
              >
                {companyData?.state}
              </p>
            </div>
          </div>
        </div>

        {/* Zip */}
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "30%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                {literals?.placeholder_text?.zip_text}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: "60%",
                }}
              >
                {companyData?.zip}
              </p>
            </div>
          </div>
        </div>

        {/* Country */}
        <div
          style={{
            display: "flex",
            gap: 15,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "30%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p style={{ fontSize: 18, fontWeight: 500 }}>
                {literals?.placeholder_text?.country_text}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              backgroundColor: "#EEF1F0",
              borderRadius: "10px",
              paddingTop: 13,
              paddingLeft: 20,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: "60%",
                }}
              >
                {companyData?.country}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          textAlign: "right",
        }}
      >
        <Button
          style={{
            backgroundColor: "#EEF1F0",
            borderColor: "#3AC1CB",
            color: "#3AC1CB",
            borderStyle: "solid",
            textTransform: "uppercase",
            fontSize: 14,
            fontWeight: 600,
            paddingTop: "0.9%",
            paddingBottom: "2.5%",
            paddingLeft: "3%",
            paddingRight: "3%",
          }}
          onClick={() => handleOpenModal()}
        >
          {literals?.buttons?.edit_btn}
        </Button>
      </div>
    </div>
  );
}
