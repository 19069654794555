import { Card, Modal, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import SearchArticleModal from "./SearchArticleModal";
import {
  EnteriesHeaders,
  updateContentEntriesEndpoint,
} from "../context/constants";
import noResultsFoundGif from "../assests/noresultsfound.gif";
import debounce from "lodash/debounce";
import _ from "lodash";
import ErrorModal from "./ErrorModal";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function SearchResultsModal({
  isModalVisible,
  handleCancel,
  searchArticles,
  loading,
  literals,
}) {
  const [searchedArticle, setSearchedArticle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [errorSearchingArticle, setErrorSearchingArticle] = useState(false);

  const handleOpenErrorSearchingArticle = () => {
    setErrorSearchingArticle(true);
  };
  const handleCloseErrorSearchingArticle = () => {
    setErrorSearchingArticle(false);
  };

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const handleSearchArticle = async (articleUid) => {
    setIsLoading(true);
    try {
      const searcharticle = "articles";
      const searchuid = articleUid;
      const endPoint = updateContentEntriesEndpoint(searcharticle, searchuid);
      const headers = EnteriesHeaders;
      const res = await axios.get(endPoint, { headers });
      setSearchedArticle(res.data);
      setIsLoading(false);
    } catch (error) {
      // showToast(
      //   `${literals?.toast_messages_text?.error_searching_article}`,
      //   "error"
      // );
      handleOpenErrorSearchingArticle();
    } finally {
      setIsLoading(false);
    }
  };

  const [ModalVisible, setModalVisible] = useState(false);

  const showModal = (articleUid) => {
    setModalVisible(true);
    handleSearchArticle(articleUid);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      open={isModalVisible}
      onCancel={handleCancel}
      maskClosable={false}
      footer={null}
      width={1200}
      centered
      className="modal_content"
    >
      <button
        onClick={handleCancel}
        style={{
          border: "none",
          boxShadow: "none",
          backgroundColor: "#F3F5F5",
          position: "absolute",
          right: 5,
          top: 5,
        }}
      >
        <Icon icon="mingcute:close-line" width={24} />
      </button>
      <div style={{ padding: 20 }} className="search_modal">
        <p style={{ fontSize: 18, fontWeight: 500 }}>
          {literals?.placeholder_text?.search_results}
        </p>
        {!searchArticles && searchArticles.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={noResultsFoundGif} alt="No Results Found" />
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            {Array.isArray(searchArticles) &&
              searchArticles.map((article, index) => (
                <Card
                  key={index}
                  style={{ backgroundColor: "#fff", padding: 10 }}
                  hoverable
                  onClick={() => showModal(article.uid)}
                >
                  <p
                    style={{ fontSize: 18, fontWeight: 500, color: "#06152B" }}
                  >
                    {article.title}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#06152B",
                      opacity: "60%",
                    }}
                  >
                    {article.description}
                  </p>
                  {/* <p style={{ fontSize: 14, fontWeight: 400, opacity: "60%" }}>
                    Score: {article.score}
                  </p> */}
                </Card>
              ))}
          </div>
        )}
      </div>
      {loading && searchArticles && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" className="custom-spin" />
          <p style={{ marginLeft: 20 }}>
            {literals?.placeholder_text?.please_wait_article_is_being_fetched}
          </p>
        </div>
      )}
      <SearchArticleModal
        visible={ModalVisible}
        onClose={handleModalCancel}
        articleData={searchedArticle}
        isLoading={isLoading}
      />

      <ErrorModal
        open={errorSearchingArticle}
        onCancel={handleCloseErrorSearchingArticle}
        message={literals?.toast_messages_text?.error_searching_article}
      />
    </Modal>
  );
}
