import {
  Breadcrumb,
  Button,
  Layout,
  Menu,
  Table,
  theme,
  Spin,
  Dropdown,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import MainHeader from "../components/MainHeader";
import { ToastContainer, toast } from "react-toastify";
import Sider from "antd/es/layout/Sider";
import image from "../assests/image.png";
import { useNavigate } from "react-router-dom";
import SubMenu from "antd/es/menu/SubMenu";
import { PiTreeStructure } from "react-icons/pi";
import { Content } from "antd/es/layout/layout";
import AnswerModal from "../components/AnswerModal";
import InstructionModal from "../components/InstructionModal";
import { Logintext } from "../styles/Styles";
import { FaPlus } from "react-icons/fa";
import { MdCollectionsBookmark } from "react-icons/md";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
  updateContentEntriesEndpoint,
} from "../context/constants";
import axios from "axios";
import debounce from "lodash/debounce";
import _ from "lodash";
import DeleteCollection from "../components/DeleteCollection";
import CreateCollection from "../components/CreateCollection";
import { useLiterals } from "../context/LiteralsContext";
import { BsQuestionCircle } from "react-icons/bs";
import { ReactComponent as HomeIcon } from "../assests/home.svg";
import { ReactComponent as Dialogues } from "../assests/dialogues.svg";
import { ReactComponent as Library } from "../assests/library.svg";
import { ReactComponent as Collections } from "../assests/collections-active.svg";
import { ReactComponent as Settings } from "../assests/settings.svg";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { EllipsisOutlined } from "@ant-design/icons";
import SuccessModal from "../components/SuccessModal";
import ErrorModal from "../components/ErrorModal";
import Link from "antd/es/typography/Link";

const marks = {
  0: "0%",
  20: "20%",
  40: "40%",
  60: "60%",
  80: "80%",
  100: "100%",
};

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    name: record.name,
  }),
};

export default function Collection() {
  const literals = useLiterals();

  const [collapsed, setCollapsed] = useState(false);
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  const [temp, setTemp] = useState(() => {
    const storedTemp = localStorage.getItem("temp");
    return storedTemp !== null ? parseFloat(storedTemp) : 0.5;
  });
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [deletefolderModal, setDeleteFolderModal] = useState(false);
  const [adddocModal, setAddDocModal] = useState(false);
  const [collectionModal, setCollectionModal] = useState(false);
  const [collectionPath, setCollectionPath] = useState([]);
  const [activeCollection, setActiveCollection] = useState(null);
  const [activeCollectionUID, setActiveCollectionUID] = useState(null);
  const [isdialogVisible, setIsDialogModalVisible] = useState(false);
  const [collectionsData, setCollectionsData] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [existingDocumentUIDs, setExistingDocumentUIDs] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [isDocumentsAdded, setDocumentsAdded] = useState(false);
  const [isDocumentsRemoved, setDocumentsRemoved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [documentsData, setDocumentsData] = useState([]);
  const [documentsAlreadyExists, setDocumentsAlreadyExists] = useState(false);
  const [documentAddedSuccessfully, setDocumentAddedSuccessfully] =
    useState(false);
  const [selectAnyCollection, setSelectAnyCollection] = useState(false);
  const [errorAddingDocument, setErrorAddingDocument] = useState(false);
  const [removedDocumentSuccessfully, setRemovedDocumentSuccessfully] =
    useState(false);
  const [errorRemovingDocuments, setErrorRemovingDocuments] = useState(false);
  const [collectionDeletedSuccessfully, setCollectionDeletedSuccessfully] =
    useState(false);
  const [errorDeletingSelectedCollection, setErrorDeletingSelectedCollection] =
    useState(false);

  const handleOpenDocumentsAlreadyExists = () => {
    setDocumentsAlreadyExists(true);
  };
  const handleCloseDocumentsAlreadyExists = () => {
    setDocumentsAlreadyExists(false);
  };

  const handleOpenDocumentAddedSuccessfully = () => {
    setDocumentAddedSuccessfully(true);
  };
  const handleCloseDocumentAddedSuccessfully = () => {
    setDocumentAddedSuccessfully(false);
  };

  const handleOpenSelectAnyCollection = () => {
    setSelectAnyCollection(true);
  };
  const handleCloseSelectAnyCollection = () => {
    setSelectAnyCollection(false);
  };

  const handleOpenErrorAddingDocument = () => {
    setErrorAddingDocument(true);
  };
  const handleCloseErrorAddingDocument = () => {
    setErrorAddingDocument(false);
  };

  const handleOpenRemovedDocumentSuccessfully = () => {
    setRemovedDocumentSuccessfully(true);
  };
  const handleCloseRemovedDocumentSuccessfully = () => {
    setRemovedDocumentSuccessfully(false);
  };

  const handleOpenErrorRemovingDocuments = () => {
    setErrorRemovingDocuments(true);
  };
  const handleCloseErrorRemovingDocuments = () => {
    setDocumentsAlreadyExists(false);
  };

  const handleOpenCollectionDeletedSuccessfully = () => {
    setCollectionDeletedSuccessfully(true);
  };
  const handleCloseCollectionDeletedSuccessfully = () => {
    setCollectionDeletedSuccessfully(false);
  };

  const handleOpenErrorDeletingSelectedCollection = () => {
    setErrorDeletingSelectedCollection(true);
  };
  const handleCloseErrorDeletingSelectedCollection = () => {
    setErrorDeletingSelectedCollection(false);
  };

  const locale = localStorage.getItem("locale");

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const areCollectionsPresent = () => {
    return collectionsData.length > 0;
  };

  const handleModalDisplay = () => {
    if (areCollectionsPresent()) {
      handleOpenAdddocModal();
    } else {
      handleOpenCollectionModal();
    }
  };
  const handleAddCollection = (newCollection) => {
    setCollectionsData((prevCollections) => [
      ...prevCollections,
      newCollection,
    ]);
  };

  const navigate = useNavigate();

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const handleOpenAnswerModal = () => {
    setAnswerModalVisible(true);
  };
  const handleCloseAnswerModal = () => {
    setAnswerModalVisible(false);
  };
  const handleOpenIsDialogModal = () => {
    setIsDialogModalVisible(true);
  };

  const handleCloseIsDailogModal = () => {
    setIsDialogModalVisible(false);
  };

  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };
  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };
  const handleOpenDeleteFolderModal = () => {
    setDeleteFolderModal(true);
  };
  const handleCloseDeleteFolderModal = () => {
    setDeleteFolderModal(false);
  };
  const handleOpenCollectionModal = () => {
    setCollectionModal(true);
  };
  const handleCloseCollectionModal = () => {
    setCollectionModal(false);
  };
  const handleOpenAdddocModal = () => {
    setAddDocModal(true);
  };
  const handleCloseAdddocModal = () => {
    setAddDocModal(false);
  };

  const primaryUser = localStorage.getItem("primaryUser");

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const handleSliderChange = (value) => {
    switch (value) {
      case 0:
        setTemp(1.0);
        break;
      case 20:
        setTemp(0.8);
        break;
      case 40:
        setTemp(0.6);
        break;
      case 60:
        setTemp(0.4);
        break;
      case 80:
        setTemp(0.2);
        break;
      default:
        setTemp(0.0);
    }
  };

  const data = documents.map((item) => ({
    key: item.system.uid,
    uid: item.system.uid,
    name: item.filename,
    type: item.content_type,
    url: item.url,
  }));

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  useEffect(() => {
    const fetchCompanyAuth = async () => {
      setLoading(true);
      try {
        const contentType = "companies";
        const uid = userData?.company_uid;
        const endPoint = updateContentEntriesEndpoint(contentType, uid, locale);
        const headers = EnteriesHeaders;

        const res = await axios.get(endPoint, { headers });
        setApiKey(res?.data?.entry?.api);
        setAuthToken(res?.data?.entry?.token);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching company auth details: ", error);
      }
    };
    fetchCompanyAuth();
  }, []);

  useEffect(() => {
    const getCollection = async () => {
      setLoading(true);
      try {
        const ContentType = "collections";
        const endPoint = getContentEntriesEndpoint(ContentType, locale);

        const headers = {
          api_key: apiKey,
          authorization: authToken,
        };
        const response = await axios.get(endPoint, {
          headers,
        });

        setLoading(false);
        setCollectionsData(response?.data?.entries);
      } catch (error) {
        console.log("Error fetching collections", error);
      } finally {
        setLoading(false);
      }
    };
    getCollection();
  }, [locale, apiKey, authToken]);

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const endPoint = `https://api.contentstack.io/v3/assets?include_folders=true`;

        const headers = {
          api_key: apiKey,
          authorization: authToken,
        };

        const response = await axios.get(endPoint, {
          headers,
        });
        const filteredDocs = response.data.assets.filter(
          (folder) => folder.is_dir === false
        );

        setDocumentsData(filteredDocs);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    getDocuments();
  }, [apiKey, authToken, locale]);

  useEffect(() => {
    const filterDocuments = async () => {
      setLoading(true);
      if (activeCollectionUID) {
        const contentType = "collections";
        const headers = {
          api_key: apiKey,
          authorization: authToken,
        };
        const getCollectionEndpoint = updateContentEntriesEndpoint(
          contentType,
          activeCollectionUID,
          locale
        );
        const response = await axios.get(getCollectionEndpoint, { headers });
        const docUIDs = response.data.entry.documents || [];

        const uid = docUIDs.map((docuid) => docuid.split("•")[0]);
        setExistingDocumentUIDs(uid);
        const filteredDocs = documents.filter((doc) =>
          uid.includes(doc.system.uid)
        );

        setFilteredDocuments(filteredDocs);
      } else {
        setFilteredDocuments(documents);
      }
      setLoading(false);
    };
    filterDocuments();
  }, [activeCollectionUID, documents, locale, apiKey, authToken]);

  const handleCollectionClick = (collectionName, collectionUID) => {
    if (!collectionPath.includes(collectionName)) {
      setActiveCollection(collectionName);
      setActiveCollectionUID(collectionUID);
      setCollectionPath([collectionName]);
    }
  };

  const handleBreadcrumbClick = (index) => {
    setCollectionPath(collectionPath.slice(0, index + 1));
  };

  const addDocToCollection = async (item) => {
    setLoading(true);
    try {
      const contentType = "collections";
      const headers = {
        api_key: apiKey,
        authorization: authToken,
      };
      const collectionUid = activeCollectionUID;

      const getCollectionEndpoint = updateContentEntriesEndpoint(
        contentType,
        collectionUid,
        locale
      );
      const collectionResponse = await axios.get(getCollectionEndpoint, {
        headers,
      });
      const existingDocuments = collectionResponse.data.entry.documents || [];
      const existingDocumentUIDs = existingDocuments.map(
        (doc) => doc.split("•")[0]
      );

      if (existingDocumentUIDs.includes(item.uid)) {
        // toast.warn("Document already exists in the collection");
        // toast.warn(
        //   `${literals?.toast_messages_text?.document_already_exists_in_the_collection}`
        // );
        handleOpenDocumentsAlreadyExists();

        return;
      }

      const newDoc = `${item.uid}•${item.filename}`;

      const payload = {
        entry: {
          documents: [...existingDocuments, newDoc],
        },
      };

      const endPoint = updateContentEntriesEndpoint(
        contentType,
        collectionUid,
        locale
      );
      await axios.put(endPoint, payload, { headers });
      setExistingDocumentUIDs([...existingDocumentUIDs, item.uid]);
      setLoading(false);
      // showToast(
      //   `${literals?.toast_messages_text?.document_added_successfully}`,
      //   "success"
      // );
      handleOpenDocumentAddedSuccessfully();
    } catch (error) {
      if (!activeCollectionUID) {
        // showToast(
        //   `${literals?.toast_messages_text?.please_select_any_collection}`,
        //   "error"
        // );
        handleOpenSelectAnyCollection();
      }
      // showToast(
      //   `${literals?.toast_messages_text?.error_adding_document_to_collection}`,
      //   "error"
      // );
      else handleOpenErrorAddingDocument();
    } finally {
      setLoading(false);
    }
  };

  // console.log(selectedRows);

  const addBulkDocToCollection = async (selectedRows) => {
    setLoading(true);
    try {
      const contentType = "collections";
      const headers = {
        api_key: apiKey,
        authorization: authToken,
      };
      const collectionUid = activeCollectionUID;

      const newDocs = selectedRows
        .filter((row) => row.uid && row.filename)
        .filter((row) => !existingDocumentUIDs.includes(row.uid))
        .map((row) => `${row.uid}•${row.filename}`);

      const getCollectionEndpoint = updateContentEntriesEndpoint(
        contentType,
        collectionUid,
        locale
      );
      const collectionResponse = await axios.get(getCollectionEndpoint, {
        headers,
      });
      const existingDocuments = collectionResponse.data.entry.documents || [];

      const payload = {
        entry: {
          documents: [...existingDocuments, ...newDocs],
        },
      };

      const endPoint = updateContentEntriesEndpoint(
        contentType,
        collectionUid,
        locale
      );
      await axios.put(endPoint, payload, { headers });

      const newDocumentUIDs = newDocs.map((doc) => doc.split("•")[0]);
      setExistingDocumentUIDs([...existingDocumentUIDs, ...newDocumentUIDs]);
      setDocumentsAdded(true);
      setDocumentsRemoved(false);
      setLoading(false);
      // showToast(
      //   `${literals?.toast_messages_text?.document_added_successfully}`,
      //   "success"
      // );
      handleOpenDocumentAddedSuccessfully();
    } catch (error) {
      console.error("Error adding documents to collection:", error);
      // showToast(
      //   `${literals?.toast_messages_text?.error_adding_document_to_collection}`,
      //   "error"
      // );
      handleOpenErrorAddingDocument();
    } finally {
      setLoading(false);
    }
  };

  const removeDocFromCollection = async (item) => {
    setLoading(true);
    try {
      const contentType = "collections";
      const headers = {
        api_key: apiKey,
        authorization: authToken,
      };
      const collectionUid = activeCollectionUID;

      const getCollectionEndpoint = updateContentEntriesEndpoint(
        contentType,
        collectionUid,
        locale
      );
      const collectionResponse = await axios.get(getCollectionEndpoint, {
        headers,
      });
      const existingDocuments = collectionResponse.data.entry.documents || [];

      const updatedDocuments = existingDocuments.filter(
        (doc) => !doc.startsWith(item.uid)
      );

      const data = {
        entry: {
          documents: updatedDocuments,
        },
      };

      await axios.put(getCollectionEndpoint, data, {
        headers,
      });

      setExistingDocumentUIDs(updatedDocuments.map((doc) => doc.split("•")[0]));

      const filteredDocs = filteredDocuments.filter(
        (doc) => doc.system.uid !== item.uid
      );
      setFilteredDocuments(filteredDocs);
      // if (!toastRef.current) {
      //   toast.success("Document removed successfully");
      //   toastRef.current = true;
      // }
      setLoading(false);
      // showToast(
      //   `${literals?.toast_messages_text?.removed_document_successfully}`,
      //   "success"
      // );
      handleOpenRemovedDocumentSuccessfully();
    } catch (error) {
      console.error("Error removing document from collection:", error);
      // showToast(
      //   `${literals?.toast_messages_text?.error_removing_document_from_collection}`,
      //   "error"
      // );
      handleOpenErrorRemovingDocuments();
    } finally {
      setLoading(false);
    }
  };

  const sortedCollectionsData = [...collectionsData].sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  const removeBulkDocFromCollection = async (selectedRows) => {
    setLoading(true);
    if (selectedRows.length === 1) {
      await removeBulkDocFromCollection(selectedRows[0]);
    } else if (selectedRows.length > 1) {
      try {
        const contentType = "collections";
        const headers = {
          api_key: apiKey,
          authorization: authToken,
        };
        const collectionUid = activeCollectionUID;

        const getCollectionEndpoint = updateContentEntriesEndpoint(
          contentType,
          collectionUid,
          locale
        );
        const collectionResponse = await axios.get(getCollectionEndpoint, {
          headers,
        });
        const existingDocuments = collectionResponse.data.entry.documents || [];

        const updatedDocuments = existingDocuments.filter(
          (doc) => !selectedRows.some((row) => doc.startsWith(row.uid))
        );

        const data = {
          entry: {
            documents: updatedDocuments,
          },
        };

        await axios.put(getCollectionEndpoint, data, {
          headers,
        });

        setExistingDocumentUIDs(
          updatedDocuments.map((doc) => doc.split("•")[0])
        );

        const filteredDocs = filteredDocuments.filter(
          (doc) => !selectedRows.some((row) => row.uid === doc.system.uid)
        );
        setFilteredDocuments(filteredDocs);
        setDocumentsRemoved(true);
        setDocumentsAdded(false);
        setLoading(false);
        // showToast("Removed documents successfully", "success");
        // showToast(
        //   `${literals?.toast_messages_text?.removed_document_successfully}`,
        //   "success"
        // );
        handleOpenRemovedDocumentSuccessfully();
      } catch (error) {
        console.error("Error removing documents from collection:", error);
        // showToast("Error removing documents from collection", "error");
        // showToast(
        //   `${literals?.toast_messages_text?.error_removing_document_from_collection}`,
        //   "error"
        // );
        handleOpenErrorRemovingDocuments();
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteCollection = async () => {
    setLoading(true);
    try {
      const contentType = "collections";
      const headers = {
        api_key: apiKey,
        authorization: authToken,
      };
      const collectionUID = activeCollectionUID;
      const endPoint = updateContentEntriesEndpoint(
        contentType,
        collectionUID,
        locale
      );

      await axios.delete(endPoint, { headers });
      const updatedCollections = collectionsData.filter(
        (collection) => collection.uid !== collectionUID
      );
      setCollectionsData(updatedCollections);
      setCollectionPath((prevPath) =>
        prevPath.filter((collection) => collection !== activeCollection)
      );

      setActiveCollection(null);
      setActiveCollectionUID(null);
      setDeleteFolderModal(false);
      // if (!toastRef.current) {
      //   toast.success("Collection deleted successfully!");
      //   toastRef.current = true;
      // }
      setLoading(false);
      // showToast(
      //   `${literals?.toast_messages_text?.collection_deleted_successfully}`,
      //   "success"
      // );
      handleOpenCollectionDeletedSuccessfully();
    } catch (error) {
      // showToast(
      //   `${literals?.toast_messages_text?.error_deleting_selected_collection}`,
      //   "error"
      // );
      handleOpenErrorDeletingSelectedCollection();
    } finally {
      setLoading(false);
    }
  };

  const sortedDocumentsData = documentsData.sort((a, b) => {
    return a.filename.toLowerCase().localeCompare(b.filename.toLowerCase());
  });

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {loading && <Spin fullscreen />}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        // theme="light"
        pauseOnHover={false}
      />
      <ErrorModal
        open={documentsAlreadyExists}
        onCancel={handleCloseDocumentsAlreadyExists}
        message={
          literals?.toast_messages_text
            ?.document_already_exists_in_the_collection
        }
      />
      <SuccessModal
        open={documentAddedSuccessfully}
        onCancel={handleCloseDocumentAddedSuccessfully}
        message={literals?.toast_messages_text?.document_added_successfully}
      />
      <ErrorModal
        open={selectAnyCollection}
        onCancel={handleCloseSelectAnyCollection}
        message={literals?.toast_messages_text?.please_select_any_collection}
      />
      <ErrorModal
        open={errorAddingDocument}
        onCancel={handleCloseErrorAddingDocument}
        message={
          literals?.toast_messages_text?.error_adding_document_to_collection
        }
      />
      <SuccessModal
        open={removedDocumentSuccessfully}
        onCancel={handleCloseRemovedDocumentSuccessfully}
        message={literals?.toast_messages_text?.removed_document_successfully}
      />
      <ErrorModal
        open={errorRemovingDocuments}
        onCancel={handleCloseErrorRemovingDocuments}
        message={
          literals?.toast_messages_text?.error_removing_document_from_collection
        }
      />
      <SuccessModal
        open={collectionDeletedSuccessfully}
        onCancel={handleCloseCollectionDeletedSuccessfully}
        message={literals?.toast_messages_text?.collection_deleted_successfully}
      />
      <ErrorModal
        open={errorDeletingSelectedCollection}
        onCancel={handleCloseErrorDeletingSelectedCollection}
        message={
          literals?.toast_messages_text?.error_deleting_selected_collection
        }
      />
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{ maxWidth: "12%" }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={collapsed ? 55 : 95} alt="Logo" />
        </div>

        <Menu theme="light" mode="inline" onClick={() => navigate("/home")}>
          <Menu.Item key="1" icon={<HomeIcon />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu theme="light" mode="inline">
          <Menu.Item
            key="7"
            icon={<Dialogues />}
            onClick={() => navigate("/dialogue")}
          >
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>
        {userData?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
            >
              <Menu.Item key="5" icon={<Library />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>

            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
              defaultSelectedKeys={["6"]}
            >
              <Menu.Item key="6" icon={<Collections />}>
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}

        <Menu theme="light" mode="inline">
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<Settings />}
          >
            {primaryUser === "true" && (
              <>
                <Menu.Item
                  key="1"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/company")}
                >
                  {literals?.buttons?.company_btn}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/contacts")}
                >
                  {literals?.buttons?.contacts_btn}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/subscription")}
                >
                  {literals?.sidebar?.subscription_menu}
                </Menu.Item>
              </>
            )}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            justifyContent: collapsed ? "center" : "flex-start",
          }}
        >
          <BsQuestionCircle
            className="question"
            style={{ color: "#fff", fontSize: 22 }}
          />
          {!collapsed && (
            <p style={{ fontSize: 15, fontWeight: 500, marginLeft: "1rem" }}>
              Help & Support
            </p>
          )}
        </Link>
      </Sider>
      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
          onClick={handleOpenhelpuserModal}
        />
        <Content
          style={{
            margin: "0px",
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              height: "100%",
              width: "100%",
            }}
          >
            {/* <div>
              <div
                style={{
                  padding: 30,
                  backgroundColor: "#FFFFFF",
                  height: 550,
                  overflowY: "scroll",
                }}
              >
                <h5 style={{ marginBottom: 20, fontSize: 18, fontWeight: 500 }}>
                  {literals?.sidebar?.collections_menu}
                </h5>
                {sortedCollectionsData &&
                  sortedCollectionsData.map((collection, index) => (
                    <div key={index}>
                      <p
                        style={{ cursor: "pointer", marginBottom: 25 }}
                        onClick={() =>
                          handleCollectionClick(
                            collection.title,
                            collection.uid
                          )
                        }
                      >
                        <MdCollectionsBookmark
                          style={{
                            color:
                              activeCollection === collection.title
                                ? "#3ac1cb"
                                : "#000000",
                            fontSize: 22,
                            marginRight: 5,
                          }}
                        />
                        {collection.title}
                      </p>
                    </div>
                  ))}
              </div>
            </div> */}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginLeft: "2%",
                marginRight: "2%",
                marginTop: "3%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Breadcrumb>
                  {collectionPath &&
                    collectionPath.map((collection, index) => (
                      <Breadcrumb.Item
                        key={index}
                        onClick={() => handleBreadcrumbClick(index)}
                      >
                        {index === 0 && (
                          <span>
                            {/* <MdCollectionsBookmark
                              style={{
                                color: "#3ac1cb",
                                fontSize: 22,
                                marginRight: 5,
                              }}
                            /> */}
                            {collection}
                          </span>
                        )}
                        {index !== 0 && <span>{collection}</span>}
                      </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div
                  className="d-flex align-items-center"
                  style={{
                    gap: 10,
                    justifyContent: "center",
                  }}
                >
                  {activeCollectionUID && selectedRows.length > 0 && (
                    <>
                      <Button
                        style={{
                          borderStyle: "solid",
                          borderWidth: "2px",
                          borderColor: "#3AC1CB",
                          color: "#3AC1CB",
                          borderRadius: "8px",
                          paddingTop: 3,
                          paddingBottom: 5,
                          paddingLeft: 15,
                          paddingRight: 15,
                          cursor: `${
                            !isDocumentsAdded ? "pointer" : "not-allowed"
                          }`,
                          opacity: `${!isDocumentsAdded ? "1" : "0.5"}`,
                        }}
                        onClick={() => addBulkDocToCollection(selectedRows)}
                        disabled={isDocumentsAdded}
                      >
                        {literals?.buttons?.add_btn}
                      </Button>
                      <Button
                        style={{
                          borderStyle: "solid",
                          borderWidth: "2px",
                          borderColor: "#3AC1CB",
                          color: "#3AC1CB",
                          borderRadius: "8px",
                          paddingTop: 3,
                          paddingBottom: 5,
                          paddingLeft: 15,
                          paddingRight: 15,
                          cursor: `${
                            !isDocumentsRemoved ? "pointer" : "not-allowed"
                          }`,
                          opacity: `${!isDocumentsRemoved ? "1" : "0.5"}`,
                        }}
                        onClick={() =>
                          removeBulkDocFromCollection(selectedRows)
                        }
                        disabled={isDocumentsRemoved}
                      >
                        {literals?.buttons?.remove_btn}
                      </Button>
                      {/* <div>{selectedRowsDisplay}</div> */}
                    </>
                  )}
                  {activeCollectionUID && (
                    <Link
                      style={{
                        color: "#3a3a5d",
                        marginLeft: "3%",
                        borderRadius: "200px",
                        backgroundColor: "#D500051A",
                        padding: 10,
                      }}
                    >
                      <Icon
                        icon="mingcute:delete-fill"
                        style={{
                          color: "#D50005",
                          fontSize: 20,
                        }}
                        onClick={handleOpenDeleteFolderModal}
                      />
                    </Link>
                  )}
                  {/* <Button
                    htmlType="submit"
                    className="login-form-button"
                    style={{
                      display: "flex",
                      backgroundColor: "#3AC1CB",
                      color: "#FFFFFF",
                      borderStyle: "none",
                      textTransform: "uppercase",
                      marginRight: "5%",
                      marginLeft: "3%",
                    }}
                    onClick={() => handleOpenCollectionModal()}
                  >
                    <Logintext>
                      <FaPlus
                        style={{
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      />
                      {areCollectionsPresent()
                        ? "Add Document"
                        : "Create Collection"}
                      {literals?.buttons?.create_collection_btn}
                    </Logintext>
                  </Button> */}
                </div>
              </div>
              {data ? (
                <div>
                  <Table
                    rowSelection={{
                      type: "checkbox",
                      selectedRowKeys,
                      onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                        setSelectedRows(selectedRows);
                        // console.log(
                        //   "Selected Rows:",
                        //   selectedRows.map((row) => ({
                        //     uid: row.uid,
                        //     name: row.name,
                        //   }))
                        // );
                      },
                      getCheckboxProps: (record) => ({
                        disabled: record.filename === "Disabled User",
                        name: record.filename,
                      }),
                    }}
                    columns={[
                      {
                        title: `${literals?.library_page?.document_name_table}`,
                        dataIndex: "filename",
                        render: (text, record) => (
                          <a
                            href={record.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            {text}
                          </a>
                        ),
                      },
                      {
                        title: `${literals?.library_page?.type_name}`,
                        dataIndex: "content_type",
                        render: (text) => (
                          <p style={{ textTransform: "uppercase" }}>
                            {text.split("/")[1]}
                          </p>
                        ),
                      },
                      // {
                      //   title: `${literals?.library_page?.actions_name}`,
                      //   dataIndex: "action",
                      //   render: (_, record) => (
                      //     <div>
                      //       {!selectedRowKeys.includes(record.key) && (
                      //         <>
                      //           <Button
                      //             style={{
                      //               borderStyle: "solid",
                      //               borderWidth: "2px",
                      //               borderColor: "#3AC1CB",
                      //               color: "#3AC1CB",
                      //               borderRadius: "8px",
                      //               paddingTop: 3,
                      //               paddingBottom: 5,
                      //               paddingLeft: 15,
                      //               paddingRight: 15,
                      //               marginRight: "10px",
                      //               cursor: "pointer",
                      //               ...(existingDocumentUIDs.includes(
                      //                 record.uid
                      //               )
                      //                 ? { opacity: 0.5, cursor: "not-allowed" }
                      //                 : {}),
                      //             }}
                      //             onClick={() => addDocToCollection(record)}
                      //             disabled={existingDocumentUIDs.includes(
                      //               record.uid
                      //             )}
                      //           >
                      //             {literals?.buttons?.add_btn}
                      //           </Button>
                      //           <Button
                      //             style={{
                      //               borderStyle: "solid",
                      //               borderWidth: "2px",
                      //               borderColor: "#3AC1CB",
                      //               color: "#3AC1CB",
                      //               borderRadius: "8px",
                      //               paddingTop: 3,
                      //               paddingBottom: 5,
                      //               paddingLeft: 15,
                      //               paddingRight: 15,
                      //               cursor: "pointer",
                      //               ...(!existingDocumentUIDs.includes(
                      //                 record.uid
                      //               )
                      //                 ? { opacity: 0.5, cursor: "not-allowed" }
                      //                 : {}),
                      //             }}
                      //             onClick={() =>
                      //               removeDocFromCollection(record)
                      //             }
                      //             disabled={
                      //               !existingDocumentUIDs.includes(record.uid)
                      //             }
                      //           >
                      //             {literals?.buttons?.remove_btn}
                      //           </Button>
                      //         </>
                      //       )}
                      //     </div>
                      //   ),
                      // },
                      {
                        title: `${literals?.library_page?.actions_name}`,
                        dataIndex: "action",
                        render: (_, record) => {
                          const menu = (
                            <Menu>
                              <Menu.Item key="add">
                                <Button
                                  style={{
                                    color: "#1B1B1B",
                                    borderRadius: "8px",
                                    paddingTop: 3,
                                    paddingBottom: 5,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    ...(existingDocumentUIDs.includes(
                                      record.uid
                                    )
                                      ? { opacity: 0.5, cursor: "not-allowed" }
                                      : {}),
                                  }}
                                  onClick={() => addDocToCollection(record)}
                                  disabled={existingDocumentUIDs.includes(
                                    record.uid
                                  )}
                                >
                                  {literals?.buttons?.add_btn}
                                </Button>
                              </Menu.Item>
                              <Menu.Item key="remove">
                                <Button
                                  style={{
                                    // borderColor: "#3AC1CB",
                                    color: "#1B1B1B",
                                    borderRadius: "8px",
                                    paddingTop: 3,
                                    paddingBottom: 5,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    cursor: "pointer",
                                    ...(!existingDocumentUIDs.includes(
                                      record.uid
                                    )
                                      ? { opacity: 0.5, cursor: "not-allowed" }
                                      : {}),
                                  }}
                                  onClick={() =>
                                    removeDocFromCollection(record)
                                  }
                                  disabled={
                                    !existingDocumentUIDs.includes(record.uid)
                                  }
                                >
                                  {literals?.buttons?.remove_btn}
                                </Button>
                              </Menu.Item>
                            </Menu>
                          );

                          return (
                            <div>
                              {!selectedRowKeys.includes(record.key) && (
                                <Dropdown overlay={menu} trigger={["click"]}>
                                  <EllipsisOutlined
                                    style={{
                                      fontSize: "24px",
                                      cursor: "pointer",
                                      color: "#000",
                                    }}
                                  />
                                </Dropdown>
                              )}
                            </div>
                          );
                        },
                      },
                    ]}
                    dataSource={sortedDocumentsData}
                    pagination={{
                      pageSize: 12,
                      showSizeChanger: false,
                      itemRender: (current, type, originalElement) => {
                        if (type === "prev") {
                          return (
                            <a
                              style={{
                                backgroundColor: "#CDD4E3",
                                padding: 8,
                                borderRadius: 5,
                              }}
                            >
                              <ArrowLeftOutlined style={{ color: "white" }} />
                            </a>
                          );
                        }
                        if (type === "next") {
                          return (
                            <a
                              style={{
                                backgroundColor: "#CDD4E3",
                                padding: 8,
                                borderRadius: 5,
                              }}
                            >
                              <ArrowRightOutlined style={{ color: "white" }} />
                            </a>
                          );
                        }
                        return originalElement;
                      },
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    height: "100%",
                  }}
                >
                  <h4>{literals?.placeholder_text?.no_data_found}</h4>
                </div>
              )}
            </div>

            <div
              style={{
                backgroundColor: "white",
                width: "30%",
                height: "100%",
                padding: 20,
                alignSelf: "start",
                position: "relative",
              }}
            >
              <h5 style={{ marginBottom: 20, fontSize: 18, fontWeight: 500 }}>
                {literals?.sidebar?.collections_menu}
              </h5>
              {sortedCollectionsData &&
                sortedCollectionsData.map((collection, index) => (
                  <div key={index}>
                    <p
                      style={{
                        cursor: "pointer",
                        marginBottom: 25,
                        backgroundColor:
                          activeCollection === collection.title
                            ? "#F3F5F8"
                            : "",
                        borderRadius: "200px",
                        padding: 10,
                      }}
                      onClick={() =>
                        handleCollectionClick(collection.title, collection.uid)
                      }
                    >
                      <MdCollectionsBookmark
                        style={{
                          // color:
                          //   activeCollection === collection.title
                          //     ? "#3ac1cb"
                          //     : "#000000",
                          color: "#06152B",
                          fontSize: 22,
                          marginRight: 5,
                        }}
                      />
                      {collection.title}
                    </p>
                  </div>
                ))}
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{
                  display: "flex",
                  background:
                    "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                  color: "#FFFFFF",
                  borderStyle: "none",
                  textTransform: "uppercase",
                  borderRadius: "200px",
                  position: "fixed",
                  bottom: "3%",
                  right: "3.5%",
                  height: "6%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => handleOpenCollectionModal()}
              >
                <Logintext>
                  <FaPlus
                    style={{
                      marginRight: 8,
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  />
                  {literals?.buttons?.create_collection_btn}
                </Logintext>
              </Button>
            </div>

            {loading && <Spin fullscreen />}
          </div>
        </Content>
      </Layout>
      <AnswerModal
        visible={answerModalVisible}
        onClose={handleCloseAnswerModal}
        marks={marks}
        handleSliderChange={handleSliderChange}
        temp={temp}
      />
      <InstructionModal
        visible={helpModalVisible}
        onClose={handleClosehelpuserModal}
      />
      <CreateCollection
        visible={collectionModal}
        onClose={handleCloseCollectionModal}
        onAddCollection={handleAddCollection}
        literals={literals}
        apiKey={apiKey}
        authToken={authToken}
      />
      <DeleteCollection
        visible={deletefolderModal}
        onClose={handleCloseDeleteFolderModal}
        deleteCollection={deleteCollection}
        selectedCollectionName={activeCollection}
        literals={literals}
      />
    </Layout>
  );
}
