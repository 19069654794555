import { Button, Layout, Menu } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import Link from "antd/es/typography/Link";
import React, { useEffect, useRef, useState } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import MainHeader from "../components/MainHeader";
import { useLiterals } from "../context/LiteralsContext";
import image from "../assests/image.png";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../assests/home-active.svg";
import { ReactComponent as Dialogues } from "../assests/dialogues.svg";
import { ReactComponent as Library } from "../assests/library.svg";
import { ReactComponent as Collections } from "../assests/collections.svg";
import { ReactComponent as Settings } from "../assests/settings.svg";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
  updateContentEntriesEndpoint,
} from "../context/constants";
import axios from "axios";
import { PiTreeStructure } from "react-icons/pi";
import InstructionModal from "../components/InstructionModal";
import UpdateCompanyModal from "../components/UpdateCompanyModal";

const { Sider, Content } = Layout;

export default function Company() {
  const navigate = useNavigate();
  const literals = useLiterals();
  const [collapsed, setCollapsed] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [clickedCardIndex, setClickedCardIndex] = useState(null);
  const [articleData, setArticleData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companyDetailsUpdated, setCompanyDetailsUpdated] = useState(false);
  const [failedToUpdateCompanyDetails, setFailedToUpdateCompanyDetails] =
    useState(false);
  const [locale, setLocale] = useState(
    () => localStorage.getItem("locale") || "en-us"
  );

  const primaryUser = localStorage.getItem("primaryUser");

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  useEffect(() => {
    const handleLocaleChange = () => {
      const storedLocale = localStorage.getItem("locale");
      setLocale(storedLocale || "en-us");
    };

    window.addEventListener("storage", handleLocaleChange);

    return () => {
      window.removeEventListener("storage", handleLocaleChange);
    };
  }, [locale]);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const fcContentType = "companies";
        const fcUid = userData?.company_uid;
        const endPoint = updateContentEntriesEndpoint(
          fcContentType,
          fcUid,
          locale
        );

        const headers = EnteriesHeaders;
        const response = await axios.get(endPoint, {
          headers,
        });
        const companies = response.data.entry;
        setApiKey(companies?.api);
        setAuthToken(companies?.token);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    fetchCompany();
  }, []);

  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };

  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };

  const modalContentRef = useRef(null);

  const updateArticleViews = async (articleId) => {
    try {
      const contentType = "article_control";
      const endpoint = getContentEntriesEndpoint(contentType, locale);
      const headers = EnteriesHeaders;

      const res = await axios.get(endpoint, { headers });
      const existingArticle = res?.data?.entry;

      if (existingArticle && existingArticle.title === articleId) {
        const updatedViews = (existingArticle.number_of_views || 0) + 1;

        const payload = {
          entry: {
            number_of_views: updatedViews,
            indexed: true,
          },
        };

        await axios.put(endpoint, payload, { headers });

        console.log(
          `Number of views updated for article ${articleId}: ${updatedViews}`
        );
      } else {
        console.log(
          `Article with id ${articleId} not found. Creating new entry...`
        );

        const payload = {
          entry: {
            title: articleId,
            number_of_views: 1,
            indexed: true,
          },
        };

        await axios.post(endpoint, payload, { headers });

        console.log(`New entry created for article ${articleId}`);
      }
    } catch (error) {
      console.error("Error updating article views:", error);
    }
  };

  const handleOpenModal = (index) => {
    const clickedArticle = articleData[index];
    if (clickedArticle) {
      updateArticleViews(clickedArticle.uid);
    }
    setModalVisible(true);
    setClickedCardIndex(index);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleOpenCompanyDetailsUpdated = () => {
    setCompanyDetailsUpdated(true);
  };
  const handleCloseCompanyDetailsUpdated = () => {
    setCompanyDetailsUpdated(false);
  };

  const handleOpenFailedToUpdateCompanyDetails = () => {
    setFailedToUpdateCompanyDetails(true);
  };
  const handleCloseFailedToUpdateCompanyDetails = () => {
    setFailedToUpdateCompanyDetails(false);
  };

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const fcContentType = "companies";
        const fcUid = userData?.company_uid;
        const endPoint = updateContentEntriesEndpoint(
          fcContentType,
          fcUid,
          locale
        );
        const headers = EnteriesHeaders;
        const response = await axios.get(endPoint, {
          headers,
        });

        const companyInfo = response.data.entry;
        setCompanyData(companyInfo);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    fetchCompany();
  }, [locale, userData, companyData]);

  const updateCompanyEntries = async (values) => {
    const ucContentType = "companies";
    const ucuid = companyData?.uid;
    const endpoint = updateContentEntriesEndpoint(ucContentType, ucuid, locale);
    const headers = EnteriesHeaders;
    const payload = {
      entry: {
        title: values.title,
        email: values.email,
        street: values.street,
        city: values.city,
        state: values.state,
        zip: values.zip,
        country: values.country,
      },
    };
    const ucauContentType = "application_user";
    const ucauuid = userData?.uid;
    const userEndpoint = updateContentEntriesEndpoint(
      ucauContentType,
      ucauuid,
      locale
    );
    const userPayload = {
      entry: {
        company_name: values.title,
      },
    };
    try {
      await axios.put(endpoint, payload, { headers });
      await axios.put(userEndpoint, userPayload, { headers });
      handleOpenCompanyDetailsUpdated();
    } catch (error) {
      handleOpenFailedToUpdateCompanyDetails();
    }
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{
          maxWidth: "12%",
        }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={collapsed ? 55 : 95} alt="Logo" />
        </div>
        <Menu theme="light" mode="inline" onClick={() => navigate("/home")}>
          <Menu.Item key="1" icon={<HomeIcon />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu theme="light" mode="inline" onClick={() => navigate("/dialogue")}>
          <Menu.Item key="7" icon={<Dialogues />}>
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>
        {userData?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
            >
              <Menu.Item key="5" icon={<Library />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>

            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
            >
              <Menu.Item key="6" icon={<Collections />}>
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}
        <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<Settings />}
          >
            {/* <Menu.Item
                key="2"
                icon={<GiSettingsKnobs />}
                onClick={handleOpenAnswerModal}
              >
                {literals?.sidebar?.answers_menu}
              </Menu.Item> */}
            {primaryUser === "true" && (
              <>
                <Menu.Item
                  key="1"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/company")}
                >
                  {literals?.buttons?.company_btn}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/contacts")}
                >
                  {literals?.buttons?.contacts_btn}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/subscription")}
                >
                  {literals?.sidebar?.subscription_menu}
                </Menu.Item>
              </>
            )}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            justifyContent: collapsed ? "center" : "flex-start",
          }}
        >
          <BsQuestionCircle
            className="question"
            style={{ color: "#fff", fontSize: 22 }}
          />
          {!collapsed && (
            <p style={{ fontSize: 15, fontWeight: 500, marginLeft: "1rem" }}>
              Help & Support
            </p>
          )}
        </Link>
      </Sider>
      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
          visible={modalVisible}
          onClose={handleCloseModal}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          clickedCardIndex={clickedCardIndex}
          handleOpenModal={handleOpenModal}
        />
        <Content>
          <div
            style={{
              gap: 10,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#F3F5F8",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "95%",
                marginTop: 20,
                marginLeft: 40,
                marginRight: 40,
              }}
            >
              <p style={{ fontSize: 20, fontWeight: 700, color: "#06152B" }}>
                Company Details
              </p>
              <Button
                style={{
                  background:
                    "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                  color: "#FFFFFF",
                  textTransform: "uppercase",
                  fontSize: 12,
                  fontWeight: 500,
                  height: 40,
                  width: 80,
                  borderRadius: 200,
                }}
                onClick={() => handleOpenModal()}
              >
                {literals?.buttons?.edit_btn}
              </Button>
            </div>

            {/* company details */}
            <div
              style={{
                backgroundColor: "#fff",
                width: "95%",
                height: "100%",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                gap: 15,
                marginTop: 10,
                marginLeft: 40,
                marginRight: 40,
                padding: 20,
              }}
            >
              {/* company name & email domain */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  gap: 30,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 500 }}>
                    {literals?.placeholder_text?.company_name_text}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      backgroundColor: "#F3F5F8",
                      borderRadius: 12,
                      paddingTop: 13,
                      paddingLeft: 20,
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#1B1B1B",
                        }}
                      >
                        {companyData?.title}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 500 }}>
                    {literals?.placeholder_text?.email_domain}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      backgroundColor: "#F3F5F8",
                      borderRadius: 12,
                      paddingTop: 13,
                      paddingLeft: 20,
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#1B1B1B",
                        }}
                      >
                        {companyData?.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* address & city */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  gap: 30,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 500 }}>
                    {literals?.placeholder_text?.address_text}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      backgroundColor: "#F3F5F8",
                      borderRadius: 12,
                      paddingTop: 13,
                      paddingLeft: 20,
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#1B1B1B",
                        }}
                      >
                        {companyData?.street || "-"}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 500 }}>
                    {literals?.placeholder_text?.city_text}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      backgroundColor: "#F3F5F8",
                      borderRadius: 12,
                      paddingTop: 13,
                      paddingLeft: 20,
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#1B1B1B",
                        }}
                      >
                        {companyData?.city}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* state & zip */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  gap: 30,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 500 }}>
                    {literals?.placeholder_text?.state_text}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      backgroundColor: "#F3F5F8",
                      borderRadius: 12,
                      paddingTop: 13,
                      paddingLeft: 20,
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#1B1B1B",
                        }}
                      >
                        {companyData?.state}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 500 }}>
                    {literals?.placeholder_text?.zip_text}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      backgroundColor: "#F3F5F8",
                      borderRadius: 12,
                      paddingTop: 13,
                      paddingLeft: 20,
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#1B1B1B",
                        }}
                      >
                        {companyData?.zip}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* country */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  gap: 30,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: 500 }}>
                    {literals?.placeholder_text?.country_text}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "49%",
                      backgroundColor: "#F3F5F8",
                      borderRadius: 12,
                      paddingTop: 13,
                      paddingLeft: 20,
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "#1B1B1B",
                        }}
                      >
                        {companyData?.country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
      <UpdateCompanyModal
        visible={modalVisible}
        onClose={handleCloseModal}
        updateCompanyEntries={updateCompanyEntries}
        companyData={companyData}
        literals={literals}
      />
      <InstructionModal
        visible={helpModalVisible}
        onClose={handleClosehelpuserModal}
        modalContentRef={modalContentRef}
      />
    </Layout>
  );
}
