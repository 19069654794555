import { Button, Dropdown, Layout, Menu, Spin, theme } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import debounce from "lodash/debounce";
import _ from "lodash";
import Sider from "antd/es/layout/Sider";
import SubMenu from "antd/es/menu/SubMenu";
import MainHeader from "../components/MainHeader";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import image from "../assests/image.png";
import { useLiterals } from "../context/LiteralsContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { PiTreeStructure } from "react-icons/pi";
import { IoSend } from "react-icons/io5";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
  searchQuery,
  updateContentEntriesEndpoint,
} from "../context/constants";
import axios from "axios";
import jsPDF from "jspdf";
import dialoguePattern from "../assests/dialogue_left_pattern.png";
import { SearchInput } from "../styles/Styles";
import { DownOutlined } from "@ant-design/icons";
import AnswerModal from "../components/AnswerModal";
import { BsQuestionCircle } from "react-icons/bs";
import InstructionModal from "../components/InstructionModal";
import { ReactComponent as HomeIcon } from "../assests/home.svg";
import { ReactComponent as Dialogues } from "../assests/dialogues-active.svg";
import { ReactComponent as Library } from "../assests/library.svg";
import { ReactComponent as Collections } from "../assests/collections.svg";
import { ReactComponent as Settings } from "../assests/settings.svg";
import ErrorModal from "../components/ErrorModal";
import Link from "antd/es/typography/Link";

const marks = {
  0: "0%",
  20: "20%",
  40: "40%",
  60: "60%",
  80: "80%",
  100: "100%",
};

export default function Dialogue() {
  const navigate = useNavigate();
  const literals = useLiterals();

  const [toastMessage, setToastMessage] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [historyData, setHistoryData] = useState(null);
  const [data, setData] = useState([]);
  const [qnaTitle, setQnaTitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  const locale = localStorage.getItem("locale");
  const userDataString = localStorage.getItem("userData");
  const userInfo = JSON.parse(userDataString);
  const primaryUser = localStorage.getItem("primaryUser");
  const [searchInput, setSearchInput] = useState("");
  const [selectedCol, setSelectedCol] = useState("Chem.Aid");
  const [collectionsData, setCollectionsData] = useState([]);
  const [apiKey, setApiKey] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [showDropdown, setShowDropdown] = useState(null);
  const [settingsUpdated, setSettingsUpdated] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [copy, setCopy] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [temp, setTemp] = useState(() => {
    const storedTemp = localStorage.getItem("temp");
    return storedTemp !== null ? parseFloat(storedTemp) : 0.5;
    // const storedTemp = localStorage.getItem("userData");
    // const userData = JSON.parse(storedTemp);
    // return userData && userData.temp ? parseFloat(userData.temp) : 0.5;
  });

  const [noDataToGeneratePdf, setNoDataToGeneratePdf] = useState(false);
  const [
    errorUpdatingCreativePrecisionValue,
    setErrorUpdatingCreativePrecisionValue,
  ] = useState(false);

  const handleOpenNoDataToGeneratePdf = () => {
    setNoDataToGeneratePdf(true);
  };
  const handleCloseNoDataToGeneratePdf = () => {
    setNoDataToGeneratePdf(false);
  };

  const handleOpenErrorUpdatingCreativePrecisionValue = () => {
    setErrorUpdatingCreativePrecisionValue(true);
  };
  const handleCloseErrorUpdatingCreativePrecisionValue = () => {
    setErrorUpdatingCreativePrecisionValue(false);
  };

  const handleOpenAnswerModal = () => {
    setAnswerModalVisible(true);
  };

  const handleCloseAnswerModal = () => {
    setAnswerModalVisible(false);
  };

  const toggleDropdown = (index) => {
    if (showDropdown === index) {
      setShowDropdown(null);
    } else {
      setShowDropdown(index);
    }
  };

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const parseResponseData = (text) => {
    return text?.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  };

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const fcContentType = "companies";
        const fcUid = userInfo?.company_uid;
        const endPoint = updateContentEntriesEndpoint(
          fcContentType,
          fcUid,
          locale
        );

        const headers = EnteriesHeaders;
        const response = await axios.get(endPoint, {
          headers,
        });
        const companies = response.data.entry;
        setApiKey(companies?.api);
        setAuthToken(companies?.token);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    fetchCompany();
  }, []);

  useEffect(() => {
    const fetchDialogues = async () => {
      if (!selectedDialogue) return;
      setLoading(true);
      try {
        const contentType = "dialogue";
        const headers = EnteriesHeaders;
        const endpoint = getContentEntriesEndpoint(contentType, locale);

        const res = await axios.get(endpoint, { headers });
        const filteredRes = res.data.entries.find(
          (item) => item.title === selectedDialogue
        );

        if (filteredRes) {
          const dialogueTitles = filteredRes.dialogue_array.split("•");
          setQnaTitle(dialogueTitles);
        } else {
          setQnaTitle([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDialogues();
  }, [selectedDialogue, locale]);

  useEffect(() => {
    const fetchQnA = async () => {
      if (!qnaTitle || qnaTitle.length === 0) return;
      setLoading(true);
      try {
        const contentType = "qanda";
        const headers = EnteriesHeaders;
        const endpoint = getContentEntriesEndpoint(contentType, locale);

        const qnaEntries = [];
        for (const title of qnaTitle) {
          const res = await axios.get(endpoint, { headers });
          const filteredQnA = res.data.entries.filter(
            (item) => item.title === title
          );
          qnaEntries.push(...filteredQnA);
        }
        setData(qnaEntries);
      } catch (error) {
        console.log("Error fetching selected dialogue");
      } finally {
        setLoading(false);
      }
    };

    fetchQnA();
  }, [qnaTitle, locale]);

  useEffect(() => {
    const fetchUserHistory = async () => {
      try {
        const contentType = "dialogue";
        const endpoint = getContentEntriesEndpoint(contentType, locale);
        const headers = EnteriesHeaders;

        const res = await axios.get(endpoint, { headers });
        const history = res?.data?.entries;
        const userHistory = history.filter(
          (item) => item.application_user === userInfo.title
        );

        setHistoryData(userHistory);
      } catch (error) {
        // showToast("Error fetching user dialogue history!", "error");
        console.log("Error fetching user dialogue history!", error);
      }
    };
    if (userInfo) {
      fetchUserHistory();
    }
  }, [literals]);

  const categorizeDataByDate = () => {
    const today = new Date();
    const todayStart = new Date(today.setHours(0, 0, 0, 0));
    const previous7Days = new Date(
      todayStart.getTime() - 7 * 24 * 60 * 60 * 1000
    );
    const previous30Days = new Date(
      todayStart.getTime() - 30 * 24 * 60 * 60 * 1000
    );

    const todayData = [];
    const last7DaysData = [];
    const last30DaysData = [];
    const olderData = [];

    historyData?.map((item) => {
      const itemDate = new Date(item.updated_at);

      if (itemDate >= todayStart) {
        todayData.push(item);
      } else if (itemDate >= previous7Days) {
        last7DaysData.push(item);
      } else if (itemDate >= previous30Days) {
        last30DaysData.push(item);
      } else {
        olderData.push(item);
      }
    });

    return { todayData, last7DaysData, last30DaysData, olderData };
  };

  const { todayData, last7DaysData, last30DaysData, olderData } =
    categorizeDataByDate();

  const handleSelectChange = (value) => {
    setSelectedDialogue(value);
  };

  const handleDownloadPDF = () => {
    const pdf = new jsPDF();
    const currentDate = new Date();
    const filename = `${selectedDialogue}_${currentDate.toLocaleString()}.pdf`;

    if (data.length > 0) {
      let titleAdded = false;
      let currentY = 20;
      let pageIndex = 0;
      const pageHeight = pdf.internal.pageSize.getHeight();

      data.forEach((item, index) => {
        const questionText = `${index + 1}: ${item.question}`;
        const answerText = item.answer;

        const questionLines = pdf.splitTextToSize(
          questionText,
          pdf.internal.pageSize.getWidth() - 20
        );
        const answerLines = pdf.splitTextToSize(
          answerText,
          pdf.internal.pageSize.getWidth() - 20
        );

        const totalLines =
          Math.max(questionLines.length, answerLines.length) + 1;

        if (currentY + totalLines * 10 > pageHeight) {
          pdf.addPage();
          pageIndex++;
          currentY = 10;
          titleAdded = false;
        }

        if (!titleAdded && pageIndex === 0) {
          const dialogueTitle = historyData.find(
            (item) => item.title === selectedDialogue
          )?.title;
          pdf.text(dialogueTitle, 10, 10);
          titleAdded = true;
        }

        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text(questionLines, 10, currentY);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.text(answerLines, 10, currentY + 5 + questionLines.length * 10);

        currentY += totalLines * 10 + 10;
      });

      pdf.save(filename);
    } else {
      // showToast("No data to generate PDF!", "error");
      // showToast(
      //   `${literals?.toast_messages_text?.no_data_to_generate_pdf}`,
      //   "error"
      // );
      handleOpenNoDataToGeneratePdf();
    }
  };

  // console.log("historyData", historyData[0].updated_at);
  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    if (input.length <= 8192) {
      setSearchInput(input);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const params = {
        question: searchInput,
        temperature: temp,
      };
      if (searchInput.trim() !== "") {
        const response = await axios.post(searchQuery, params);
        setResponseData(response.data);
        setLoading(false);
        setLiked(false);
        setDisliked(false);
        setCopy(false);
      } else {
        setResponseData(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getCollections = async () => {
      try {
        const contentType = "collections";
        const headers = {
          api_key: apiKey,
          authorization: authToken,
        };
        const endPoint = getContentEntriesEndpoint(contentType, locale);

        const res = await axios.get(endPoint, { headers });
        setCollectionsData(res?.data?.entries);
      } catch (error) {
        console.log("Error fetching collections");
      }
    };
    getCollections();
  }, [locale, apiKey, authToken]);

  const handleMenuClick = (e) => {
    setSelectedCol(e.item.props.value);
  };
  const collections = (
    <Menu onClick={handleMenuClick} style={{ minWidth: "140px" }}>
      <Menu.Item value="Chem.Aid">Chem.Aid</Menu.Item>
      <Menu.Item value="Company (all) collections">
        Company (all) collections
      </Menu.Item>
      {/* {collectionsData.map((item, index) => (
        <Option value={item.title} key={index}>
          {item.title.length > 18
            ? `${item.title.slice(0, 18)}...`
            : item.title}
        </Option>
      ))} */}
      {collectionsData
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((item, index) => (
          <Menu.Item value={item.title} key={index}>
            {item.title.length > 18
              ? `${item.title.slice(0, 18)}...`
              : item.title}
          </Menu.Item>
        ))}
    </Menu>
  );

  const setUserPrefSettings = async (temp) => {
    try {
      const contentType = "application_user";
      const contentUid = userInfo?.uid;
      if (!contentUid) {
        console.error("User data is missing or invalid.");
        return;
      }

      const endpoint = updateContentEntriesEndpoint(
        contentType,
        contentUid,
        locale
      );
      const headers = EnteriesHeaders;

      const payload = {
        entry: {
          preferences_and_settings: {
            creativity_precision: temp,
          },
        },
      };
      if (
        !settingsUpdated &&
        parseFloat(temp) !==
          userInfo?.preferences_and_settings?.creativity_precision
      ) {
        const res = await axios.put(endpoint, payload, { headers });

        // console.log(res.data);

        const updatedTemp =
          res.data.entry.preferences_and_settings?.creativity_precision;
        if (updatedTemp !== undefined && updatedTemp !== null) {
          setTemp(updatedTemp);
          localStorage.setItem("temp", updatedTemp);
        }
        setSettingsUpdated(true);
      } else {
        console.log("creativity_precision is already up to date or unchanged");
      }
    } catch (error) {
      handleOpenErrorUpdatingCreativePrecisionValue();
    }
  };

  const handleSliderChange = (value) => {
    switch (value) {
      case 0:
        setTemp(() => {
          localStorage.setItem("temp", "1.0");
          setUserPrefSettings(1.0);
          return 1.0;
        });
        break;
      case 20:
        setTemp(() => {
          localStorage.setItem("temp", "0.8");
          setUserPrefSettings(0.8);
          return 0.8;
        });
        break;
      case 40:
        setTemp(() => {
          localStorage.setItem("temp", "0.6");
          setUserPrefSettings(0.6);
          return 0.6;
        });
        break;
      case 60:
        setTemp(() => {
          localStorage.setItem("temp", "0.4");
          setUserPrefSettings(0.4);
          return 0.4;
        });
        break;
      case 80:
        setTemp(() => {
          localStorage.setItem("temp", "0.2");
          setUserPrefSettings(0.2);
          return 0.2;
        });
        break;
      default:
        setTemp(() => {
          localStorage.setItem("temp", "0.0");
          setUserPrefSettings(0.0);
          return 0.0;
        });
    }
  };

  const modalContentRef = useRef(null);

  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };

  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };

  console.log("selectedDialogue", selectedDialogue);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {loading && <Spin fullscreen />}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
      <ErrorModal
        open={noDataToGeneratePdf}
        onCancel={handleCloseNoDataToGeneratePdf}
        message={literals?.toast_messages_text?.no_data_to_generate_pdf}
      />
      <ErrorModal
        open={errorUpdatingCreativePrecisionValue}
        onCancel={handleCloseErrorUpdatingCreativePrecisionValue}
        message={
          literals?.toast_messages_text
            ?.error_updating_creative_and_precision_value
        }
      />
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{ maxWidth: "12%" }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={collapsed ? 55 : 95} alt="Logo" />
        </div>
        <Menu theme="light" mode="inline" onClick={() => navigate("/home")}>
          <Menu.Item key="1" icon={<HomeIcon />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu
          theme="light"
          mode="inline"
          onClick={() => navigate("/dialogue")}
          defaultSelectedKeys={["7"]}
        >
          <Menu.Item key="7" icon={<Dialogues />}>
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>
        {userInfo?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
            >
              <Menu.Item key="5" icon={<Library />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
            >
              <Menu.Item key="6" icon={<Collections />}>
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}
        <Menu theme="light" mode="inline">
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<Settings />}
          >
            {primaryUser === "true" && (
              <>
                <Menu.Item
                  key="1"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/company")}
                >
                  {literals?.buttons?.company_btn}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/contacts")}
                >
                  {literals?.buttons?.contacts_btn}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/subscription")}
                >
                  {literals?.sidebar?.subscription_menu}
                </Menu.Item>
              </>
            )}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            justifyContent: collapsed ? "center" : "flex-start",
          }}
        >
          <BsQuestionCircle
            className="question"
            style={{ color: "#fff", fontSize: 22 }}
          />
          {!collapsed && (
            <p
              style={{
                fontSize: 15,
                fontWeight: 500,
                marginLeft: "1rem",
              }}
            >
              Help & Support
            </p>
          )}
        </Link>
      </Sider>
      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
        />
        <Content
          style={{
            margin: "0px",
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              margin: 0,
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                width: "80%",
              }}
            >
              {selectedDialogue ? (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    // alignItems: "center",
                    // justifyContent: "center",
                    width: "80%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 20,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 18,
                        color: "#06152B",
                        fontWeight: "600",
                      }}
                    >
                      {selectedDialogue}
                    </p>
                    <Button
                      onClick={handleDownloadPDF}
                      className="login-form-button"
                      style={{
                        background:
                          "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                        color: "#FFFFFF",
                        borderStyle: "none",
                        textTransform: "uppercase",
                        height: 40,
                        marginBottom: 20,
                        borderRadius: 50,
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>
                        {literals?.buttons?.download_pdf_btn}
                      </p>
                    </Button>
                  </div>
                  {data &&
                    data.map((item, index) => (
                      <div
                        id="pdf-content"
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: 8,
                          marginBottom: 10,
                        }}
                        key={index}
                      >
                        <h4
                          style={{
                            color: "#06152B",
                            fontSize: 18,
                            fontWeight: 500,
                            padding: "10px",
                          }}
                        >
                          {item.question}
                        </h4>
                        <div
                          className="response_scroll"
                          style={{
                            whiteSpace: "pre-line",
                            color: "#06152B",
                            padding: "15px 15px",
                            fontSize: 14,
                            fontWeight: 400,
                            borderRadius: "16px",
                            opacity: "60%",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: parseResponseData(item.answer),
                          }}
                        ></div>
                      </div>
                    ))}
                  <div className="sticky-footer">
                    <div
                      className="d-flex justify-content-between align-items-center responce_search"
                      style={{
                        position: "relative",
                        gap: "10px",
                        marginTop: 20,
                      }}
                    >
                      <SearchInput
                        placeholder={literals?.placeholder_text?.search_query}
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter" && !event.shiftKey) {
                            event.preventDefault();
                            handleSubmit();
                          }
                        }}
                        style={{
                          height: "50px",
                          paddingLeft: "16px",
                          paddingRight: "120px",
                          fontSize: "14px",
                          color: "#667085",
                          borderRadius: "24px",
                          border: "1px solid #F5F5F5",
                          backgroundColor: "#FFFFFF",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          right: "70px",
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <Dropdown overlay={collections} trigger={["click"]}>
                          <Button
                            type="text"
                            style={{
                              fontSize: "14px",
                              color: "#667085",
                              borderRadius: "200px",
                              backgroundColor: "#182B3712",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {selectedCol} <DownOutlined />
                          </Button>
                        </Dropdown>
                        <Button
                          onClick={handleOpenAnswerModal}
                          type="text"
                          icon={<Icon icon="system-uicons:filtering" />}
                          style={{
                            color: "#182B37",
                            opacity: "70%",
                            borderRadius: "200px",
                            backgroundColor: "#182B3712",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      </div>
                      <Link
                        className="search_button text-center"
                        htmltype="submit"
                        style={{
                          background:
                            "linear-gradient(90deg, #3AC1CB 0%, #1D6065 150%)",
                          height: 46,
                          width: 46,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          borderRadius: 200,
                        }}
                      >
                        {!loading ? (
                          <IoSend />
                        ) : (
                          <Spin size="large" fullscreen />
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "80%",
                    height: "100%",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    alt="dialogue pattern"
                    src={dialoguePattern}
                    style={{
                      position: "absolute",
                      top: "22%",
                      left: "13%",
                      width: 134,
                    }}
                  />
                  <img
                    alt="dialogue pattern"
                    src={dialoguePattern}
                    style={{
                      position: "absolute",
                      bottom: "18%",
                      right: "18%",
                      width: 134,
                    }}
                  />
                  <h2
                    style={{
                      fontSize: 20,
                      fontWeight: "700",
                      color: "#06152B",
                    }}
                  >
                    Select Dialogue
                  </h2>
                  <p style={{ color: "#06152B", opacity: "60%" }}>
                    Choose a dialogue from the saved list in the panel on your
                    right.
                  </p>
                </div>
              )}
            </div>
            <div
              style={{
                backgroundColor: "white",
                width: "20%",
                height: "100%",
                padding: 10,
                alignSelf: "start",
              }}
            >
              {todayData.length > 0 && (
                <>
                  <p style={{ color: "#06152B", fontSize: 12 }}>Today</p>
                  {todayData.map((item, index) => (
                    <div style={{ display: "flex" }} key={index}>
                      <Button
                        style={{
                          width: "100%",
                          marginBottom: 10,
                          marginTop: -15,
                          textAlign: "left",
                          borderColor: "white",
                          fontWeight: "500",
                          boxShadow: "none",
                        }}
                        onClick={() => handleSelectChange(item.title)}
                      >
                        {item.title}
                      </Button>
                    </div>
                  ))}
                  <div
                    style={{
                      backgroundColor: "#06152B",
                      height: 1,
                      borderRadius: 20,
                      opacity: "10%",
                      width: "100%",
                      marginBottom: 15,
                    }}
                  />
                </>
              )}

              {/* Last 7 Days Data */}
              {last7DaysData.length > 0 && (
                <>
                  <p style={{ color: "#06152B", fontSize: 12 }}>
                    Previous 7 Days
                  </p>
                  {last7DaysData.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                      }}
                      key={index}
                    >
                      <Button
                        style={{
                          width: "100%",
                          marginTop: -15,
                          marginBottom: 10,
                          textAlign: "left",
                          borderColor: "white",
                          fontWeight: "500",
                          boxShadow: "none",
                        }}
                        onClick={() => handleSelectChange(item.title)}
                      >
                        {item.title}
                      </Button>
                      {/* <MoreOutlined /> */}
                    </div>
                  ))}
                  <div
                    style={{
                      backgroundColor: "#06152B",
                      height: 1,
                      borderRadius: 20,
                      opacity: "10%",
                      width: "100%",
                      marginBottom: 15,
                    }}
                  />
                </>
              )}

              {/* Last 30 Days Data */}
              {last30DaysData.length > 0 && (
                <>
                  <p style={{ color: "#06152B", fontSize: 12 }}>
                    Previous 30 Days
                  </p>
                  {last30DaysData.map((item, index) => (
                    <div style={{ display: "flex" }} key={index}>
                      <Button
                        style={{
                          width: "100%",
                          marginTop: -15,
                          marginBottom: 10,
                          textAlign: "left",
                          borderColor: "white",
                          fontWeight: "500",
                          boxShadow: "none",
                        }}
                        onClick={() => handleSelectChange(item.title)}
                      >
                        {item.title}
                      </Button>
                    </div>
                  ))}
                  <div
                    style={{
                      backgroundColor: "#06152B",
                      height: 1,
                      borderRadius: 20,
                      opacity: "10%",
                      width: "100%",
                      marginBottom: 15,
                    }}
                  />
                </>
              )}

              {/* Older Data */}
              {olderData.length > 0 && (
                <>
                  <p style={{ color: "#06152B", fontSize: 12 }}>Older</p>
                  {olderData.map((item, index) => (
                    <div style={{ display: "flex" }} key={index}>
                      <Button
                        style={{
                          width: "100%",
                          marginTop: -15,
                          marginBottom: 10,
                          textAlign: "left",
                          borderColor: "white",
                          fontWeight: "500",
                          boxShadow: "none",
                        }}
                        onClick={() => handleSelectChange(item.title)}
                      >
                        {item.title}
                      </Button>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </Content>
      </Layout>

      <InstructionModal
        visible={helpModalVisible}
        onClose={handleClosehelpuserModal}
        modalContentRef={modalContentRef}
      />

      <AnswerModal
        visible={answerModalVisible}
        onClose={handleCloseAnswerModal}
        marks={marks}
        handleSliderChange={handleSliderChange}
        temp={temp}
        right="12%"
        top="55%"
      />
    </Layout>
  );
}
