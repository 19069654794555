import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout, Menu, theme } from "antd";
import { FaBuffer } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { GiSettingsKnobs } from "react-icons/gi";
import { PiTreeStructure } from "react-icons/pi";
import { IoLibrary } from "react-icons/io5";
import { GiConversation } from "react-icons/gi";
import image from "../assests/image.png";
import AlertMessage from "../components/AlertMessage";
import MainHeader from "../components/MainHeader";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import AnswerModal from "../components/AnswerModal";
import InstructionModal from "../components/InstructionModal";
import {
  EnteriesHeaders,
  fetchSignUpEmailData,
  getContentEntriesEndpoint,
  updateContentEntriesEndpoint,
} from "../context/constants";
import SubscriptionPage from "../components/SubscriptionPage";
import SubMenu from "antd/es/menu/SubMenu";
import { MdCollectionsBookmark } from "react-icons/md";
import debounce from "lodash/debounce";
import _ from "lodash";
import { useLiterals } from "../context/LiteralsContext";
import ErrorModal from "../components/ErrorModal";
import Link from "antd/es/typography/Link";
import { BsQuestionCircle } from "react-icons/bs";
import { ReactComponent as HomeIcon } from "../assests/home-active.svg";
import { ReactComponent as Dialogues } from "../assests/dialogues.svg";
import { ReactComponent as Library } from "../assests/library.svg";
import { ReactComponent as Collections } from "../assests/collections.svg";
import { ReactComponent as Settings } from "../assests/settings.svg";

// import EditUserModal from "../components/EditUserModal";

const { Sider, Content } = Layout;

const marks = {
  0: "0%",
  20: "20%",
  40: "40%",
  60: "60%",
  80: "80%",
  100: "100%",
};

export default function Subscription() {
  const literals = useLiterals();

  const [collapsed, setCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("company");
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  // const [temp, setTemp] = useState("0.5");
  const [signUpEmailData, setSignUpEmailData] = useState(null);
  const [deniedDomains, setDeniedDomains] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [isdialogVisible, setIsDialogModalVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const [
    errorUpdatingCreativePrecisionValue,
    setErrorUpdatingCreativePrecisionValue,
  ] = useState(false);

  const handleOpenErrorUpdatingCreativePrecisionValue = () => {
    setErrorUpdatingCreativePrecisionValue(true);
  };
  const handleCloseErrorUpdatingCreativePrecisionValue = () => {
    setErrorUpdatingCreativePrecisionValue(false);
  };

  const locale = localStorage.getItem("locale");

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const [temp, setTemp] = useState(() => {
    const storedTemp = localStorage.getItem("temp");
    return storedTemp !== null ? parseFloat(storedTemp) : 0.5;
  });

  const handleSliderChange = (value) => {
    switch (value) {
      case 0:
        setTemp(1.0);
        break;
      case 20:
        setTemp(0.8);
        break;
      case 40:
        setTemp(0.6);
        break;
      case 60:
        setTemp(0.4);
        break;
      case 80:
        setTemp(0.2);
        break;
      default:
        setTemp(0.0);
    }
  };

  const textareaRef = useRef(null);
  const navigate = useNavigate();

  const userDataString = localStorage.getItem("userData");
  const userInfo = JSON.parse(userDataString);

  const primaryUser = localStorage.getItem("primaryUser");

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [searchInput]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleOpenAnswerModal = () => {
    setAnswerModalVisible(true);
  };

  const handleCloseAnswerModal = () => {
    setAnswerModalVisible(false);
  };
  const handleOpenIsDialogModal = () => {
    setIsDialogModalVisible(true);
  };

  const handleCloseIsDailogModal = () => {
    setIsDialogModalVisible(false);
  };

  const fetchDeniedDomains = async () => {
    try {
      const ddContentType = "denied_domains";
      const endPoint = getContentEntriesEndpoint(ddContentType, locale);
      const headers = EnteriesHeaders;
      const response = await axios.get(endPoint, { headers });
      setDeniedDomains(response.data.entries);
    } catch (error) {
      console.log("Failed to fetch denied domains");
    }
  };

  useEffect(() => {
    fetchDeniedDomains();
  }, [locale]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSignUpEmailData(locale);
        setSignUpEmailData(response);
      } catch (error) {
        console.error("Error fetching signup email data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };
  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };

  const modalContentRef = useRef(null);

  // const handlePaymentStatus = () => {
  //   const params = new URLSearchParams(window.location.search);
  //   const paymentStatusParam = params.get("payment_status");

  //   if (paymentStatusParam !== null && paymentStatusParam !== undefined) {
  //     setPaymentStatus(paymentStatusParam);
  //   }
  // };

  // useEffect(() => {
  //   handlePaymentStatus();
  // }, []);

  const setUserPrefSettings = async () => {
    try {
      const contentType = "application_user";
      const contentUid = userInfo?.uid;
      if (!contentUid) {
        console.error("User data is missing or invalid.");
        return;
      }

      const endpoint = updateContentEntriesEndpoint(
        contentType,
        contentUid,
        locale
      );
      const headers = EnteriesHeaders;

      const payload = {
        entry: {
          preferences_and_settings: {
            creativity_precision: parseFloat(temp),
          },
        },
      };
      const res = await axios.put(endpoint, payload, { headers });

      // console.log(res.data);

      const updatedTemp =
        res.data.entry.preferences_and_settings?.creativity_precision;
      if (updatedTemp !== undefined && updatedTemp !== null) {
        setTemp(updatedTemp);
        localStorage.setItem("temp", updatedTemp.toString());
      }
    } catch (error) {
      // showToast("Error updating creative and precision value!", "error");
      // showToast(
      //   `${literals?.toast_messages_text?.error_updating_creative_and_precision_value}`,
      //   "error"
      // );
      handleOpenErrorUpdatingCreativePrecisionValue();
    }
  };

  useEffect(() => {
    setUserPrefSettings();
  }, [temp, locale]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{ maxWidth: "12%" }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={collapsed ? 55 : 95} alt="Logo" />
        </div>

        <Menu theme="light" mode="inline" onClick={() => navigate("/home")}>
          <Menu.Item key="1" icon={<HomeIcon />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu theme="light" mode="inline">
          <Menu.Item
            key="7"
            icon={<Dialogues />}
            onClick={() => navigate("/dialogue")}
          >
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>
        {userInfo?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
            >
              <Menu.Item key="5" icon={<Library />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
            >
              <Menu.Item key="6" icon={<Collections />}>
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}

        <Menu theme="light" mode="inline" defaultSelectedKeys={["3"]}>
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<Settings />}
          >
            {/* <Menu.Item
              key="2"
              icon={<GiSettingsKnobs />}
              onClick={handleOpenAnswerModal}
            >
              {literals?.sidebar?.answers_menu}
            </Menu.Item> */}
            {primaryUser === "true" && (
              <>
                <Menu.Item
                  key="1"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/company")}
                >
                  {literals?.buttons?.company_btn}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/contacts")}
                >
                  {literals?.buttons?.contacts_btn}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/subscription")}
                >
                  {literals?.sidebar?.subscription_menu}
                </Menu.Item>
              </>
            )}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            justifyContent: collapsed ? "center" : "flex-start",
          }}
        >
          <BsQuestionCircle
            className="question"
            style={{ color: "#fff", fontSize: 22 }}
          />
          {!collapsed && (
            <p style={{ fontSize: 15, fontWeight: 500, marginLeft: "1rem" }}>
              Help & Support
            </p>
          )}
        </Link>
      </Sider>

      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
          onClick={handleOpenhelpuserModal}
        />
        <Content
          style={{
            margin: "24px 16px",
            minHeight: 280,
            borderRadius: borderRadiusLG,
            backgroundColor: "#F3F5F8",
          }}
        >
          <div>
            <AlertMessage currentPage={currentPage} />
            <SubscriptionPage />
            {paymentStatus && (
              <div>
                {paymentStatus === "success" ? (
                  <p>Payment successful! Thank you for your purchase.</p>
                ) : (
                  <p>Payment failed. Please try again later.</p>
                )}
              </div>
            )}
            {selectedCategory === "billing" && <div>Billing tab</div>}
          </div>

          <AnswerModal
            visible={answerModalVisible}
            onClose={handleCloseAnswerModal}
            marks={marks}
            handleSliderChange={handleSliderChange}
            temp={temp}
          />

          <InstructionModal
            visible={helpModalVisible}
            onClose={handleClosehelpuserModal}
            modalContentRef={modalContentRef}
          />
          <ErrorModal
            open={errorUpdatingCreativePrecisionValue}
            onCancel={handleCloseErrorUpdatingCreativePrecisionValue}
            message={
              literals?.toast_messages_text
                ?.error_updating_creative_and_precision_value
            }
          />
        </Content>
      </Layout>
    </Layout>
  );
}
