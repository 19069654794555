import { Button, Form, Modal, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Formfield, Logintext } from "../styles/Styles";
import axios from "axios";
import debounce from "lodash/debounce";
import _ from "lodash";
import { toast } from "react-toastify";

export default function DeleteDocument({
  visible,
  onClose,
  data,
  apiKey,
  authToken,
  literals,
}) {
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const deleteDocument = async () => {
    setLoading(true);
    try {
      const payload1 = {
        folder_uid: data.key,
      };

      const payload2 = {
        asset_uid: data.key,
      };

      const headers = {
        api_key: apiKey,
        auth_token: authToken,
      };

      if (data.is_dir === true) {
        const endPoint2 = "https://query3.azurewebsites.net/api/delete-folder";
        await axios.post(endPoint2, payload1, { headers });
      } else {
        const endPoint1 = "https://query3.azurewebsites.net/api/delete-asset";
        await axios.post(endPoint1, payload2, { headers });
      }
      onClose();
      setLoading(false);
      // showToast("Document deleted successfully", "success");
      showToast(
        `${literals?.toast_messages_text?.document_deleted_successfully}`,
        "success"
      );
    } catch (error) {
      // showToast("Error deleting document", "error");
      showToast(
        `${literals?.toast_messages_text?.error_deleting_document}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={500}
      centered
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Formfield>
          <p
            style={{
              fontSize: 22,
              fontWeight: 500,
              color: "#1B1B1B",
              // opacity: "60%",
              textAlign: "center",
            }}
          >
            {literals?.modal_literals?.are_you_sure_want_to_delete}
          </p>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Form.Item>
              <Button
                className="login-form-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: "#2B8E96",
                  color: "#1B1B1B",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  paddingLeft: "30%",
                  paddingRight: "30%",
                  textTransform: "uppercase",
                  borderRadius: "200px",
                }}
                onClick={onClose}
              >
                <Logintext>{literals?.buttons?.cancel_btn}</Logintext>
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={deleteDocument}
                className="login-form-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                  color: "#FFFFFF",
                  borderStyle: "none",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  paddingLeft: "30%",
                  paddingRight: "30%",
                  textTransform: "uppercase",
                  borderRadius: "200px",
                }}
              >
                <Logintext>{literals?.buttons?.delete_btn}</Logintext>
              </Button>
            </Form.Item>
            {loading && <Spin fullscreen />}
          </div>
        </Formfield>
      </div>
    </Modal>
  );
}
