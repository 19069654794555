import React, { useEffect, useState } from "react";
import {
  CopyRight,
  LeftContainer,
  Logo,
  SubTitle,
  Title,
} from "../styles/Styles";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import ReactHtmlParser from "react-html-parser";
import { fetchWelcomeMsgData } from "../context/constants";
import { useLiterals } from "../context/LiteralsContext";
import image from "../assests/image.png";

export default function FormBanner() {
  const [welcomeData, setWelcomeData] = useState(null);
  const literals = useLiterals();

  const locale = localStorage.getItem("locale");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWelcomeMsgData(locale);
        setWelcomeData(response);
      } catch (error) {
        console.error("Error fetching welcome message:", error);
      }
    };

    fetchData();
  }, [locale]);

  return (
    <LeftContainer>
      <div>
        <Logo src={image} />
      </div>
      <div>
        {welcomeData &&
          welcomeData.data &&
          welcomeData.data.all_welcome &&
          welcomeData.data.all_welcome.items &&
          welcomeData.data.all_welcome.items.length > 0 && (
            <>
              <Title>{welcomeData.data.all_welcome.items[0].title}</Title>
              <SubTitle>
                {ReactHtmlParser(
                  jsonToHtml(welcomeData.data.all_welcome.items[0].message.json)
                )}
              </SubTitle>
            </>
          )}
      </div>
    </LeftContainer>
  );
}
