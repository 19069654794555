import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "antd";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formfield, InputField, Logintext } from "../styles/Styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  EnteriesHeaders,
  updateContentEntriesEndpoint,
} from "../context/constants";
import bcrypt from "bcryptjs";
import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";

// import { jsonToHtml } from "@contentstack/json-rte-serializer";

export default function SetPasswordForm({ userData, literals }) {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [pwdDoNotMatch, setPwdDoNotMatch] = useState(false);
  const [pwdCreate, setPwdCreate] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const navigate = useNavigate();

  const locale = localStorage.getItem("locale");

  const content_type = "application_user";

  const handleOpenPwdDoNotMatch = () => {
    setPwdDoNotMatch(true);
  };
  const handleClosePwdDoNotMatch = () => {
    setPwdDoNotMatch(false);
  };

  const handleOpenPwdCreate = () => {
    setPwdCreate(true);
  };
  const handleClosePwdCreate = () => {
    setPwdCreate(false);
  };

  const handleOpenSomethingWentWrong = () => {
    setSomethingWentWrong(true);
  };
  const handleCloseSomethingWentWrong = () => {
    setSomethingWentWrong(false);
  };

  const createPassword = async (values) => {
    setLoading(true);
    try {
      const { encrypted_password, confirmPassword } = values;
      if (encrypted_password !== confirmPassword) {
        // toast.error(`${literals?.toast_messages_text?.passwords_do_not_match}`);
        handleOpenPwdDoNotMatch();
        return;
      }

      const saltRounds = 10;
      const hashedPassword = await bcrypt.hash(encrypted_password, saltRounds);

      const headers = EnteriesHeaders;
      const updateData = {
        entry: {
          encrypted_password: hashedPassword,
        },
      };
      const UpdateUserEndpoint = updateContentEntriesEndpoint(
        content_type,
        userData.uid,
        locale
      );
      await axios.put(UpdateUserEndpoint, updateData, { headers });
      // toast.success(
      //   `${literals?.toast_messages_text?.password_created_successfully}`
      // );
      handleOpenPwdCreate();

      setTimeout(() => {
        navigate("/home");
      }, 5000);
    } catch (error) {
      // toast.error(
      //   `${literals?.toast_messages_text?.something_went_wrong_while_updating_password}`
      // );
      handleOpenSomethingWentWrong();
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setPwdVisible(!pwdVisible);
  };

  return (
    <>
      <Formfield
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={createPassword}
      >
        <Form.Item
          name="encrypted_password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputField
            autoComplete="off"
            // prefix={
            //   <FontAwesomeIcon icon={faLock} style={{ color: "#BBBBBB" }} />
            // }
            type={passwordVisible ? "text" : "password"}
            // placeholder="Password"
            placeholder={literals?.placeholder_text?.password}
            className="boldPlaceholder"
            suffix={
              <FontAwesomeIcon
                icon={passwordVisible ? faEyeSlash : faEye}
                style={{ color: "#BBBBBB", cursor: "pointer" }}
                onClick={togglePasswordVisibility}
              />
            }
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["encrypted_password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("encrypted_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The passwords does not match!")
                );
              },
            }),
          ]}
        >
          <InputField
            autoComplete="off"
            // prefix={
            //   <FontAwesomeIcon icon={faLock} style={{ color: "#BBBBBB" }} />
            // }
            type={pwdVisible ? "text" : "password"}
            // placeholder="Confirm Password"
            placeholder={literals?.placeholder_text?.confirm_password}
            className="boldPlaceholder"
            suffix={
              <FontAwesomeIcon
                icon={pwdVisible ? faEyeSlash : faEye}
                style={{ color: "#BBBBBB", cursor: "pointer" }}
                onClick={toggleConfirmPasswordVisibility}
              />
            }
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={loading ? true : false}
            htmlType="submit"
            className="login-form-button"
            block
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: loading
                ? "#BEC8C8"
                : "linear-gradient(180deg, #3AC1CB 0%, #1D6065 150%)",
              color: "#FFFFFF",
              borderStyle: "none",
              paddingTop: "5%",
              paddingBottom: "5%",
              textTransform: "uppercase",
              borderRadius: "50px",
            }}
          >
            <Logintext>
              {literals?.buttons?.submit}
              {!loading ? (
                <></>
              ) : (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </Logintext>
          </Button>
        </Form.Item>
      </Formfield>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
      <ErrorModal
        open={pwdDoNotMatch}
        onCancel={handleClosePwdDoNotMatch}
        message={literals?.toast_messages_text?.passwords_do_not_match}
      />
      <SuccessModal
        open={pwdCreate}
        onCancel={handleClosePwdCreate}
        message={literals?.toast_messages_text?.password_created_successfully}
      />
      <ErrorModal
        open={somethingWentWrong}
        onCancel={handleCloseSomethingWentWrong}
        message={
          literals?.toast_messages_text
            ?.something_went_wrong_while_updating_password
        }
      />
    </>
  );
}
