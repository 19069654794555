import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
  updateContentEntriesEndpoint,
} from "../context/constants";
import { useLiterals } from "../context/LiteralsContext";
import { ReactComponent as TickIcon } from "../assests/tick.svg";
import { useLocation } from "react-router-dom";

export default function SubscriptionPage() {
  const literals = useLiterals();
  const location = useLocation();
  const [subscriptionPlan, setSubscriptionPlan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);
  const [activeSwitch, setActiveSwitch] = useState(1);
  const paymentStatus = location.state?.paymentStatus;

  const handleToggle = () => {
    setActiveSwitch(activeSwitch === 1 ? 2 : 1);
  };

  const locale = localStorage.getItem("locale");

  const userDataString = localStorage.getItem("userData");
  const userInfo = JSON.parse(userDataString);

  useEffect(() => {
    const initialPlanIndex = (() => {
      const plan = userInfo?.preferences_and_settings?.user_subscription_plan;
      const storedPlan = localStorage.getItem("subscriptionPlan");

      switch (storedPlan || plan) {
        case "BASIC":
          return 0;
        case "PRO":
          return 1;
        case "SPECIAL":
          return 2;
        default:
          return null;
      }
    })();
    setSelectedPlanIndex(initialPlanIndex);
  }, [userInfo]);

  useEffect(() => {
    if (paymentStatus === "success") {
      const selectedPlan = localStorage.getItem("selectedPlan");
      switch (selectedPlan) {
        case "BASIC":
          localStorage.setItem("subscriptionPlan", "BASIC");
          setSelectedPlanIndex(0);
          break;
        case "PRO":
          localStorage.setItem("subscriptionPlan", "PRO");
          setSelectedPlanIndex(1);
          break;
        case "SPECIAL":
          localStorage.setItem("subscriptionPlan", "SPECIAL");
          setSelectedPlanIndex(2);
          break;
        default:
          localStorage.setItem("subscriptionPlan", selectedPlan); // Store the actual selected plan name
          break;
      }
    }
  }, [paymentStatus]);

  useEffect(() => {
    const subscriptions = async () => {
      setIsLoading(true);
      const content_type = "chemaid_subscription";
      try {
        const loginEndpoint = getContentEntriesEndpoint(content_type, locale);
        const headers = EnteriesHeaders;
        const response = await axios.get(loginEndpoint, { headers });

        setSubscriptionPlan(response.data.entries[0] || []);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    subscriptions();
  }, [locale]);

  const handleCheckout = async (data) => {
    setIsLoading(true);
    try {
      const endpoint = "https://query3.azurewebsites.net/api/checkout-customer";
      const payload = {
        customer_email: userInfo.title,
        success_url: "http://localhost:3000/success", //https://chemaid.mutare.app/success
        cancel_url: "http://localhost:3000/cancel", //https://chemaid.mutare.app/cancel
        price_id: data.plans.price_id,
      };

      localStorage.setItem("selectedPlan", data.plans.features);

      const res = await axios.post(endpoint, payload);
      setIsLoading(false);
      window.location.href = res.data.payment_url;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const updateSubscription = async () => {
      const selectedPlan = localStorage.getItem("subscriptionPlan");
      if (selectedPlan) {
        const CT = "companies";
        const companyUid = userInfo.company_uid;
        const endPoint = updateContentEntriesEndpoint(CT, companyUid, locale);
        const headers = EnteriesHeaders;

        const UCT = "application_user";
        const Uid = userInfo.uid;
        const userEndPoint = updateContentEntriesEndpoint(UCT, Uid, locale);

        const userPayload = {
          entry: {
            preferences_and_settings: {
              subscription: true,
              user_subscription_plan: selectedPlan,
            },
          },
        };

        const payload = {
          entry: {
            subscription_plan: selectedPlan,
          },
        };

        await axios.put(endPoint, payload, { headers });
        await axios.put(userEndPoint, userPayload, {
          headers,
        });
        setIsLoading(false);
      }
    };
    updateSubscription();
  }, [locale]);

  // console.log("==>", subscriptionPlan);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <div style={{ marginTop: 25 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontSize: 20,
              fontWeight: 700,
              color: "#06152B",
            }}
          >
            Available Plans
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: 200,
              borderRadius: 100,
              border: "2px solid #E4E4E4",
              backgroundColor: "#fff",
              cursor: "pointer",
              padding: 5,
              marginBottom: 10,
            }}
            onClick={handleToggle}
          >
            <div
              style={{
                width: "50%",
                padding: "10px 0",
                textAlign: "center",
                background:
                  activeSwitch === 1
                    ? "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)"
                    : "transparent",
                color: activeSwitch === 1 ? "#fff" : "#000000",
                borderRadius: "100px",
                transition: "background-color 0.5s",
                fontWeight: 500,
              }}
            >
              Monthly
            </div>
            <div
              style={{
                width: "50%",
                padding: "10px 0",
                textAlign: "center",
                background:
                  activeSwitch === 2
                    ? "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)"
                    : "transparent",
                color: activeSwitch === 2 ? "#fff" : "#000000",
                borderRadius: "100px",
                transition: "background-color 0.5s",
                fontWeight: 500,
              }}
            >
              Annualy
            </div>
          </div>
        </div>

        {activeSwitch === 1 ? (
          <div
            style={{
              display: "flex",
              marginTop: 16,
              width: "100%",
              gap: 20,
            }}
          >
            {subscriptionPlan &&
              subscriptionPlan?.modular_blocks_for_plans &&
              subscriptionPlan?.modular_blocks_for_plans?.map((plan, index) => {
                const priceParts =
                  plan.plans.price_per_user_mo_annual_.split("-");

                return (
                  <div key={index}>
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        padding: 24,
                        borderRadius: 8,
                        height: "100%",
                        width: 330,
                        textAlign: "left",
                        borderWidth: 1,
                        borderColor: "#E4E4E4",
                      }}
                    >
                      <div style={{ marginBottom: "2.8rem" }}>
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: 24,
                            marginBottom: 0,
                          }}
                        >
                          {plan.plans.features}
                        </p>
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: 32,
                            marginBottom: 0,
                          }}
                        >
                          {priceParts[0]}
                        </p>
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: 12,
                            marginBottom: 0,
                          }}
                        >
                          {priceParts[1]}
                        </p>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <TickIcon
                            style={{
                              height: 20,
                              width: 20,
                              backgroundColor: "#F2F2F2",
                              borderRadius: 3.2,
                            }}
                          />
                          Up to
                          <span style={{ fontWeight: 600 }}>
                            {plan.plans.users}
                          </span>
                          Users
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <TickIcon
                            style={{
                              height: 20,
                              width: 20,
                              backgroundColor: "#F2F2F2",
                              borderRadius: 3.2,
                            }}
                          />
                          <span style={{ fontWeight: 600 }}>
                            {plan.plans.questions_month}
                          </span>
                          Question / Per Month
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <TickIcon
                            style={{
                              height: 20,
                              width: 20,
                              backgroundColor: "#F2F2F2",
                              borderRadius: 3.2,
                            }}
                          />
                          <span style={{ fontWeight: 600 }}>
                            {plan.plans.company_library}
                          </span>
                          Private Document Library
                        </div>
                      </div>
                      <button
                        style={{
                          height: 40,
                          width: "100%",
                          textAlign: "center",
                          borderColor: "#00000033",
                          background: `${
                            selectedPlanIndex === index
                              ? "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)"
                              : "none"
                          }`,
                          borderWidth: 1,
                          borderRadius: 174,
                          color: `${
                            selectedPlanIndex === index
                              ? "white"
                              : "linear-gradient(60deg, #3AC1CB 0%, #1D6065 100%)"
                          }`,
                          backgroundColor: "white",
                          marginTop: 20,
                          fontWeight: 600,
                        }}
                        onClick={() => handleCheckout(plan)}
                      >
                        {selectedPlanIndex === index
                          ? literals?.buttons?.current_plan_btn
                          : plan.plans.button_link.title}
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              marginTop: 16,
              width: "100%",
              gap: 20,
            }}
          >
            {subscriptionPlan &&
              subscriptionPlan?.modular_blocks_for_plans &&
              subscriptionPlan?.modular_blocks_for_plans?.map((plan, index) => {
                const priceParts =
                  plan.plans.price_per_user_annually.split("-");

                return (
                  <div key={index}>
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        padding: 24,
                        borderRadius: 8,
                        height: "100%",
                        width: 330,
                        textAlign: "left",
                        borderWidth: 1,
                        borderColor: "#E4E4E4",
                      }}
                    >
                      <div style={{ marginBottom: "2.8rem" }}>
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: 24,
                            marginBottom: 0,
                          }}
                        >
                          {plan.plans.features}
                        </p>
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: 32,
                            marginBottom: 0,
                          }}
                        >
                          {priceParts[0]}
                        </p>
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: 12,
                            marginBottom: 0,
                          }}
                        >
                          {priceParts[1]}
                        </p>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <TickIcon
                            style={{
                              height: 20,
                              width: 20,
                              backgroundColor: "#F2F2F2",
                              borderRadius: 3.2,
                            }}
                          />
                          Up to
                          <span style={{ fontWeight: 600 }}>
                            {plan.plans.users}
                          </span>
                          Users
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <TickIcon
                            style={{
                              height: 20,
                              width: 20,
                              backgroundColor: "#F2F2F2",
                              borderRadius: 3.2,
                            }}
                          />
                          <span style={{ fontWeight: 600 }}>
                            {plan.plans.questions_month}
                          </span>
                          Question / Per Month
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <TickIcon
                            style={{
                              height: 20,
                              width: 20,
                              backgroundColor: "#F2F2F2",
                              borderRadius: 3.2,
                            }}
                          />
                          <span style={{ fontWeight: 600 }}>
                            {plan.plans.company_library}
                          </span>
                          Private Document Library
                        </div>
                      </div>
                      <button
                        style={{
                          height: 40,
                          width: "100%",
                          textAlign: "center",
                          borderColor: "#00000033",
                          background: `${
                            selectedPlanIndex === index
                              ? "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)"
                              : "none"
                          }`,
                          borderWidth: 1,
                          borderRadius: 174,
                          color: `${
                            selectedPlanIndex === index
                              ? "white"
                              : "linear-gradient(60deg, #3AC1CB 0%, #1D6065 100%)"
                          }`,
                          backgroundColor: "white",
                          marginTop: 20,
                          fontWeight: 600,
                        }}
                        onClick={() => handleCheckout(plan)}
                      >
                        {selectedPlanIndex === index
                          ? literals?.buttons?.current_plan_btn
                          : plan.plans.button_link.title}
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {isLoading && <Spin fullscreen />}
    </div>
  );
}
