import { Button, Dropdown, Input, Modal, Spin, Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import {
  AiFillDislike,
  AiFillFile,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineFile,
  AiOutlineLike,
  AiOutlineReload,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
  searchQuery,
  updateContentEntriesEndpoint,
} from "../context/constants";
import { ToastContainer, toast } from "react-toastify";
import { Logintext, SearchInput } from "../styles/Styles";
import debounce from "lodash/debounce";
import _ from "lodash";
import { DownOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import AnswerModal from "./AnswerModal";
import { IoSend } from "react-icons/io5";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

export default function DialogModal({
  visible,
  onClose,
  resdata,
  question,
  collections,
  literals,
}) {
  const [copy, setCopy] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [responses, setResponses] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [dialogueValue, setDialogueValue] = useState("");
  const [toastMessage, setToastMessage] = useState(null);
  const [shouldPostQnA, setShouldPostQnA] = useState(false);
  const [selectedCol, setSelectedCol] = useState("Chem.Aid");
  const [settingsUpdated, setSettingsUpdated] = useState(false);
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  const [temp, setTemp] = useState(() => {
    const storedTemp = localStorage.getItem("temp");
    return storedTemp !== null ? parseFloat(storedTemp) : 0.5;
    // const storedTemp = localStorage.getItem("userData");
    // const userData = JSON.parse(storedTemp);
    // return userData && userData.temp ? parseFloat(userData.temp) : 0.5;
  });

  const [tryAgainLater, setTryAgainLater] = useState(false);
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [dialoguesSaved, setDialoguesSaved] = useState(false);
  const [errorSavingDialogues, setErrorSavingDialogues] = useState(false);
  const [
    errorUpdatingCreativePrecisionValue,
    setErrorUpdatingCreativePrecisionValue,
  ] = useState(false);
  const [responseStates, setResponseStates] = useState({});

  const handleOpenTryAgainLater = () => {
    setTryAgainLater(true);
  };
  const handleCloseTryAgainLater = () => {
    setTryAgainLater(false);
  };

  const handleOpenSubmissionFailed = () => {
    setSubmissionFailed(true);
  };
  const handleCloseSubmissionFailed = () => {
    setSubmissionFailed(false);
  };

  const handleOpenDialoguesSaved = () => {
    setDialoguesSaved(true);
  };
  const handleCloseDialoguesSaved = () => {
    setDialoguesSaved(false);
  };

  const handleOpenErrorSavingDialogues = () => {
    setErrorSavingDialogues(true);
  };
  const handleCloseErrorSavingDialogues = () => {
    setErrorSavingDialogues(false);
  };

  const handleOpenErrorUpdatingCreativePrecisionValue = () => {
    setErrorUpdatingCreativePrecisionValue(true);
  };
  const handleCloseErrorUpdatingCreativePrecisionValue = () => {
    setErrorUpdatingCreativePrecisionValue(false);
  };

  const locale = localStorage.getItem("locale");

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  useEffect(() => {
    if (resdata) {
      setResponses(resdata);
    }
  }, [resdata]);

  useEffect(() => {
    if (question && responses) {
      let genResults = JSON.parse(localStorage.getItem("searchResults")) || [];

      const exists = genResults.some((item) => item.question === question);

      if (!exists) {
        genResults.push({ question: question, responses: responses });
        localStorage.setItem("searchResults", JSON.stringify(genResults));
      }
    }
  }, [question, responses]);

  useEffect(() => {
    if (shouldPostQnA && responses) {
      PostQnA();
      setShouldPostQnA(false);
    }
  }, [responses, shouldPostQnA]);

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    if (input.length <= 8192) {
      setSearchInput(input);
    }
  };

  const handleDialogueTitleChange = (event) => {
    setDialogueValue(event.target.value);
  };

  const parseResponseData = (text) => {
    return text?.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  const PostQnA = async () => {
    const qaContentType = "qanda";
    const endpoint = getContentEntriesEndpoint(qaContentType, locale);
    const headers = EnteriesHeaders;
    const RandomTitle = generateRandomString(8);
    try {
      const data = {
        entry: {
          title: RandomTitle,
          qanda_user: userData?.title,
          question: searchInput,
          answer: responses,
        },
      };
      const res = await axios.post(endpoint, data, { headers });
      const dTitle = JSON.parse(localStorage.getItem("dTitle")) || [];
      dTitle.push({ dialogueTitle: res.data.entry.title });
      localStorage.setItem("dTitle", JSON.stringify(dTitle));
    } catch (error) {
      handleOpenTryAgainLater();
    }
  };

  const copyResponseData1 = (data) => {
    const textarea = document.createElement("textarea");
    textarea.value = data;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopy(true);
    setDisliked(false);
    setLiked(false);
  };

  const handleToggleLike1 = () => {
    if (liked) {
      setLiked(false);
    } else {
      setLiked(true);
      setDisliked(false);
      setCopy(false);
    }
  };

  const handleToggleDislike1 = () => {
    if (disliked) {
      setDisliked(false);
    } else {
      setDisliked(true);
      setLiked(false);
      setCopy(false);
    }
  };

  const handleToggleLike = (index) => {
    setResponseStates((prevStates) => ({
      ...prevStates,
      [index]: {
        ...prevStates[index],
        liked: !prevStates[index]?.liked,
        disliked: false,
        copy: false,
      },
    }));
  };

  const handleToggleDislike = (index) => {
    setResponseStates((prevStates) => ({
      ...prevStates,
      [index]: {
        ...prevStates[index],
        disliked: !prevStates[index]?.disliked,
        liked: false,
        copy: false,
      },
    }));
  };

  const copyResponseData = (data, index) => {
    const textarea = document.createElement("textarea");
    textarea.value = data;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setResponseStates((prevStates) => ({
      ...prevStates,
      [index]: {
        ...prevStates[index],
        copy: true,
        disliked: false,
        liked: false,
      },
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const params = {
        question: searchInput,
        temperature: temp,
      };
      if (searchInput.trim() !== "") {
        const response = await axios.post(searchQuery, params);
        setResponses(response.data);

        const searchResults =
          JSON.parse(localStorage.getItem("searchResults")) || [];
        const exists = searchResults.some(
          (item) => item.question === searchInput
        );

        if (!exists) {
          searchResults.push({
            question: searchInput,
            responses: response.data,
          });
          localStorage.setItem("searchResults", JSON.stringify(searchResults));
        }
        setLiked(false);
        setDisliked(false);
        setCopy(false);
        setResponseStates({});
        setShouldPostQnA(true);
        fetchSearchResults();
        // PostQnA();
      } else {
        setResponses(null);
      }
    } catch (error) {
      handleOpenSubmissionFailed();
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchResults = () => {
    const savedSearchResults = JSON.parse(
      localStorage.getItem("searchResults")
    );
    if (savedSearchResults) {
      setSearchResults(savedSearchResults);
    }
  };

  const handleOnClose = () => {
    localStorage.removeItem("searchResults");
    localStorage.removeItem("dTitle");
    setSearchResults([]);
    onClose();
  };

  const saveDialogue = async () => {
    try {
      const contentType = "dialogue";
      const headers = EnteriesHeaders;
      const endPoint = getContentEntriesEndpoint(contentType, locale);

      const dialogueTitleString = localStorage.getItem("dTitle");
      const dialogueTitle = JSON.parse(dialogueTitleString);
      const dTitle = dialogueTitle.map((item) => item.dialogueTitle);
      const dialoguesString = dTitle.join("•");

      const payload = {
        entry: {
          title: dialogueValue,
          application_user: userData?.title,
          dialogue_array: dialoguesString,
        },
      };

      await axios.post(endPoint, payload, { headers });
      handleOpenDialoguesSaved();
    } catch (error) {
      handleOpenErrorSavingDialogues();
    }
  };

  const handleOpenAnswerModal = () => {
    setAnswerModalVisible(true);
  };

  const handleCloseAnswerModal = () => {
    setAnswerModalVisible(false);
  };

  const setUserPrefSettings = async (temp) => {
    try {
      const contentType = "application_user";
      const contentUid = userData?.uid;
      if (!contentUid) {
        console.error("User data is missing or invalid.");
        return;
      }

      const endpoint = updateContentEntriesEndpoint(
        contentType,
        contentUid,
        locale
      );
      const headers = EnteriesHeaders;

      const payload = {
        entry: {
          preferences_and_settings: {
            creativity_precision: temp,
          },
        },
      };
      if (
        !settingsUpdated &&
        parseFloat(temp) !==
          userData?.preferences_and_settings?.creativity_precision
      ) {
        const res = await axios.put(endpoint, payload, { headers });

        const updatedTemp =
          res.data.entry.preferences_and_settings?.creativity_precision;
        if (updatedTemp !== undefined && updatedTemp !== null) {
          setTemp(updatedTemp);
          localStorage.setItem("temp", updatedTemp);
        }
        setSettingsUpdated(true);
      } else {
        console.log("creativity_precision is already up to date or unchanged");
      }
    } catch (error) {
      handleOpenErrorUpdatingCreativePrecisionValue();
    }
  };

  const handleSliderChange = (value) => {
    switch (value) {
      case 0:
        setTemp(() => {
          localStorage.setItem("temp", "1.0");
          setUserPrefSettings(1.0);
          return 1.0;
        });
        break;
      case 20:
        setTemp(() => {
          localStorage.setItem("temp", "0.8");
          setUserPrefSettings(0.8);
          return 0.8;
        });
        break;
      case 40:
        setTemp(() => {
          localStorage.setItem("temp", "0.6");
          setUserPrefSettings(0.6);
          return 0.6;
        });
        break;
      case 60:
        setTemp(() => {
          localStorage.setItem("temp", "0.4");
          setUserPrefSettings(0.4);
          return 0.4;
        });
        break;
      case 80:
        setTemp(() => {
          localStorage.setItem("temp", "0.2");
          setUserPrefSettings(0.2);
          return 0.2;
        });
        break;
      default:
        setTemp(() => {
          localStorage.setItem("temp", "0.0");
          setUserPrefSettings(0.0);
          return 0.0;
        });
    }
  };

  const marks = {
    0: "0%",
    20: "20%",
    40: "40%",
    60: "60%",
    80: "80%",
    100: "100%",
  };

  return (
    <Modal
      open={visible}
      onCancel={handleOnClose}
      maskClosable={false}
      footer={null}
      width={1200}
      centered
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
      <ErrorModal
        open={tryAgainLater}
        onCancel={handleCloseTryAgainLater}
        message={
          literals?.toast_messages_text
            ?.unable_to_perform_the_action_please_try_again_later
        }
      />
      <ErrorModal
        open={submissionFailed}
        onCancel={handleCloseSubmissionFailed}
        message={literals?.toast_messages_text?.submission_failed}
      />
      <SuccessModal
        open={dialoguesSaved}
        onCancel={handleCloseDialoguesSaved}
        message={literals?.toast_messages_text?.dialogues_saved_successfully}
      />
      <ErrorModal
        open={errorSavingDialogues}
        onCancel={handleCloseErrorSavingDialogues}
        message={literals?.toast_messages_text?.error_saving_dialogues}
      />
      <ErrorModal
        open={errorUpdatingCreativePrecisionValue}
        onCancel={handleCloseErrorUpdatingCreativePrecisionValue}
        message={
          literals?.toast_messages_text
            ?.error_updating_creative_and_precision_value
        }
      />

      <div
        style={{ paddingRight: 20, paddingLeft: 20 }}
        className="dailog_modal"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {literals?.placeholder_text?.name_this_dialogue}
          </div>
          <button
            onClick={handleOnClose}
            style={{
              border: "none",
              boxShadow: "none",
              backgroundColor: "#F3F5F5",
              position: "absolute",
              right: 20,
              top: 10,
            }}
          >
            <Icon icon="mingcute:close-line" width={24} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            borderRadius: "8px",
            textAlign: "center",
            paddingTop: 10,
          }}
        >
          <Input
            value={dialogueValue}
            onChange={handleDialogueTitleChange}
            type="text"
            className="search"
            style={{
              width: "60%",
              height: 38,
              borderRadius: 200,
            }}
          />
          <Button
            onClick={saveDialogue}
            className="login-form-button"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
              color: "#FFFFFF",
              borderStyle: "none",
              textTransform: "uppercase",
              marginLeft: 20,
              borderRadius: 200,
              height: 38,
              paddingLeft: 24,
              paddingRight: 24,
            }}
          >
            <Logintext>{literals?.buttons?.save_btn}</Logintext>
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: 8,
              padding: 20,
              marginTop: 30,
              marginBottom: 5,
              height: "auto",
            }}
          >
            <h4
              style={{
                color: "#06152B",
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              {question}
            </h4>
            <div
              className="response_scroll"
              style={{
                whiteSpace: "pre-line",
                color: "#06152B",
                fontSize: 14,
                fontWeight: 400,
                opacity: "60%",
              }}
              dangerouslySetInnerHTML={{
                __html: parseResponseData(resdata),
              }}
            ></div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              width: "auto",
              display: "flex",
              flexDirection: "row",
              position: "relative",
              left: "41%",
            }}
          >
            <Tooltip title="Like">
              <div
                onClick={() => {
                  handleToggleLike1();
                }}
                className="content-icon me-2"
              >
                <Link>{!liked ? <AiOutlineLike /> : <AiFillLike />}</Link>
              </div>
            </Tooltip>
            <Tooltip title="Dislike">
              <div
                onClick={() => {
                  handleToggleDislike1();
                }}
                className="content-icon me-2"
              >
                <Link>
                  {!disliked ? <AiOutlineDislike /> : <AiFillDislike />}
                </Link>
              </div>
            </Tooltip>
            <Tooltip title="Regenerate">
              <div onClick={() => {}} className="content-icon me-2">
                <Link onClick={handleSubmit}>
                  <AiOutlineReload />
                </Link>
              </div>
            </Tooltip>
            <Tooltip title="Copy to clipboard">
              <div
                className="content-icon"
                onClick={() => {
                  copyResponseData1(resdata);
                }}
              >
                <Link>{!copy ? <AiOutlineFile /> : <AiFillFile />}</Link>
              </div>
            </Tooltip>
          </div>
        </div>
        {searchResults &&
          searchResults.slice(1).map((item, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  padding: 20,
                  marginTop: 30,
                  marginBottom: 5,
                  height: "auto",
                }}
              >
                <h4
                  style={{
                    color: "#06152B",
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                >
                  {item.question}
                </h4>
                <div
                  className="response_scroll"
                  style={{
                    whiteSpace: "pre-line",
                    color: "#06152B",
                    fontSize: 14,
                    fontWeight: 400,
                    opacity: "60%",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: parseResponseData(item.responses),
                  }}
                ></div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderBottomLeftRadius: 16,
                  borderBottomRightRadius: 16,
                  width: "auto",
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  left: "41%",
                }}
              >
                <Tooltip title="Like">
                  <div
                    onClick={() => {
                      handleToggleLike(index);
                    }}
                    className="content-icon me-2"
                  >
                    {/* <Link>{!liked ? <AiOutlineLike /> : <AiFillLike />}</Link> */}
                    <Link>
                      {responseStates[index]?.liked ? (
                        <AiFillLike />
                      ) : (
                        <AiOutlineLike />
                      )}
                    </Link>
                  </div>
                </Tooltip>
                <Tooltip title="Dislike">
                  <div
                    onClick={() => {
                      handleToggleDislike(index);
                    }}
                    className="content-icon me-2"
                  >
                    {/* <Link>
                      {!disliked ? <AiOutlineDislike /> : <AiFillDislike />}
                    </Link> */}
                    <Link>
                      {responseStates[index]?.disliked ? (
                        <AiFillDislike />
                      ) : (
                        <AiOutlineDislike />
                      )}
                    </Link>
                  </div>
                </Tooltip>
                <Tooltip title="Regenerate">
                  <div onClick={() => {}} className="content-icon me-2">
                    <Link onClick={handleSubmit}>
                      <AiOutlineReload />
                    </Link>
                  </div>
                </Tooltip>
                <Tooltip title="Copy to clipboard">
                  <div
                    className="content-icon"
                    onClick={() => {
                      copyResponseData(responses, index);
                    }}
                  >
                    {/* <Link>{!copy ? <AiOutlineFile /> : <AiFillFile />}</Link> */}
                    <Link>
                      {responseStates[index]?.copy ? (
                        <AiFillFile />
                      ) : (
                        <AiOutlineFile />
                      )}
                    </Link>
                  </div>
                </Tooltip>
              </div>
            </div>
          ))}

        <div className="sticky-footer">
          <div
            className="d-flex justify-content-between align-items-center responce_search"
            style={{
              position: "relative",
              gap: "10px",
              marginTop: 20,
            }}
          >
            <SearchInput
              placeholder={literals?.placeholder_text?.search_query}
              value={searchInput}
              onChange={handleSearchInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleSubmit();
                }
              }}
              style={{
                height: "50px",
                paddingLeft: "16px",
                paddingRight: "120px",
                fontSize: "14px",
                color: "#667085",
                borderRadius: "24px",
                border: "1px solid #F5F5F5",
                backgroundColor: "#FFFFFF",
                marginRight: 10,
              }}
            />
            <div
              style={{
                position: "absolute",
                right: "7%",
                bottom: "18%",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Dropdown overlay={collections} trigger={["click"]}>
                <Button
                  type="text"
                  style={{
                    fontSize: "14px",
                    color: "#667085",
                    borderRadius: "200px",
                    backgroundColor: "#182B3712",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {selectedCol} <DownOutlined />
                </Button>
              </Dropdown>
              <Button
                onClick={handleOpenAnswerModal}
                type="text"
                icon={<Icon icon="system-uicons:filtering" />}
                style={{
                  color: "#182B37",
                  opacity: "70%",
                  borderRadius: "200px",
                  backgroundColor: "#182B3712",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </div>
            {/* <Charlimit>
                  <span
                    style={{
                      position: "absolute",
                      bottom: 5,
                      right: 45,
                      fontSize: 13,
                      color: searchInput.length <= 8192 ? "gray" : "red",
                    }}
                  >
                    {searchInput.length}/8192
                  </span>
                </Charlimit> */}
            <Link
              onClick={handleSubmit}
              className="search_button text-center"
              htmltype="submit"
              style={{
                background: "linear-gradient(90deg, #3AC1CB 0%, #1D6065 150%)",
                height: 46,
                width: 46,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                borderRadius: 200,
              }}
            >
              {!loading ? <IoSend /> : <Spin size="large" fullscreen />}
            </Link>
          </div>
        </div>
      </div>
      <AnswerModal
        visible={answerModalVisible}
        onClose={handleCloseAnswerModal}
        marks={marks}
        handleSliderChange={handleSliderChange}
        temp={temp}
        right="5%"
        top="25%"
      />
    </Modal>
  );
}
