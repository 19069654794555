import { Button, Col, Drawer, Form, Modal, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Formfield, InputField, Logintext } from "../styles/Styles";
import PhoneInput from "react-phone-input-2";
import {
  EnteriesHeaders,
  updateContentEntriesEndpoint,
} from "../context/constants";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import _ from "lodash";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

export default function EditUserModal({
  visible,
  onClose,
  setPhoneNumber,
  phoneNumber,
  onFinish,
  record,
  literals,
}) {
  const [isEmpty, setIsEmpty] = useState(true);
  const [form] = Form.useForm();
  const [toastMessage, setToastMessage] = useState(null);

  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleOpenUpdatedSuccessfully = () => {
    setUpdatedSuccessfully(true);
  };
  const handleCloseUpdatedSuccessfully = () => {
    setUpdatedSuccessfully(false);
  };

  const handleOpenErrorMessage = () => {
    setErrorMessage(true);
  };
  const handleCloseErrorMessage = () => {
    setErrorMessage(false);
  };

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  useEffect(() => {
    if (visible && record) {
      // console.log("record", record);
      form.setFieldsValue(record);
    }
  }, [visible, record, form]);

  const handleInputChange = (event) => {
    setIsEmpty(event.target.value === "");
  };

  const handleUpdateUser = async (values) => {
    try {
      const contentType = "application_user";
      const userId = record?.uid;
      const endpoint = updateContentEntriesEndpoint(contentType, userId);
      const headers = EnteriesHeaders;

      const payload = {
        entry: {
          user_name: values.user_name,
          title: values.title,
          contact_number: values.contact_number,
        },
      };
      await axios.put(endpoint, payload, { headers });
      handleOpenUpdatedSuccessfully();
      // toast("Updated successfully", "success");
    } catch (error) {
      console.log(error.message);
      // showToast("Error: " + error.message, "error");
      handleOpenErrorMessage();
    } finally {
      onClose();
    }
  };

  return (
    <Drawer
      destroyOnClose
      open={visible}
      onClose={onClose}
      maskClosable={false}
      footer={null}
      width={450}
      title={literals?.library_page?.edit_user}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "center",
          paddingTop: "5%",
          paddingBottom: "5%",
          width: "100%",
        }}
      >
        <Formfield initialValues={record} onFinish={handleUpdateUser}>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                name="user_name"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    border: "none",
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    width: "400px",
                    backgroundColor: "#F3F5F8",
                    border: "none",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="contact_number"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <PhoneInput
                  inputStyle={{
                    height: 44,
                    width: "400px",
                    border: "none",
                    backgroundColor: "#F3F5F8",
                  }}
                  country={"us"}
                  value={phoneNumber}
                  onChange={(text) => setPhoneNumber(text)}
                  className="boldPlaceholder"
                />
              </Form.Item>
              {/* <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <InputField
                style={{ backgroundColor: "#F3F5F5" }}
                autoComplete="off"
                type="number"
                placeholder="Telephone"
                className="Placeholder"
                onChange={handleInputChange}
                suffix={
                  isEmpty ? (
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        left: "22%",
                      }}
                    >
                      *
                    </span>
                  ) : null
                }
              />
            </Form.Item> */}
            </Col>
          </Row>
          <Form.Item>
            <Button
              htmlType="submit"
              className="login-form-button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                color: "#FFFFFF",
                borderStyle: "none",
                height: 40,
                borderRadius: 200,
                paddingLeft: "8%",
                paddingRight: "8%",
                textTransform: "uppercase",
              }}
            >
              <Logintext>{literals?.buttons?.save_btn}</Logintext>
            </Button>
          </Form.Item>
        </Formfield>
      </div>
      <SuccessModal
        open={updatedSuccessfully}
        onCancel={handleCloseUpdatedSuccessfully}
        message="Updated successfully"
      />
      <ErrorModal
        open={errorMessage}
        onCancel={handleCloseErrorMessage}
        message="Error updating user"
      />
    </Drawer>
  );
}
