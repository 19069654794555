import { Card, Col, Row } from "antd";
import React from "react";
import Link from "antd/es/typography/Link";

export default function OrganizationTabs({
  selectedCategory,
  setSelectedCategory,
  literals,
}) {
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <Row
      gutter={32}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Col lg={6} md={12} sm={12} xs={12}>
        <Link>
          <Card
            className="facility_wrapper mt-4 "
            style={{
              backgroundColor: `${
                selectedCategory === "company" ? "#3ac1cb" : "#fff"
              }`,
            }}
            onClick={() => handleCategoryClick("company")}
          >
            <div className="text-center">
              <h2
                style={{
                  color: `${
                    selectedCategory === "company" ? "#fff" : "#548235"
                  }`,
                }}
              >
                {literals?.buttons?.company_btn}
              </h2>
            </div>
          </Card>
        </Link>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <Link>
          <Card
            className="facility_wrapper mt-4"
            style={{
              backgroundColor: `${
                selectedCategory === "contacts" ? "#3ac1cb" : "#fff"
              }`,
            }}
            onClick={() => handleCategoryClick("contacts")}
          >
            <div className="text-center">
              <h2
                style={{
                  color: `${
                    selectedCategory === "contacts" ? "#fff" : "#548235"
                  }`,
                }}
              >
                {literals?.buttons?.contacts_btn}
              </h2>
            </div>
          </Card>
        </Link>
      </Col>
      <Col lg={6} md={12} sm={12} xs={12}>
        <Link>
          <Card
            className="facility_wrapper mt-4"
            style={{
              backgroundColor: `${
                selectedCategory === "subscriptionPlan" ? "#3ac1cb" : "#fff"
              }`,
            }}
            onClick={() => handleCategoryClick("subscriptionPlan")}
          >
            <div className="text-center">
              <h2
                style={{
                  color: `${
                    selectedCategory === "subscriptionPlan" ? "#fff" : "#548235"
                  }`,
                }}
              >
                {literals?.buttons?.subsctiption_plan_btn}
              </h2>
            </div>
          </Card>
        </Link>
      </Col>
      {/* <Col lg={6} md={12} sm={12} xs={12}>
                <Link>
                  <Card
                    className="facility_wrapper mt-4"
                    style={{
                      backgroundColor: `${
                        selectedCategory === "billing" ? "#3ac1cb" : "#fff"
                      }`,
                    }}
                    onClick={() => handleCategoryClick("billing")}
                  >
                    <div className="text-center">
                      <h2
                        style={{
                          color: `${
                            selectedCategory === "billing" ? "#fff" : "#548235"
                          }`,
                        }}
                      >
                        BILLING
                      </h2>
                    </div>
                  </Card>
                </Link>
              </Col> */}
    </Row>
  );
}
