import { Modal, Slider } from "antd";
import React, { useState } from "react";
import { useLiterals } from "../context/LiteralsContext";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function AnswerModal({
  visible,
  onClose,
  marks,
  handleSliderChange,
  temp,
  right,
  top,
}) {
  const literals = useLiterals();

  const [clickedMark, setClickedMark] = useState(null);

  const locale = localStorage.getItem("locale");

  const handleSliderClick = (value) => {
    setClickedMark(value in marks ? value : null);
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={600}
      style={{ right: right, top: top, position: "absolute" }}
    >
      <button
        onClick={onClose}
        style={{
          border: "none",
          boxShadow: "none",
          backgroundColor: "#F3F5F5",
          position: "absolute",
          right: 5,
          top: 5,
        }}
      >
        <Icon icon="mingcute:close-line" width={24} />
      </button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "5%",
          paddingBottom: "5%",
          width: "100%",
        }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "#548235",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {literals?.answers_modal?.modal_title}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 18,
            fontWeight: 600,
            marginLeft: "3%",
            marginRight: "3%",
          }}
        >
          <div>
            <p>{literals?.answers_modal?.more_creative}</p>
          </div>
          <div>
            <p>{literals?.answers_modal?.more_precise}</p>
          </div>
        </div>

        <div>
          <Slider
            marks={marks}
            value={temp === 1.0 ? 0 : (1 - temp) * 100}
            style={{ width: "500px", margin: 20 }}
            step={null}
            tooltipVisible={false}
            onAfterChange={() => setClickedMark(null)}
            onClickStep={handleSliderClick}
            onChange={handleSliderChange}
            styles={{
              track: {
                background: "green",
              },
              tracks: {
                background: "green",
              },
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
