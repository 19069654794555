import { Button, Col, Drawer, Form, Modal, Row } from "antd";
import { useState } from "react";
import { Formfield, InputField, Logintext } from "../styles/Styles";
import PhoneInput from "react-phone-input-2";
import { Switch } from "antd";

export default function AddUserModal({
  visible,
  onClose,
  setPhoneNumber,
  phoneNumber,
  onFinish,
  literals,
}) {
  const [isEmpty, setIsEmpty] = useState(true);
  const [checked, setChecked] = useState(false);

  const handleInputChange = (event) => {
    setIsEmpty(event.target.value === "");
  };

  const onChange = (checked) => {
    setChecked(checked);
  };

  return (
    <Drawer
      destroyOnClose
      open={visible}
      onClose={onClose}
      maskClosable={false}
      footer={null}
      width={450}
      title={literals?.buttons?.add_new_user_btn}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "center",
          paddingTop: "5%",
          paddingBottom: "5%",
          width: "100%",
        }}
      >
        <Formfield
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <h1 style={{ color: "#06152B", fontSize: 16, fontWeight: 500 }}>
                {literals?.placeholder_text?.contact_name}
              </h1>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    border: "none",
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  // placeholder="Contact Name"
                  onChange={handleInputChange}
                  // suffix={
                  //   isEmpty ? (
                  //     <span
                  //       style={{
                  //         color: "red",
                  //         position: "absolute",
                  //         left: "28%",
                  //       }}
                  //     >
                  //       *
                  //     </span>
                  //   ) : null
                  // }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <h1 style={{ color: "#06152B", fontSize: 16, fontWeight: 500 }}>
                {literals?.placeholder_text?.email_address}
              </h1>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{
                    backgroundColor: "#F3F5F8",
                    border: "none",
                    width: "400px",
                  }}
                  autoComplete="off"
                  type="text"
                  onChange={handleInputChange}
                  // suffix={
                  //   isEmpty ? (
                  //     <span
                  //       style={{
                  //         color: "red",
                  //         position: "absolute",
                  //         left: "28%",
                  //       }}
                  //     >
                  //       *
                  //     </span>
                  //   ) : null
                  // }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <h1 style={{ color: "#06152B", fontSize: 16, fontWeight: 500 }}>
                {literals?.placeholder_text?.telephone}
              </h1>
              <Form.Item
                name="contact_number"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <PhoneInput
                  inputStyle={{
                    height: 44,
                    width: "400px",
                    backgroundColor: "#F3F5F8",
                    border: "none",
                  }}
                  country={"us"}
                  value={phoneNumber}
                  onChange={(text) => setPhoneNumber(text)}
                  className="boldPlaceholder"
                />
              </Form.Item>
              {/* <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
                >
                  <InputField
                    style={{ backgroundColor: "#F3F5F5" }}
                    autoComplete="off"
                    type="number"
                    placeholder="Telephone"
                    className="Placeholder"
                    onChange={handleInputChange}
                    suffix={
                      isEmpty ? (
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            left: "22%",
                          }}
                        >
                          *
                        </span>
                      ) : null
                    }
                  />
                </Form.Item> */}
            </Col>
            <Form.Item name="library">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    marginLeft: "2%",
                    fontSize: 16,
                  }}
                >
                  {literals?.placeholder_text?.enable_library_access}
                </p>
                <Switch
                  onChange={onChange}
                  style={{
                    backgroundColor: checked ? "rgb(58, 193, 203)" : "grey",
                  }}
                />
              </div>
            </Form.Item>
          </Row>
          <Form.Item>
            <Button
              htmlType="submit"
              className="login-form-button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                color: "#FFFFFF",
                borderStyle: "none",
                height: 40,
                paddingLeft: "8%",
                paddingRight: "8%",
                textTransform: "uppercase",
                borderRadius: 200,
              }}
            >
              <Logintext>{literals?.buttons?.save_btn}</Logintext>
            </Button>
          </Form.Item>
        </Formfield>
      </div>
    </Drawer>
  );
}
