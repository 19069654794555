import { Card, Layout, Menu, Spin, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import SubMenu from "antd/es/menu/SubMenu";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import image from "../assests/image.png";
import { PiTreeStructure } from "react-icons/pi";
import AnswerModal from "../components/AnswerModal";
import { Content } from "antd/es/layout/layout";
import MainHeader from "../components/MainHeader";
import AlertMessage from "../components/AlertMessage";
import success from "../../src/assests/Success.png";
import InstructionModal from "../components/InstructionModal";
import debounce from "lodash/debounce";
import _ from "lodash";
import { useLiterals } from "../context/LiteralsContext";
import { BsQuestionCircle } from "react-icons/bs";
import { ReactComponent as HomeIcon } from "../assests/home-active.svg";
import { ReactComponent as Dialogues } from "../assests/dialogues.svg";
import { ReactComponent as Library } from "../assests/library.svg";
import { ReactComponent as Collections } from "../assests/collections.svg";
import { ReactComponent as Settings } from "../assests/settings.svg";

const marks = {
  0: "0%",
  20: "20%",
  40: "40%",
  60: "60%",
  80: "80%",
  100: "100%",
};

export default function Cancel() {
  const literals = useLiterals();
  const [collapsed, setCollapsed] = useState(false);
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  const [temp, setTemp] = useState(() => {
    const storedTemp = localStorage.getItem("temp");
    return storedTemp !== null ? parseFloat(storedTemp) : 0.5;
  });
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isdialogVisible, setIsDialogModalVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const navigate = useNavigate();

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const handleOpenAnswerModal = () => {
    setAnswerModalVisible(true);
  };
  const handleCloseAnswerModal = () => {
    setAnswerModalVisible(false);
  };
  const handleOpenIsDialogModal = () => {
    setIsDialogModalVisible(true);
  };

  const handleCloseIsDailogModal = () => {
    setIsDialogModalVisible(false);
  };

  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };
  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };
  const primaryUser = localStorage.getItem("primaryUser");

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const handleSliderChange = (value) => {
    switch (value) {
      case 0:
        setTemp(1.0);
        break;
      case 20:
        setTemp(0.8);
        break;
      case 40:
        setTemp(0.6);
        break;
      case 60:
        setTemp(0.4);
        break;
      case 80:
        setTemp(0.2);
        break;
      default:
        setTemp(0.0);
    }
  };

  const userDataString = localStorage.getItem("userData");
  const userInfo = JSON.parse(userDataString);

  setTimeout(() => {
    navigate("/subscription", {
      state: { paymentStatus: "success" },
    });
  }, 2500);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {loading && <Spin fullscreen />}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{ maxWidth: "12%" }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={collapsed ? 55 : 95} alt="Logo" />
        </div>

        <Menu theme="light" mode="inline" onClick={() => navigate("/home")}>
          <Menu.Item key="1" icon={<HomeIcon />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu theme="light" mode="inline">
          <Menu.Item
            key="7"
            icon={<Dialogues />}
            onClick={() => navigate("/dialogue")}
          >
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>
        {userInfo?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
            >
              <Menu.Item key="5" icon={<Library />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
            >
              <Menu.Item key="6" icon={<Collections />}>
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}

        <Menu theme="light" mode="inline" defaultSelectedKeys={["3"]}>
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<Settings />}
          >
            {/* <Menu.Item
              key="2"
              icon={<GiSettingsKnobs />}
              onClick={handleOpenAnswerModal}
            >
              {literals?.sidebar?.answers_menu}
            </Menu.Item> */}
            {primaryUser === "true" && (
              <>
                <Menu.Item
                  key="1"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/company")}
                >
                  {literals?.buttons?.company_btn}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/contacts")}
                >
                  {literals?.buttons?.contacts_btn}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/subscription")}
                >
                  {literals?.sidebar?.subscription_menu}
                </Menu.Item>
              </>
            )}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            justifyContent: collapsed ? "center" : "flex-start",
            textDecoration: "none",
          }}
        >
          <BsQuestionCircle
            className="question"
            style={{ color: "#fff", fontSize: 22 }}
          />
          {!collapsed && (
            <p
              style={{
                fontSize: 15,
                fontWeight: 500,
                marginLeft: "1rem",
                marginTop: 15,
              }}
            >
              Help & Support
            </p>
          )}
        </Link>
      </Sider>
      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
          onClick={handleOpenhelpuserModal}
        />
        <Content
          style={{
            margin: "24px 16px",
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
        >
          <div>
            <AlertMessage currentPage={currentPage} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Card
              style={{
                paddingTop: 10,
                width: "40%",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                // margin: "20px auto",
                textAlign: "center",
              }}
            >
              <img src={success} alt="Success" width="50%" height="50%" />
              <p style={{ fontSize: "48px", fontWeight: 600, opacity: "80%" }}>
                Payment Success
              </p>
              {/* <p style={{ fontSize: "48px", fontWeight: 600 }}>$ 3,000</p> */}
            </Card>
          </div>
        </Content>
      </Layout>
      <AnswerModal
        visible={answerModalVisible}
        onClose={handleCloseAnswerModal}
        marks={marks}
        handleSliderChange={handleSliderChange}
        temp={temp}
      />
      <InstructionModal
        visible={helpModalVisible}
        onClose={handleClosehelpuserModal}
      />
    </Layout>
  );
}
