import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Protected(props) {
  const navigate = useNavigate();
  const { Component } = props;
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  // const blockedUser = userData.blocked === true;

  useEffect(() => {
    if (
      !userData ||
      userData?.user_status?.blocked === true ||
      userData?.user_status?.removed === true
    ) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Component />;
}
