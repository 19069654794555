import React from "react";
import {
  ChooseLang,
  CopyRight,
  MainContainer,
  Nav,
  RightContainer,
  Signin,
  Signintext,
} from "../styles/Styles";
import FormBanner from "../components/FormBanner";
import FormHeader from "../components/FormHeader";
import image from "../assests/image.png";
import SetPasswordForm from "../components/SetPasswordForm";
import { useLiterals } from "../context/LiteralsContext";
import LanguageSelect from "../components/LanguageSelect";

export default function SetPassword() {
  const literals = useLiterals();

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  return (
    <div>
      <MainContainer>
        <FormBanner />
        <ChooseLang
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            right: 0,
            top: 0,
            margin: 20,
          }}
        >
          <LanguageSelect width="100%" />
        </ChooseLang>
        <RightContainer>
          <div
            style={{
              marginTop: "10%",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormHeader
              image={image}
              title="Create Password"
              subTitle="Please create a new password for your account"
            />
            <SetPasswordForm userData={userData} literals={literals} />
            <Signin>
              <Signintext>
                {literals?.placeholder_text?.don_t_have_an_account}
              </Signintext>
              <Nav to="/signup">{literals?.buttons?.signup_btn}</Nav>
            </Signin>
          </div>
          <CopyRight>{literals?.email_content?.copyrights}</CopyRight>
        </RightContainer>
      </MainContainer>
    </div>
  );
}
