import React, { useEffect, useState } from "react";
import { useLiterals } from "../context/LiteralsContext";
import { fetchWelcomeMsgData } from "../context/constants";
import {
  Buttonfield,
  CopyRight,
  Logintext,
  Signin,
  SubTitle,
  Title,
  ChooseLang,
} from "../styles/Styles";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import ReactHtmlParser from "react-html-parser";
import BG from "../assests/bg.png";
import BG2 from "../assests/BG2.png";
import image from "../assests/image.png";
import { Link, useNavigate } from "react-router-dom";
import LanguageSelect from "../components/LanguageSelect";
import "../index.css";

export default function Welcome() {
  const [welcomeData, setWelcomeData] = useState(null);
  const [isLeaving, setIsLeaving] = useState(false);

  const literals = useLiterals();
  const navigate = useNavigate();

  let locale = localStorage.getItem("locale");

  useEffect(() => {
    if (!locale) {
      locale = "en-us";
      localStorage.setItem("locale", locale);
    }

    const fetchData = async () => {
      try {
        const response = await fetchWelcomeMsgData(locale);
        setWelcomeData(response);
      } catch (error) {
        console.error("Error fetching welcome message:", error);
      }
    };

    fetchData();
  }, [locale]);

  const handleLoginClick = () => {
    setIsLeaving(true);
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  return (
    <div
      className={isLeaving ? "slide-left-leave" : "slide-left-enter"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        paddingLeft: "6%",
        paddingRight: "3%",
        paddingTop: "5%",
        paddingBottom: "1%",
        backgroundImage: `url(${BG2})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        <img
          alt="chemaid-logo"
          style={{ width: "45%", height: "45%", objectFit: "contain" }}
          src={image}
        />
        <ChooseLang
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            right: 0,
            top: 0,
            margin: 20,
          }}
          className="screen-lang-select"
        >
          <LanguageSelect width="100%" className="welcome-page-lang-select" />
        </ChooseLang>
      </div>
      <div style={{ textAlign: "center" }}>
        {welcomeData &&
          welcomeData.data &&
          welcomeData.data.all_welcome &&
          welcomeData.data.all_welcome.items &&
          welcomeData.data.all_welcome.items.length > 0 && (
            <>
              <Title>{welcomeData.data.all_welcome.items[0].title}</Title>
              <SubTitle>
                {ReactHtmlParser(
                  jsonToHtml(welcomeData.data.all_welcome.items[0].message.json)
                )}
              </SubTitle>
            </>
          )}
      </div>
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <Buttonfield
          onClick={handleLoginClick}
          // disabled={loading ? true : false}
          className="login-form-button"
          block
          style={{
            backgroundColor: "white",
            color: "#14222F",
            marginBottom: 20,
          }}
        >
          <Logintext>{literals?.buttons?.login_btn}</Logintext>
        </Buttonfield>
        <Signin>
          <p style={{ color: "white" }}>
            {literals?.placeholder_text?.signin_info}
          </p>
          <Link
            style={{
              color: "white",
              textDecoration: "none",
              fontWeight: "600",
            }}
            to="/signup"
          >
            {literals?.buttons?.signup_btn}
          </Link>
        </Signin>
      </div>
      <CopyRight>
        {/* 2023 CHEM&#8226;AID<sup>TM</sup>&#9135; All rights reserved. */}
        {literals?.email_content?.copyrights}
      </CopyRight>
    </div>
  );
}
