import { Button, Col, Form, Modal, Row, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Formfield, InputField, Logintext } from "../styles/Styles";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";
import debounce from "lodash/debounce";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

export default function CreateFolder({
  visible,
  onClose,
  onAddFolder,
  parentUid,
  apiKey,
  authToken,
  literals,
}) {
  const [form] = Form.useForm();
  const [folderData, setFolderData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [folderCreated, setFolderCreated] = useState(false);
  const [folderNameAlreadyExists, setFolderNameAlreadyExists] = useState(false);

  const handleOpenFolderCreated = () => {
    setFolderCreated(true);
  };
  const handleCloseFolderCreated = () => {
    setFolderCreated(false);
  };

  const handleOpenFolderNameAlreadyExists = () => {
    setFolderNameAlreadyExists(true);
  };
  const handleCloseFolderNameAlreadyExists = () => {
    setFolderNameAlreadyExists(false);
  };

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const createFolder = async () => {
    setLoading(true);
    try {
      const endPoint = "https://query3.azurewebsites.net/api/create-folder";

      const payload = {
        folder_name: inputValue,
        parent_uid: parentUid,
      };

      const headers = {
        api_key: apiKey,
        auth_token: authToken,
      };

      await axios.post(endPoint, payload, { headers });

      form.resetFields();
      setInputValue("");
      onClose();
      setLoading(false);
      // showToast("Folder created successfully", "success");
      // showToast(
      //   `${literals?.toast_messages_text?.folder_created_successfully}`,
      //   "success"
      // );
      handleOpenFolderCreated();
    } catch (error) {
      // showToast("Folder name already exists!", "error");
      // showToast(
      //   `${literals?.toast_messages_text?.folder_name_already_exists}`,
      //   "error"
      // );
      handleOpenFolderNameAlreadyExists();
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const modalTitle = parentUid
    ? `${literals?.library_page?.create_subfolder_title}`
    : `${literals?.library_page?.create_folder_title}`;

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      maskClosable={false}
      footer={null}
      width={600}
      centered
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Formfield form={form} onFinish={createFolder}>
          <p
            style={{
              fontSize: 22,
              fontWeight: 500,
              color: "#1B1B1B",
              // opacity: "60%",
              textAlign: "center",
            }}
          >
            {modalTitle}
          </p>
          <Row gutter={[16, 0]}>
            {/* <Col span={24}>
              <Form.Item name="folder_dropdown">
                <Select
                  style={{
                    width: "100%",
                    height: "40px",
                  }}
                  placeholder="Select parent folder"
                  options={[
                    {
                      value: "Ava Swift",
                      label: "Ava Swift",
                    },
                    {
                      value: "Cole Reed",
                      label: "Cole Reed",
                    },
                    {
                      value: "Mia Blake",
                      label: "Mia Blake",
                    },
                    {
                      value: "Jake Stone",
                      label: "Jake Stone",
                    },
                    {
                      value: "Lily Lane",
                      label: "Lily Lane",
                    },
                  ]}
                />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                name="folder_name"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{ backgroundColor: "#F3F5F5" }}
                  autoComplete="off"
                  type="text"
                  placeholder={literals?.modal_literals?.enter_folder_name}
                  className="Placeholder"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffffff",
                  borderColor: "#2B8E96",
                  color: "#1B1B1B",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  paddingLeft: "30%",
                  paddingRight: "30%",
                  textTransform: "uppercase",
                  borderRadius: "200px",
                }}
                onClick={onClose}
              >
                <Logintext>{literals?.buttons?.cancel_btn}</Logintext>
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                  color: "#FFFFFF",
                  borderStyle: "none",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  paddingLeft: "30%",
                  paddingRight: "30%",

                  textTransform: "uppercase",
                  borderRadius: "200px",
                }}
              >
                <Logintext>{literals?.buttons?.create_btn}</Logintext>
              </Button>
            </Form.Item>
          </div>
        </Formfield>
        {loading && <Spin fullscreen />}
      </div>
      <SuccessModal
        open={folderCreated}
        onCancel={handleCloseFolderCreated}
        message={literals?.toast_messages_text?.folder_created_successfully}
      />
      <ErrorModal
        open={folderNameAlreadyExists}
        onCancel={handleCloseFolderNameAlreadyExists}
        message={literals?.toast_messages_text?.folder_name_already_exists}
      />
    </Modal>
  );
}
