import { Button, Col, Form, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import { Formfield, InputField, Logintext } from "../styles/Styles";
import axios from "axios";
import { getContentEntriesEndpoint } from "../context/constants";
import { ToastContainer, toast } from "react-toastify";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

export default function CreateCollection({
  visible,
  onClose,
  onAddCollection,
  literals,
  apiKey,
  authToken,
}) {
  // const [dropdownVisible, setDropdownVisible] = useState(false);
  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [collectionCreated, setCollectionCreated] = useState(false);
  const [collectionNameAlreadyExists, setCollectionNameAlreadyExists] =
    useState(false);

  const handleOpenCollectionCreated = () => {
    setCollectionCreated(true);
  };
  const handleCloseCollectionCreated = () => {
    setCollectionCreated(false);
  };

  const handleOpenCollectionNameAlreadyExists = () => {
    setCollectionNameAlreadyExists(true);
  };
  const handleCloseCollectionNameAlreadyExists = () => {
    setCollectionNameAlreadyExists(false);
  };

  const locale = localStorage.getItem("locale");

  // const handleSelect = () => {
  //   setDropdownVisible(false);
  // };

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const createCollection = async () => {
    setLoading(true);
    try {
      // const documents = values.folder_dropdown
      //   .map((filename) => {
      //     const selectedItem = options.find(
      //       (item) => item.filename === filename
      //     );
      //     return selectedItem
      //       ? `${selectedItem.system.uid}•${selectedItem.filename}`
      //       : null;
      //   })
      //   .filter((item) => item !== null);

      const contentType = "collections";
      const endpoint = getContentEntriesEndpoint(contentType, locale);
      const headers = {
        api_key: apiKey,
        authorization: authToken,
      };
      const payload = {
        entry: {
          title: inputValue,
          upload_email: userData.title,
          stack_uid: apiKey,
          stack_auth: authToken,
        },
      };
      const response = await axios.post(endpoint, payload, { headers });
      const newCollection = response.data;
      onAddCollection(newCollection.entry);
      form.resetFields();
      setInputValue("");
      onClose();
      setLoading(false);
      // toast.success("Collection created successfully!");
      // toast.success(
      //   `${literals?.toast_messages_text?.collection_created_successfully}`
      // );
      handleOpenCollectionCreated();
    } catch (error) {
      // toast.error("Collection name already exists!");
      // toast.error(
      //   `${literals?.toast_messages_text?.collection_name_already_exists}`
      // );
      handleOpenCollectionNameAlreadyExists();
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCancel = () => {
    form.resetFields();
    setInputValue("");
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      maskClosable={false}
      footer={null}
      width={600}
      centered
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
      <SuccessModal
        open={collectionCreated}
        onCancel={handleCloseCollectionCreated}
        message={literals?.toast_messages_text?.collection_created_successfully}
      />
      <ErrorModal
        open={collectionNameAlreadyExists}
        onCancel={handleCloseCollectionNameAlreadyExists}
        message={literals?.toast_messages_text?.collection_name_already_exists}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Formfield form={form} onFinish={createCollection}>
          <p
            style={{
              fontSize: 22,
              fontWeight: 500,
              color: "#1B1B1B",
              // opacity: "60%",
              textAlign: "center",
            }}
          >
            {literals?.buttons?.create_collection_btn}
          </p>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                name="collection_name"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <InputField
                  style={{ backgroundColor: "#F3F5F5", height: "35px" }}
                  autoComplete="off"
                  type="text"
                  // placeholder="Enter collection name"
                  placeholder={literals?.modal_literals?.enter_collection_name}
                  className="Placeholder"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item name="folder_dropdown">
                <Select
                  mode="multiple"
                  // style={{
                  //   width: "100%",
                  //   height: "40px",
                  // }}
                  style={{
                    width: "100%",
                    maxHeight: "400px", // Set a maximum height for the dropdown
                    overflowY: "auto", // Enable vertical scrolling if needed
                  }}
                  // maxTagCount={2}
                  placeholder="Select document"
                  options={options.map((item) => ({
                    value: item.filename,
                    label: item.filename,
                  }))}
                  onSelect={handleSelect} // Close the dropdown after an option is selected
                  onDropdownVisibleChange={(open) => setDropdownVisible(open)} // Control the visibility of the dropdown
                  open={dropdownVisible} // Set the visibility of the dropdown
                  dropdownMatchSelectWidth={false}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffffff",
                  borderColor: "#2B8E96",
                  color: "#1B1B1B",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  paddingLeft: "30%",
                  paddingRight: "30%",
                  textTransform: "uppercase",
                  borderRadius: "200px",
                }}
                onClick={onClose}
              >
                <Logintext>{literals?.buttons?.cancel_btn}</Logintext>
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                  color: "#FFFFFF",
                  borderStyle: "none",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  paddingLeft: "30%",
                  paddingRight: "30%",
                  textTransform: "uppercase",
                  borderRadius: "200px",
                }}
              >
                <Logintext>{literals?.buttons?.create_btn}</Logintext>
              </Button>
            </Form.Item>
          </div>
        </Formfield>
        {loading && <Spin fullscreen />}
      </div>
    </Modal>
  );
}
