import { Button, Card, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import Link from "antd/es/typography/Link";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import environmental from "../assests/environmental_icon 1.png";
import health from "../assests/Health_icon 1.png";
import safety from "../assests/Safety_icon 1.png";
import sustainability from "../assests/Sustainability_icon 1.png";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function Categories({
  selectedCategory,
  categories,
  setSelectedSubCategory,
  selectedSubCategory,
  setSelectCategoryAndResetPage,
  setSubCategories,
  subCategories,
  literals,
}) {
  const [dropdownOpen, setDropdownOpen] = useState({
    environment: false,
    health: false,
    safety: false,
    esg: false,
  });
  const [lastSelectedCategory, setLastSelectedCategory] = useState(null);

  const handleDropdownVisibleChange = (categoryUid, visible) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [categoryUid]: visible,
    }));
    if (!visible && categoryUid === lastSelectedCategory) {
      setSelectedSubCategory(null);
    }
  };

  useEffect(() => {
    setSelectedSubCategory(null);
  }, [selectedCategory]);

  function getIcon(categoryName) {
    switch (categoryName) {
      case "Environment":
      case "Meio Ambiente":
        return environmental;
      case "Health":
      case "Saúde":
        return health;
      case "Safety":
      case "Segurança":
        return safety;
      case "Sustainability":
      case "Sustentabilidade":
        return sustainability;
      default:
        return null;
    }
  }

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory.uid);
    setSubCategories(subCategory.name);
    setDropdownOpen((prevState) => ({
      ...prevState,
      [selectedCategory]: false,
    }));
  };

  const handleClearSubCategory = () => {
    setSelectedSubCategory(null);
    setSubCategories(null);
    setDropdownOpen((prevState) => ({
      ...prevState,
      [selectedCategory]: false,
    }));
    setLastSelectedCategory(selectedCategory);
  };

  // useEffect(() => {
  //   if (!selectedCategory) {
  //     setSelectCategoryAndResetPage("all");
  //   }
  // }, [selectedCategory]);
  // if (isLoading) {
  //   return (
  //     <>
  //       <Spin size="large" fullscreen />
  //     </>
  //   );
  // }

  const handleAllArticlesClick = () => {
    setSelectCategoryAndResetPage(null);
    setSelectedSubCategory(null);
    setSubCategories(null);
    setDropdownOpen({
      environment: false,
      health: false,
      safety: false,
      esg: false,
    });
  };

  return (
    <div>
      <p
        style={{
          fontSize: 20,
          fontWeight: 700,
          color: "#06152B",
          marginLeft: 40,
          marginTop: 10,
        }}
      >
        Featured Articles
      </p>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 100,
          width: "53%",
          maxHeight: 55,
          display: "flex",
          alignItems: "center",
          marginLeft: 40,
          paddingLeft: 20,
          paddingRight: 10,
        }}
      >
        <Link
          onClick={handleAllArticlesClick}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            whiteSpace: "nowrap",
            color: "#06152B",
            backgroundColor: `${
              selectedCategory === null ? "#F3F5F8" : "#fff"
            }`,
            borderRadius: "200px",
            marginTop: "17px",
            marginBottom: "16px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Icon
            icon="icon-park-outline:all-application"
            color="#4F625C"
            style={{ fontSize: 17 }}
          />
          <p
            style={{
              fontWeight: 500,
            }}
          >
            All Articles
          </p>
        </Link>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {categories &&
            categories.length > 0 &&
            categories.map((category, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  whiteSpace: "nowrap",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  onClick={() =>
                    setSelectCategoryAndResetPage(`${category.uid}`)
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                >
                  <Card
                    className="facility_wrapper"
                    style={{
                      backgroundColor:
                        selectedCategory === `${category.uid}` ? "#F3F5F8" : "",
                      padding: "10px",
                      borderRadius: "200px",
                    }}
                  >
                    <div
                      className="text-center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        flexDirection: "row",
                        gap: 5,
                      }}
                    >
                      <img
                        alt={category.name}
                        src={getIcon(category.name)}
                        className="card_img"
                        height={24}
                        width={24}
                      />
                      <h2
                        style={{
                          fontSize: 14,
                          margin: 0,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {category.name}
                        <Dropdown
                          style={{ backgroundColor: "white" }}
                          menu={{
                            items: [
                              ...category.child.map((subCategory) => ({
                                key: subCategory.uid,
                                label: (
                                  <>
                                    <span
                                      style={{
                                        // marginBottom: 5,
                                        fontSize: 14,
                                        fontWeight: 500,
                                        backgroundColor:
                                          selectedSubCategory ===
                                          subCategory.uid
                                            ? "#36B2BC"
                                            : "#fff",
                                        color:
                                          selectedSubCategory ===
                                          subCategory.uid
                                            ? "#fff"
                                            : "#000",
                                        borderRadius: "8px",
                                        display: "block",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {subCategory.name}
                                    </span>
                                    <div
                                      style={{
                                        height: 1,
                                        width: "100%",
                                        backgroundColor: "#000",
                                        opacity: "10%",
                                        borderRadius: 20,
                                      }}
                                    />
                                  </>
                                ),
                                onClick: () =>
                                  handleSubCategorySelect(subCategory),
                              })),
                              {
                                key: "heading",
                                label: (
                                  <Button
                                    style={{
                                      backgroundColor: "#fff",
                                      textTransform: "capitalize",
                                      fontSize: 14,
                                      fontWeight: 600,
                                      borderColor: "#fff",
                                      color: "#3AC1CB",
                                      boxShadow: "none",
                                      marginTop: 5,
                                      marginBottom: 5,
                                      marginLeft: -10,
                                    }}
                                    onClick={handleClearSubCategory}
                                  >
                                    {literals?.buttons?.clear_btn}
                                  </Button>
                                ),
                                disabled: true,
                              },
                            ],
                            backgroundColor: "white",
                          }}
                          trigger={["click"]}
                          visible={dropdownOpen[category.uid]}
                          onVisibleChange={(visible) =>
                            handleDropdownVisibleChange(category.uid, visible)
                          }
                        >
                          <a
                            href="/"
                            onClick={(e) => e.preventDefault()}
                            style={{
                              color: `${
                                selectedCategory === category.uid
                                  ? "#548235"
                                  : "#548235"
                              }`,
                            }}
                          >
                            {dropdownOpen[category.uid] ? (
                              <IoIosArrowUp size={20} />
                            ) : (
                              <IoIosArrowDown size={20} />
                            )}
                          </a>
                        </Dropdown>
                      </h2>
                    </div>
                  </Card>
                </Link>
                {/* {selectedSubCategory && selectedCategory === category.uid && (
                  <div className="text-center">
                    <p
                      style={{
                        backgroundColor: "#DCF0EC",
                        borderRadius: "8px",
                        padding: 10,
                      }}
                    >
                      {subCategories}
                    </p>
                  </div>
                )} */}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
