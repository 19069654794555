import React from "react";
import { Button, Form } from "antd";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formfield, InputField, Logintext } from "../styles/Styles";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
} from "../context/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { jsonToHtml } from "@contentstack/json-rte-serializer";

export default function VerifyForm({
  emailPattern,
  literals,
  loading,
  setLoading,
  email,
}) {
  const navigate = useNavigate();
  const locale = localStorage.getItem("locale");

  const verifyToken = async (values) => {
    setLoading(true);
    try {
      const content_type = "application_user";
      const endpoint = getContentEntriesEndpoint(content_type, locale);
      const headers = EnteriesHeaders;

      const res = await axios.get(endpoint, { headers });
      const verifiedUser = res.data.entries.filter(
        (user) => user.title === email
      );

      const verified = values.token === verifiedUser[0].uid;

      console.log("===>", verified);

      if (verified) {
        navigate("/reset");
      }
      setLoading(false);
      // setFilteredUser(verifiedUser)
    } catch (error) {
      console.log("Error verifing token", error);
    }
  };

  return (
    <>
      <Formfield
        name="normal_login"
        className="login-form"
        initial={{
          remember: true,
        }}
        emailPattern={emailPattern}
        onFinish={verifyToken}
      >
        <Form.Item
          name="token"
          rules={[
            {
              required: true,
              message: "Please enter your valid token!",
            },
          ]}
        >
          <InputField
            autoComplete="off"
            placeholder="Enter Token"
            className="boldPlaceholder"
          />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={loading ? true : false}
            htmlType="submit"
            className="login-form-button"
            block
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: loading
                ? "#BEC8C8"
                : "linear-gradient(180deg, #3AC1CB 0%, #1D6065 150%)",
              color: "#FFFFFF",
              borderStyle: "none",
              paddingTop: "5%",
              paddingBottom: "5%",
              textTransform: "uppercase",
              borderRadius: "50px",
            }}
          >
            <Logintext>
              {literals?.buttons?.submit}
              {!loading ? (
                <></>
              ) : (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </Logintext>
          </Button>
        </Form.Item>
      </Formfield>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
    </>
  );
}
