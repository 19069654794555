import { Button, Input, Layout, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { BsBoxArrowRight, BsQuestionCircle } from "react-icons/bs";
import { PiUserCircleLight } from "react-icons/pi";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import Link from "antd/es/typography/Link";
import { resetUserData } from "../redux/actions/userAction";
import SearchResultsModal from "./SearchResultsModal";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import _ from "lodash";
import LanguageSelect from "./LanguageSelect";
import { useLiterals } from "../context/LiteralsContext";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

export default function MainHeader({
  collapsed,
  handleToggleCollapsed,
  onClick,
  visible,
  onClose,
  currentPage,
  onPageChange,
  clickedCardIndex,
  handleOpenModal,
}) {
  const literals = useLiterals();
  const { Header } = Layout;
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [searchValue, setSearchValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const debouncedToast = useCallback(
    debounce((message, type) => {
      toast(message, { type });
    }, 300),
    []
  );

  useEffect(() => {
    if (toastMessage) {
      debouncedToast(toastMessage.message, toastMessage.type);
      setToastMessage(null);
    }
  }, [toastMessage, debouncedToast]);

  const showToast = (message, type) => {
    if (!toastMessage) {
      setToastMessage({ message, type });
    }
  };

  const handleLogout = () => {
    dispatch(resetUserData(user));
    localStorage.removeItem("userData");
    localStorage.removeItem("primaryUser");
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter" && searchValue.trim() !== "") {
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
    setSearchValue("");
    handleSearchArticle();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // useEffect(() => {
  //   const fetchSearchResults = async () => {
  //     try {
  //       if (searchValue.trim().length >= 3) {
  //         const headers = CDNHeaders;
  //         const res = await axios.get(
  //           'https://cdn.contentstack.io/v3/content_types/articles/entries?query=&limit=20&sort={"date":"desc"}',
  //           { headers }
  //         );
  //         // console.log("Response Data:", res.data);
  //         const filteredResults = res.data.entries.filter((entry) =>
  //           entry.title
  //             .trim()
  //             .toLowerCase()
  //             .includes(searchValue.trim().toLowerCase())
  //         );
  //         setSearchResults(filteredResults);
  //         // console.log("filteredResults", filteredResults);
  //         // if (filteredResults.length > 0) {
  //         //   console.log("Matching Entry Title:", filteredResults[0].title);
  //         // } else {
  //         //   console.log("No matching entry found.");
  //         // }
  //       } else {
  //         console.log("Search query should be at least 3 characters long.");
  //       }
  //     } catch (error) {
  //       toast.error("Error fetching search results:", error);
  //     }
  //   };
  //   fetchSearchResults();
  // }, [searchValue]);

  const handleSearchArticle = async () => {
    setLoading(true);
    try {
      const endpoint = "https://query3.azurewebsites.net/api/search";
      const payload = {
        question: searchValue,
        k: "10",
        index_name: "articles-chemaid",
        namespace: "Default",
        llm: "gpt-4",
        score: "0.75",
      };
      const res = await axios.post(endpoint, payload);

      setSearchResults(res.data);
      setLoading(false);
    } catch (error) {
      showToast(
        `${literals?.toast_messages_text?.error_searching_article}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Header
      style={{
        background: colorBgContainer,
      }}
    >
      <div
        style={{
          background: "linear-gradient(90deg, #3AC1CB 0%, #1D6065 150%)",
          borderRadius: "50%",
          width: 32,
          height: 32,
          display: "flex",
          marginTop: "1%",
          marginLeft: collapsed ? "-3%" : "-4.4%",
          position: "absolute",
          zIndex: 1,
        }}
      >
        <Button
          type="text"
          icon={
            collapsed ? (
              <IoIosArrowForward onClick={handleToggleCollapsed} />
            ) : (
              <IoIosArrowBack onClick={handleToggleCollapsed} />
            )
          }
          onClick={handleToggleCollapsed}
          style={{
            fontSize: "16px",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
      <div className="header_wrapper">
        <div
          className="d-flex align-items-center"
          style={{
            width: "100%",
          }}
        >
          <Input
            type="text"
            className="search"
            placeholder={literals?.placeholder_text?.search_articles}
            prefix={
              <CiSearch
                className="search_icon"
                onClick={showModal}
                style={{ cursor: "pointer" }}
              />
            }
            onChange={handleSearchChange}
            value={searchValue}
            onPressEnter={showModal}
            onKeyPress={handleSearchKeyPress}
          />
          {/* <Link onClick={onClick}>
            <BsQuestionCircle className="question me-3" />
          </Link> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 20,
            width: "70%",
            marginRight: "-2%",
          }}
        >
          <LanguageSelect width="28%" />
          <Link
            href=""
            className="user_profile"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <PiUserCircleLight className="user" />
            <div>
              <h6 style={{ fontSize: 14, marginBottom: 0 }}>
                {userData?.user_name}
              </h6>
              <p
                style={{
                  textDecoration: "none",
                  color: "#0C0D16",
                  opacity: "70%",
                  fontSize: 12,
                  padding: 0,
                }}
              >
                {userData?.company_name}
              </p>
            </div>
          </Link>

          <Link
            href="/"
            onClick={handleLogout}
            style={{
              backgroundColor: "#192E3C",
              borderRadius: "25%",
              padding: "2%",
            }}
          >
            <RiLogoutCircleRLine
              className="log_out"
              size={14}
              style={{ color: "#fff", display: "flex", alignItems: "center" }}
            />
          </Link>
        </div>
      </div>

      <SearchResultsModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        searchArticles={searchResults}
        visible={visible}
        onClose={onClose}
        currentPage={currentPage}
        onPageChange={onPageChange}
        clickedCardIndex={clickedCardIndex}
        handleOpenModal={handleOpenModal}
        loading={loading}
        literals={literals}
      />
    </Header>
  );
}
