import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal } from "antd";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Buttonfield,
  ForgotToken,
  Formfield,
  InputField,
  Logintext,
} from "../styles/Styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../redux/actions/userAction";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
  updateContentEntriesEndpoint,
} from "../context/constants";
import bcrypt from "bcryptjs";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

export default function SignInForm({
  emailPattern,
  literals,
  categories,
  isLoading,
}) {
  const [loading, setLoading] = useState(false);
  const [invalidAttempts, setInvalidAttempts] = useState(0);
  const [ipAddress, setIpAddress] = useState("");
  const [passwordExists, setPasswordExists] = useState(true);
  const [values, setValues] = useState({ title: "", token: "" });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [attemptModal, setAttemptModal] = useState(false);
  const [passwordivModal, setPasswordivModal] = useState(false);
  const [invalidEmail, setInvalidEmailModal] = useState(false);
  const [userNotFound, setUserNotFoundModal] = useState(false);
  const [issue, setIssueModal] = useState(false);

  const handleOpenSuccessModal = () => {
    setSuccessModal(true);
  };
  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleOpenErrorModal = () => {
    setErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModal(false);
  };

  const handleOpenAttemptModal = () => {
    setAttemptModal(true);
  };
  const handleCloseAttemptModal = () => {
    setAttemptModal(false);
  };

  const handleOpenPasswordivModal = () => {
    setPasswordivModal(true);
  };
  const handleClosePasswordivModal = () => {
    setPasswordivModal(false);
  };

  const handleOpenInvalidEmailModal = () => {
    setInvalidEmailModal(true);
  };
  const handleCloseInvalidEmailModal = () => {
    setInvalidEmailModal(false);
  };

  const handleOpenUserNotFoundModal = () => {
    setUserNotFoundModal(true);
  };
  const handleCloseUserNotFoundModal = () => {
    setUserNotFoundModal(false);
  };

  const handleOpenIssue = () => {
    setIssueModal(true);
  };
  const handleCloseIssue = () => {
    setIssueModal(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const locale = localStorage.getItem("locale");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();
  }, [ipAddress]);

  useEffect(() => {
    if (values.title && values.title.trim() !== "") {
      checkPasswordExists(values);
    }
  }, [values, locale]);

  const handleEmailChange = async (e) => {
    const { value } = e.target;
    setValues((prevValues) => ({ ...prevValues, title: value }));
  };

  const handleTokenChange = async (e) => {
    const { value } = e.target;
    setValues((prevValues) => ({ ...prevValues, token: value }));
  };

  const checkPasswordExists = async (values) => {
    try {
      const { title } = values;
      const content_type = "application_user";
      const headers = EnteriesHeaders;
      const loginEndpoint = getContentEntriesEndpoint(content_type, locale);
      const response = await axios.get(loginEndpoint, { headers });
      const data = response.data.entries;
      const foundUser = data.find((item) => item.title === title);
      if (foundUser) {
        setPasswordExists(!!foundUser.encrypted_password);
      } else {
        setPasswordExists(false);
      }
      if (foundUser && typeof foundUser.encrypted_password === "undefined") {
        foundUser.encrypted_password = "";
      }
      // if (foundUser && foundUser.encrypted_password !== "") {
      //   setPasswordExists(true);
      // } else {
      //   setPasswordExists(false);
      // }
    } catch (error) {
      console.error("Error checking password existence:", error);
    }
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSignin = async (values) => {
    setLoading(true);
    const content_type = "application_user";
    try {
      const loginEndpoint = getContentEntriesEndpoint(content_type, locale);
      const headers = EnteriesHeaders;
      const response = await axios.get(loginEndpoint, { headers });
      const loginData = response.data.entries;
      const foundUser = loginData.find((item) => {
        return (
          (passwordExists &&
            item.encrypted_password &&
            bcrypt.compareSync(
              values.encrypted_password,
              item.encrypted_password
            )) ||
          (!passwordExists && item.uid === values.token)
        );
      });
      if (foundUser) {
        if (foundUser.blocked) {
          handleOpenErrorModal();
        } else {
          setInvalidAttempts(0);

          handleOpenSuccessModal();

          dispatch(setUserData(foundUser));
          localStorage.setItem("userData", JSON.stringify(foundUser));
          const uniqueId = generateRandomString(8);
          const payload = {
            entry: {
              title: uniqueId,
              user_name: values.title,
              user_ip: ipAddress,
            },
          };
          const visitLogCT = "visit_log";
          const VisitLogEndpoint = getContentEntriesEndpoint(
            visitLogCT,
            locale
          );
          await axios.post(VisitLogEndpoint, payload, { headers });
        }
        if (passwordExists) {
          setTimeout(() => {
            navigate("/home");
          }, 1500);
        } else {
          setTimeout(() => {
            navigate("/setPassword");
          }, 1500);
        }
      } else {
        const invalidEmail = loginData.find(
          (item) => item.uid === values.token && item.title !== values.title
        );
        const invalidToken = loginData.find(
          (item) => item.uid !== values.token && item.title === values.title
        );
        const invalidPassword = loginData.find(
          (item) =>
            item.title === values.title &&
            item.encrypted_password &&
            bcrypt.compareSync(
              values.encrypted_password,
              item.encrypted_password
            )
        );
        if (invalidToken || invalidPassword) {
          setInvalidAttempts((prevAttempts) => prevAttempts + 1);
          if (invalidAttempts >= 4) {
            handleOpenAttemptModal();
            const blockedUser = loginData.find(
              (item) => item.title === values.title
            );
            if (blockedUser) {
              const payload = {
                entry: {
                  user_status: {
                    blocked: (blockedUser.blocked = true),
                  },
                },
              };
              const bUserId = blockedUser.uid;
              const UpdateUserEndpoint = updateContentEntriesEndpoint(
                content_type,
                bUserId,
                locale
              );
              await axios.put(UpdateUserEndpoint, payload, { headers });
            }
            return;
          } else {
            handleOpenPasswordivModal();
          }
        } else {
          if (invalidEmail) {
            handleOpenInvalidEmailModal();
          } else {
            handleOpenUserNotFoundModal();
          }
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
      handleOpenIssue();
      handleOpenUserNotFoundModal();
    } finally {
      setLoading(false);
    }
  };

  const forgotToken = () => {
    // if(passwordExists){
    //   navigate("/setPassword")
    // }else{
    navigate("/forget", { state: { passwordExists } });
    // }
  };

  return (
    <>
      <Formfield
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={handleSignin}
        emailPattern={emailPattern}
      >
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: "Please enter your email!",
            },
            {
              type: "email",
              message: "This email is invalid!",
            },
          ]}
        >
          <InputField
            autoComplete="off"
            // prefix={
            //   <FontAwesomeIcon icon={faEnvelope} style={{ color: "#BBBBBB" }} />
            // }
            type="email"
            placeholder={literals?.placeholder_text?.email_address}
            className="boldPlaceholder"
            onChange={handleEmailChange}
          />
        </Form.Item>

        <Form.Item
          name={passwordExists ? "encrypted_password" : "token"}
          rules={[
            {
              required: true,
              message: `Please enter your ${
                passwordExists ? "Password" : "Token"
              }!`,
            },
          ]}
        >
          <InputField
            autoComplete="off"
            // prefix={
            //   <FontAwesomeIcon icon={faLock} style={{ color: "#BBBBBB" }} />
            // }
            type={passwordVisible ? "text" : "password"}
            placeholder={
              passwordExists
                ? `${literals?.placeholder_text?.enter_password_text}`
                : `${literals?.placeholder_text?.enter_token}`
            }
            className="boldPlaceholder"
            onChange={handleTokenChange}
            suffix={
              <FontAwesomeIcon
                icon={passwordVisible ? faEyeSlash : faEye}
                style={{ color: "#BBBBBB", cursor: "pointer" }}
                onClick={togglePasswordVisibility}
              />
            }
          />
        </Form.Item>

        <Form.Item>
          <ForgotToken
            onClick={forgotToken}
            onMouseOver={(e) => (e.currentTarget.style.color = "#1B1B1B")}
            onMouseOut={(e) => (e.currentTarget.style.color = "#1B1B1B")}
          >
            {passwordExists
              ? `${literals?.placeholder_text?.forgot_password_text}`
              : `${literals?.placeholder_text?.forgot_token}`}
          </ForgotToken>
        </Form.Item>

        <Form.Item>
          <Buttonfield
            disabled={loading ? true : false}
            htmlType="submit"
            className="login-form-button"
            block
            style={{
              background: loading
                ? "#BEC8C8"
                : "linear-gradient(180deg, #3AC1CB 0%, #1D6065 150%)",
              color: "#FFFFFF",
              borderRadius: "50px",
            }}
          >
            <Logintext>
              {literals?.buttons?.login_btn}
              {!loading ? (
                <></>
              ) : (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </Logintext>
          </Buttonfield>
        </Form.Item>
      </Formfield>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        pauseOnHover={false}
      />
      <SuccessModal
        open={successModal}
        onCancel={handleCloseSuccessModal}
        message={literals?.toast_messages_text?.user_logged_in_successfully}
      />
      <ErrorModal
        open={errorModal}
        onCancel={handleCloseErrorModal}
        message={
          literals?.toast_messages_text?.user_is_blocked_please_contact_support
        }
      />
      <ErrorModal
        open={attemptModal}
        onCancel={handleCloseAttemptModal}
        message={literals?.toast_messages_text?.invalid_attempts}
      />
      <ErrorModal
        open={passwordivModal}
        onCancel={handleClosePasswordivModal}
        message={`${passwordExists ? "Password" : "Token"} is invalid`}
      />
      <ErrorModal
        open={invalidEmail}
        onCancel={handleCloseInvalidEmailModal}
        message={literals?.toast_messages_text?.user_not_found_text}
      />
      <ErrorModal
        open={userNotFound}
        onCancel={handleCloseUserNotFoundModal}
        message={literals?.toast_messages_text?.user_not_found_text}
      />
      <ErrorModal
        open={issue}
        onCancel={handleCloseIssue}
        message={
          literals?.toast_messages_text
            ?.the_app_experienced_an_issue_during_the_login_process
        }
      />
    </>
  );
}
