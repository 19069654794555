import axios from "axios";

export const getContentEntriesEndpoint = (content_type, locale) => {
  return `https://api.contentStack.io/v3/content_types/${content_type}/entries?locale=${locale}`;
};

export const updateContentEntriesEndpoint = (
  content_type,
  content_uid,
  locale
) => {
  return `https://api.contentStack.io/v3/content_types/${content_type}/entries/${content_uid}?locale=${locale}`;
};

export const EnteriesHeaders = {
  api_key: "blt6f0f282d20bbe00b",
  authorization: "cs126192b9f72c7fcf7ec544c3",
};

export const CDNHeaders = {
  api_key: "blt6f0f282d20bbe00b",
  access_token: "cs534278b3e38a059d0cd52cdc",
};

export const AzureConnectionUrl =
  "endpoint=https://mutare-communication.unitedstates.communication.azure.com/;accesskey=w5Mih2Q6SSM/m4aIp5YvFdnpb45Lj/yrZEEfji7e8TYMcP2aCPh/vHSAj9vfrDAkjjsIBTBbgWtE1CjykdjqxQ==";

export const getTaxonomy = "https://query3.azurewebsites.net/api/get-taxonomy";

export const searchQuery = "https://query3.azurewebsites.net/api/query";

export const fetchAlertMessage = async (locale) => {
  try {
    const data = {
      query: `query AllTrueMessages($locale: String!) {
            all_messages(locale: $locale, where: {active: true}) {
              total
              items {
                active
                message_type
                message {
                  json
                }
              }
            }
          }`,
      variables: { entry_uid: "blte63b2ff6f6414d8e", locale },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://graphql.contentstack.com/stacks/blt6f0f282d20bbe00b?environment=prod",
      headers: {
        access_token: "cs534278b3e38a059d0cd52cdc",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    const response = await axios.request(config);
    return response.data.data.all_messages;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchWelcomeMsgData = async (locale) => {
  try {
    const data = {
      query: `query WelcomeMsg($locale: String!)  {
        all_welcome(where: {active: true}, locale: $locale) {
          items {
            title
            message {
              json
            }
          }
        }
      }`,
      variables: { entry_uid: "blte63b2ff6f6414d8e", locale },
    };
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://graphql.contentstack.com/stacks/blt6f0f282d20bbe00b?environment=prod",
      headers: {
        access_token: "cs534278b3e38a059d0cd52cdc",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchSignUpEmailData = async (locale) => {
  try {
    const data = {
      query: `query FetchEmailContents($locale: String!) {
        all_emails(locale: $locale, where: {select_type: "Sign Up"}) {
          total
          items {
            title
            select_type
            email_subject
            sender_email
            bcc
            email_message {
              json
            }
          }
        }
      }`,
      variables: { locale },
    };
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://graphql.contentstack.com/stacks/blt6f0f282d20bbe00b?environment=prod",
      headers: {
        access_token: "cs534278b3e38a059d0cd52cdc",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    const response = await axios.request(config);
    return response.data.data.all_emails.items[0];
  } catch (error) {
    console.error("Something went wrong!");
  }
};
