import { Button, Dropdown, Layout, Menu, Modal, Spin, Table } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import Link from "antd/es/typography/Link";
import React, { useEffect, useRef, useState } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import MainHeader from "../components/MainHeader";
import { useLiterals } from "../context/LiteralsContext";
import image from "../assests/image.png";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../assests/home-active.svg";
import { ReactComponent as Dialogues } from "../assests/dialogues.svg";
import { ReactComponent as Library } from "../assests/library.svg";
import { ReactComponent as Collections } from "../assests/collections.svg";
import { ReactComponent as Settings } from "../assests/settings.svg";
import {
  AzureConnectionUrl,
  EnteriesHeaders,
  fetchSignUpEmailData,
  getContentEntriesEndpoint,
  updateContentEntriesEndpoint,
} from "../context/constants";
import axios from "axios";
import { PiTreeStructure } from "react-icons/pi";
import InstructionModal from "../components/InstructionModal";
import ErrorModal from "../components/ErrorModal";
import SuccessModal from "../components/SuccessModal";
import { EllipsisOutlined, LeftOutlined } from "@ant-design/icons";
import _ from "lodash";
import AddUserModal from "../components/AddUserModal";
import EditUserModal from "../components/EditUserModal";
import { EmailClient } from "@azure/communication-email";

const { Sider, Content } = Layout;

export default function Contacts() {
  const navigate = useNavigate();
  const literals = useLiterals();
  const [collapsed, setCollapsed] = useState(false);
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [clickedCardIndex, setClickedCardIndex] = useState(null);
  const [articleData, setArticleData] = useState([]);
  const [showUserTable, setShowUserTable] = useState(false);
  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const [editModalRecord, setEditModalRecord] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [contactUsers, setContactUsers] = useState([]);
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [libraryEnableDisable, setLibraryEnableDisable] = useState(false);
  const [enableLibraryAccess, setEnableLibraryAccess] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deniedDomains, setDeniedDomains] = useState([]);
  const [signUpEmailData, setSignUpEmailData] = useState(null);
  const [tokenSentSuccessfully, setTokenSentSuccessfully] = useState(false);
  const [pleaseTryAgain, setPleaseTryAgain] = useState(false);
  const [locale, setLocale] = useState(
    () => localStorage.getItem("locale") || "en-us"
  );

  const primaryUser = localStorage.getItem("primaryUser");

  const handleOpenEdituserModal = (record) => {
    setEditUserModalVisible(true);
    setEditModalRecord(record);
  };

  const handleCloseEdituserModal = () => {
    setEditUserModalVisible(false);
  };

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleManageUsers = () => {
    setShowUserTable(true);
  };

  const handleOpenAdduserModal = () => {
    setAddUserModalVisible(true);
  };

  const handleCloseAdduserModal = () => {
    setAddUserModalVisible(false);
  };

  const handleToggleTableState = () => {
    setShowUserTable(!showUserTable);
  };

  const handleOpenLibraryEnableDisable = () => {
    setLibraryEnableDisable(true);
  };
  const handleCloseLibraryEnableDisable = () => {
    setLibraryEnableDisable(false);
  };

  const handleOpenEnableLibraryAccess = () => {
    setEnableLibraryAccess(true);
  };
  const handleCloseEnableLibraryAccess = () => {
    setEnableLibraryAccess(false);
  };

  const handleOpenTokenSentSuccessfully = () => {
    setTokenSentSuccessfully(true);
  };
  const handleCloseTokenSentSuccessfully = () => {
    setTokenSentSuccessfully(false);
  };

  const handleOpenPleaseTryAgain = () => {
    setPleaseTryAgain(true);
  };
  const handleClosePleaseTryAgain = () => {
    setPleaseTryAgain(false);
  };

  const fetchDeniedDomains = async () => {
    try {
      const ddContentType = "denied_domains";
      const endPoint = getContentEntriesEndpoint(ddContentType, locale);
      const headers = EnteriesHeaders;
      const response = await axios.get(endPoint, { headers });
      setDeniedDomains(response.data.entries);
    } catch (error) {
      console.log("Failed to fetch denied domains");
    }
  };

  useEffect(() => {
    fetchDeniedDomains();
  }, []);

  const markRemoveEmp = async (recordUid) => {
    try {
      const contentType = "application_user";
      const uid = recordUid;
      const headers = EnteriesHeaders;
      const endpoint = updateContentEntriesEndpoint(contentType, uid, locale);

      const payload = {
        entry: {
          user_status: {
            removed: true,
          },
        },
      };

      await axios.put(endpoint, payload, { headers });
    } catch (error) {
      console.log("Error removing user!");
    }
  };

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  useEffect(() => {
    const handleLocaleChange = () => {
      const storedLocale = localStorage.getItem("locale");
      setLocale(storedLocale || "en-us");
    };

    window.addEventListener("storage", handleLocaleChange);

    return () => {
      window.removeEventListener("storage", handleLocaleChange);
    };
  }, [locale]);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const fcContentType = "companies";
        const fcUid = userData?.company_uid;
        const endPoint = updateContentEntriesEndpoint(
          fcContentType,
          fcUid,
          locale
        );

        const fcauContentType = "application_user";
        const usersEndPoint = getContentEntriesEndpoint(
          fcauContentType,
          locale
        );

        const headers = EnteriesHeaders;
        const response = await axios.get(endPoint, {
          headers,
        });
        const userResponse = await axios.get(usersEndPoint, {
          headers,
        });

        const employees = userResponse.data.entries.filter(
          (emp) => emp.company_name === companyData?.title
        );
        setTableData(employees);

        const companyInfo = response.data.entry;
        setContactUsers(companyInfo);
        setCompanyData(companyInfo);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    fetchCompany();
  }, [locale, userData, companyData]);

  const handleOpenhelpuserModal = () => {
    setHelpModalVisible(true);
  };

  const handleClosehelpuserModal = () => {
    setHelpModalVisible(false);
  };

  const modalContentRef = useRef(null);

  const updateArticleViews = async (articleId) => {
    try {
      const contentType = "article_control";
      const endpoint = getContentEntriesEndpoint(contentType, locale);
      const headers = EnteriesHeaders;

      const res = await axios.get(endpoint, { headers });
      const existingArticle = res?.data?.entry;

      if (existingArticle && existingArticle.title === articleId) {
        const updatedViews = (existingArticle.number_of_views || 0) + 1;

        const payload = {
          entry: {
            number_of_views: updatedViews,
            indexed: true,
          },
        };

        await axios.put(endpoint, payload, { headers });

        console.log(
          `Number of views updated for article ${articleId}: ${updatedViews}`
        );
      } else {
        console.log(
          `Article with id ${articleId} not found. Creating new entry...`
        );

        const payload = {
          entry: {
            title: articleId,
            number_of_views: 1,
            indexed: true,
          },
        };

        await axios.post(endpoint, payload, { headers });

        console.log(`New entry created for article ${articleId}`);
      }
    } catch (error) {
      console.error("Error updating article views:", error);
    }
  };

  const handleOpenModal = (index) => {
    const clickedArticle = articleData[index];
    if (clickedArticle) {
      updateArticleViews(clickedArticle.uid);
    }
    setModalVisible(true);
    setClickedCardIndex(index);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const data =
    contactUsers &&
    contactUsers.contact_details &&
    contactUsers?.contact_details.map((user) => user.contact_details);

  const dataIndexMap = {
    name: showUserTable ? "user_name" : "name",
    email: showUserTable ? "title" : "email",
  };

  const filteredTableData = tableData.filter(
    (user) => !user.user_status || !user.user_status.removed
  );

  const enableLibrary = async (record) => {
    setLoading(true);
    try {
      const contentType = "application_user";
      const endPoint = updateContentEntriesEndpoint(
        contentType,
        record.uid,
        locale
      );
      const headers = EnteriesHeaders;

      const payload = {
        entry: {
          preferences_and_settings: {
            library: !record.preferences_and_settings.library,
          },
        },
      };

      await axios.put(endPoint, payload, { headers });

      // Update localStorage
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      userData.preferences_and_settings.library =
        !record.preferences_and_settings.library;
      localStorage.setItem("userData", JSON.stringify(userData));

      setLoading(false);
      handleOpenLibraryEnableDisable();
    } catch (error) {
      handleOpenEnableLibraryAccess();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSignUpEmailData(locale);
        setSignUpEmailData(response);
      } catch (error) {
        console.error("Error fetching signup email data:", error);
      }
    };

    fetchData();
  }, []);

  const handleAddNewUser = async (values) => {
    setLoading(true);
    try {
      const emailDomain = values.title.split("@")[1];
      const deniedDomainsList = deniedDomains.map((entry) => entry.title);
      if (deniedDomainsList.includes(emailDomain)) {
        throw new Error("Only professional emails are allowed");
      }

      const euContentType = "application_user";
      const euEndpoint = getContentEntriesEndpoint(euContentType, locale);
      const existingUserResponse = await axios.get(euEndpoint, {
        headers: EnteriesHeaders,
      });
      if (
        existingUserResponse.data.entries.find(
          (userEmail) => userEmail.title === values.title
        )
      ) {
        throw new Error("This email is already registered.");
      }

      const endPoint = euEndpoint;
      const headers = EnteriesHeaders;
      const payload = {
        entry: {
          user_name: values.title,
          company_name: companyData.title,
          company_uid: companyData.uid,
          title: values.email,
          contact_number: values.contact_number,
          preferences_and_settings: {
            library: values.library,
          },
        },
      };
      const res = await axios.post(endPoint, payload, { headers });
      const connectionString = AzureConnectionUrl;
      const client = new EmailClient(connectionString);

      const emailMessage = {
        senderAddress: signUpEmailData?.sender_email,
        content: {
          subject: signUpEmailData?.email_subject,
          html: `<!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <script
                src="https://kit.fontawesome.com/fab1f8bdcb.js"
                crossorigin="anonymous"
              ></script>
              <title>Email Template</title>
            </head>
            <body>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #dcf0ec;
                "
              >
                <div
                  style="
                    background-color: #ffffff;
                    margin-top: 5%;
                    margin-bottom: 5%;
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  "
                >
                <img
                src="https://images.contentstack.io/v3/assets/blt6f0f282d20bbe00b/blt58e0bed74aba1c39/65dcad6b6e7edbf719b4ec54/WhatsApp_Image_2024-02-08_at_17.16.53.jpeg"
                style="object-fit: cover; padding-top: 20px"
                alt="ChemAId Logo"
                width="158px"
              />
              <hr style="height: 1px; border: none; background-color: #3ac1cb" />
              <div style="padding-left: 30px; padding-right: 30px;">
                <p
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 36px;
                    opacity: 80%;
                  "
                >
                  Dear <strong>${values.title}</strong>,
                </p>
                <p
                style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              "
                >
                  ${signUpEmailData?.email_message?.json?.children[2]?.children[0]?.text}
                </p>
      
                <div
                  style="display: flex; align-items: center; justify-content: center"
                >
                  <div style="background-color: #eef0f8; padding: 3px 20px">
                    <p
                      id="textToCopy"
                      style="font-size: 16px; font-weight: 400; opacity: 40%"
                    >
                      <i class="fa-solid fa-lock"></i>
                      ${res.data.entry.uid}
                    </p>
                  </div>
      
                  <button
                    onclick="copyText()"
                    style="
                      font-size: 16px;
                      font-weight: 500;
                      color: #ffffff;
                      border: none;
                      background-color: #3ac1cb;
                      padding: 20px;
                      cursor: pointer;
                    "
                  >
                    <i class="fa-regular fa-copy"></i> Copy
                  </button>
                </div>      
                <p
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  "
                >
                  ${signUpEmailData?.email_message?.json?.children[6]?.children[0]?.text}
                </p>
                <p
                style="
                  font-size: 14px;
                  font-weight: 400;
                  opacity: 80%;
                  line-height: 20px;
                "
              >
                ${signUpEmailData?.email_message?.json?.children[8]?.children[0]?.text}
              </p>
                <ul>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[0]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[0]?.children[0]?.children[1]?.text}</p>
                  </li>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[1]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[1]?.children[0]?.children[1]?.text}</p>
                  </li>
                  <li style="display: flex; flex-direction: row;">
                    <p style="
                    font-size: 14px;
                    opacity: 80%;
                    line-height: 20px;
                    padding-right: 10px;
                  "><strong>${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[0]?.text}</strong></p>
                    <p style="
                    font-size: 14px;
                    font-weight: 400;
                    opacity: 80%;
                    line-height: 20px;
                  ">${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[1]?.text}<strong>${signUpEmailData?.email_message?.json?.children[9]?.children[2]?.children[0]?.children[2]?.text}</strong></p>
                  </li>
                </ul>
                <p style="
                font-size: 14px;
                opacity: 80%;
                line-height: 20px;
              "><strong>${signUpEmailData?.email_message?.json?.children[10]?.children[0]?.text}</strong>${signUpEmailData?.email_message?.json?.children[10]?.children[1]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[11]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[12]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[14]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                font-weight: 400;
                opacity: 80%;
                line-height: 20px;
              ">${signUpEmailData?.email_message?.json?.children[15]?.children[0]?.text}</p>
                <p style="
                font-size: 14px;
                opacity: 80%;
                line-height: 20px;
              "><strong>${signUpEmailData?.email_message?.json?.children[15]?.children[2]?.text}</strong></p>
                <p
                  style="
                    font-size: 15px;
                    font-weight: 400;
                    opacity: 60%;
                    text-align: center;
                    line-height: 22.5px;
                  "
                >
                2023 CHEM&#8226;AID<sup>TM</sup>&#9135; All rights reserved.
                </p>
              </div>
                </div>
              </div>
              <script>
                  function copyText() {
                  const textToCopy = document.getElementById("textToCopy").innerText;
                  navigator.clipboard.writeText(textToCopy)
                  .then(() => {
                  alert('Copied to clipboard!');
                  })
                  .catch((error) => {
                  console.error('Unable to copy:', error);
                  alert('Failed to copy text!');
                  });
                  }
              </script>
            </body>
          </html>
          `,
        },
        recipients: {
          to: [{ address: values.email }],
          bcc: [{ address: signUpEmailData.bcc }],
        },
      };

      const poller = await client.beginSend(emailMessage);
      await poller.pollUntilDone();
      setLoading(false);
      handleOpenTokenSentSuccessfully();
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (error) {
      console.log("error adding user", error);
      handleOpenPleaseTryAgain();
    }
  };

  const sortedData = _.sortBy(data, [dataIndexMap["name"]]);
  const sortedFilteredTableData = _.sortBy(filteredTableData, [
    dataIndexMap["name"],
  ]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="md"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleToggleCollapsed}
        style={{
          maxWidth: "12%",
          position: "relative",
        }}
      >
        <div className="demo-logo-vertical text-center m-1 my-3">
          <img src={image} width={collapsed ? 55 : 95} alt="Logo" />
        </div>
        <Menu theme="light" mode="inline" onClick={() => navigate("/home")}>
          <Menu.Item key="1" icon={<HomeIcon />}>
            {literals?.sidebar?.home}
          </Menu.Item>
        </Menu>
        <Menu theme="light" mode="inline" onClick={() => navigate("/dialogue")}>
          <Menu.Item key="7" icon={<Dialogues />}>
            {literals?.sidebar?.dialogues}
          </Menu.Item>
        </Menu>
        {userData?.preferences_and_settings?.library === true && (
          <>
            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/library")}
            >
              <Menu.Item key="5" icon={<Library />}>
                {literals?.sidebar?.library_menu}
              </Menu.Item>
            </Menu>

            <Menu
              theme="light"
              mode="inline"
              onClick={() => navigate("/collection")}
            >
              <Menu.Item key="6" icon={<Collections />}>
                {literals?.sidebar?.collections_menu}
              </Menu.Item>
            </Menu>
          </>
        )}
        <Menu theme="light" mode="inline" defaultSelectedKeys={["2"]}>
          <SubMenu
            key="sub1"
            title={literals?.sidebar?.settings_menu}
            icon={<Settings />}
          >
            {primaryUser === "true" && (
              <>
                <Menu.Item
                  key="1"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/company")}
                >
                  {literals?.buttons?.company_btn}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/contacts")}
                >
                  {literals?.buttons?.contacts_btn}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<PiTreeStructure />}
                  onClick={() => navigate("/subscription")}
                >
                  {literals?.sidebar?.subscription_menu}
                </Menu.Item>
              </>
            )}
          </SubMenu>
        </Menu>
        <div
          style={{
            borderTop: "1px solid #FFFFFF1A",
            position: "absolute",
            bottom: "4rem",
            left: 0,
            width: "90%",
            marginLeft: "4%",
          }}
        />
        <Link
          onClick={handleOpenhelpuserModal}
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            color: "#fff",
            justifyContent: collapsed ? "center" : "flex-start",
          }}
        >
          <BsQuestionCircle
            className="question"
            style={{ color: "#fff", fontSize: 22 }}
          />
          {!collapsed && (
            <p style={{ fontSize: 15, fontWeight: 500, marginLeft: "1rem" }}>
              Help & Support
            </p>
          )}
        </Link>
      </Sider>
      <Layout>
        <MainHeader
          collapsed={collapsed}
          handleToggleCollapsed={handleToggleCollapsed}
          visible={modalVisible}
          onClose={handleCloseModal}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          clickedCardIndex={clickedCardIndex}
          handleOpenModal={handleOpenModal}
        />
        <Content style={{ padding: 20 }}>
          <div style={{ marginTop: 25 }}>
            {!showUserTable ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                {loading && <Spin fullscreen />}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h1 style={{ fontSize: 20, fontWeight: 700 }}>Contacts</h1>
                  <Button
                    style={{
                      background:
                        "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                      color: "#FFFFFF",
                      textTransform: "uppercase",
                      border: "none",
                      fontSize: 14,
                      fontWeight: 600,
                      height: 40,
                      paddingLeft: "1.5%",
                      paddingRight: "1.5%",
                      borderRadius: 200,
                    }}
                    onClick={handleManageUsers}
                  >
                    {literals?.buttons?.manage_users_btn}
                  </Button>
                </div>
                <Table
                  columns={[
                    {
                      title: `${literals?.placeholder_text?.contact_name}`,
                      dataIndex: "name",
                      render: (text) => (
                        <a
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#06152B",
                          }}
                        >
                          {text}
                        </a>
                      ),
                    },
                    {
                      title: `${literals?.placeholder_text?.email_address}`,
                      dataIndex: "email",
                      render: (text) => (
                        <a
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#06152B",
                          }}
                        >
                          {text}
                        </a>
                      ),
                    },
                    {
                      title: `${literals?.placeholder_text?.telephone}`,
                      dataIndex: "contact_number",
                      render: (text) => (
                        <a
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#06152B",
                          }}
                        >
                          {text}
                        </a>
                      ),
                    },
                    {
                      title: `${literals?.placeholder_text?.contact_type}`,
                      dataIndex: "contact_type",
                      render: (text) => (
                        <div>
                          <a
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#06152B",
                            }}
                          >
                            {text}
                          </a>
                        </div>
                      ),
                    },
                    {
                      title: `${literals?.library_page?.actions_name}`,
                      dataIndex: "",
                      key: "x",
                      // render: (text, record, index) => {
                      //   const { contact_type } = record;
                      //   let buttonText = literals?.buttons?.remove_btn;
                      //   if (contact_type === "PRIMARY") {
                      //     buttonText =
                      //       literals?.buttons?.remove_primary_btn ||
                      //       "Remove Primary";
                      //   } else if (contact_type === "SECONDARY") {
                      //     buttonText =
                      //       literals?.buttons?.remove_secondary_btn ||
                      //       "Remove Secondary";
                      //   }
                      //   return (
                      //     <div>
                      //       {index === 0 && (
                      //         <Button
                      //           style={{
                      //             borderStyle: "solid",
                      //             borderWidth: "2px",
                      //             borderColor: "#3AC1CB",
                      //             color: "#3AC1CB",
                      //             borderRadius: "8px",
                      //             paddingTop: "0.8%",
                      //             paddingBottom: "2.8%",
                      //             paddingLeft: "3%",
                      //             paddingRight: "3%",
                      //           }}
                      //         >
                      //           {/* {literals?.buttons?.remove_primary_btn} */}
                      //           {buttonText}
                      //         </Button>
                      //       )}
                      //       {index === 1 && (
                      //         <Button
                      //           style={{
                      //             borderStyle: "solid",
                      //             borderWidth: "2px",
                      //             borderColor: "#3AC1CB",
                      //             color: "#3AC1CB",
                      //             borderRadius: "8px",
                      //             paddingTop: "0.8%",
                      //             paddingBottom: "2.8%",
                      //             paddingLeft: "3%",
                      //             paddingRight: "3%",
                      //           }}
                      //         >
                      //           {/* {literals?.buttons?.remove_secondary_btn} */}
                      //           {buttonText}
                      //         </Button>
                      //       )}
                      //       {index > 1 && (
                      //         <Button
                      //           style={{
                      //             borderStyle: "solid",
                      //             borderWidth: "2px",
                      //             borderColor: "#3AC1CB",
                      //             color: "#3AC1CB",
                      //             borderRadius: "8px",
                      //             paddingTop: "0.8%",
                      //             paddingBottom: "2.8%",
                      //             paddingLeft: "3%",
                      //             paddingRight: "3%",
                      //           }}
                      //         >
                      //           {literals?.buttons?.remove_btn}
                      //         </Button>
                      //       )}
                      //       {/* <a>
                      //   <EditFilled
                      //     style={{
                      //       color: "#FFFFFF",
                      //       backgroundColor: "#3AC1CB",
                      //       marginLeft: "5%",
                      //       padding: "3.5%",
                      //       borderStyle: "solid",
                      //       borderWidth: "2px",
                      //       borderRadius: "5px",
                      //     }}
                      //     onClick={() => handleOpenEdituserModal(record)}
                      //   />
                      // </a> */}
                      //     </div>
                      //   );
                      // },
                      render: (_, record) => {
                        const menu = (
                          <Menu>
                            <Menu.Item
                              key="edit"
                              onClick={() => markRemoveEmp(record.uid)}
                            >
                              Remove
                            </Menu.Item>
                          </Menu>
                        );

                        return (
                          <Dropdown overlay={menu} trigger={["click"]}>
                            <EllipsisOutlined
                              style={{
                                fontSize: 24,
                                cursor: "pointer",
                                color: "#000",
                              }}
                            />
                          </Dropdown>
                        );
                      },
                    },
                  ]}
                  dataSource={sortedData}
                  bordered
                />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      type="text"
                      icon={<LeftOutlined />}
                      onClick={handleToggleTableState}
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Users
                    </Button>
                    <div>
                      <Button
                        style={{
                          background:
                            "linear-gradient(90deg, #3AC1CB 0%, #1D6065 100%)",
                          color: "#FFFFFF",
                          textTransform: "uppercase",
                          border: "none",
                          fontSize: 14,
                          fontWeight: 600,
                          height: 40,
                          width: 140,
                          borderRadius: 200,
                        }}
                        onClick={() => handleOpenAdduserModal()}
                      >
                        {literals?.buttons?.add_new_user_btn}
                      </Button>
                    </div>
                  </div>
                  <Table
                    columns={[
                      {
                        title: `${literals?.placeholder_text?.contact_name}`,
                        dataIndex: dataIndexMap["name"],
                        render: (text) => (
                          <a
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#06152B",
                            }}
                          >
                            {text}
                          </a>
                        ),
                      },
                      {
                        title: `${literals?.placeholder_text?.email_address}`,
                        dataIndex: dataIndexMap["email"],
                        render: (text) => (
                          <a
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#06152B",
                            }}
                          >
                            {text}
                          </a>
                        ),
                      },
                      {
                        title: `${literals?.placeholder_text?.telephone}`,
                        dataIndex: "contact_number",
                        render: (text) => (
                          <a
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: "#06152B",
                            }}
                          >
                            {text}
                          </a>
                        ),
                      },
                      {
                        title: "Library Access",
                        dataIndex: "library",
                        render: (text, record) => {
                          return (
                            <Button
                              onClick={() => enableLibrary(record)}
                              style={{
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#3AC1CB",
                                color: "#1B1B1B",
                                borderRadius: 200,
                                paddingTop: 3,
                                paddingBottom: 5,
                                paddingLeft: 15,
                                paddingRight: 15,
                                cursor: "pointer",
                                textTransform: "capitalize",
                              }}
                            >
                              {record?.preferences_and_settings?.library ===
                              false
                                ? "Enable"
                                : "Disable"}
                            </Button>
                          );
                        },
                      },
                      // {
                      //   title: "Contact Type",
                      //   dataIndex: "contact_type",
                      //   render: (text) => (
                      //     <div>
                      //       <a
                      //         style={{
                      //           fontSize: "18px",
                      //           fontWeight: 400,
                      //           opacity: "60%",
                      //         }}
                      //       >
                      //         {text}
                      //       </a>
                      //     </div>
                      //   ),
                      // },
                      {
                        title: `${literals?.library_page?.actions_name}`,
                        dataIndex: "",
                        key: "x",
                        // render: (record) => (
                        //   <div>
                        //     <Button
                        //       onClick={() => handleRemoveUser(record)}
                        //       style={{
                        //         borderStyle: "solid",
                        //         borderWidth: "2px",
                        //         borderColor: "#3AC1CB",
                        //         color: "#3AC1CB",
                        //         borderRadius: "8px",
                        //         paddingTop: "0.8%",
                        //         paddingBottom: "2.8%",
                        //         paddingLeft: "3%",
                        //         paddingRight: "3%",
                        //       }}
                        //     >
                        //       {literals?.buttons?.remove_btn}
                        //     </Button>
                        //     <a>
                        //       <EditFilled
                        //         style={{
                        //           color: "#FFFFFF",
                        //           backgroundColor: "#3AC1CB",
                        //           marginLeft: "5%",
                        //           padding: "3.5%",
                        //           borderStyle: "solid",
                        //           borderWidth: "2px",
                        //           borderRadius: "5px",
                        //         }}
                        //         onClick={() => handleOpenEdituserModal(record)}
                        //       />
                        //     </a>
                        //   </div>
                        // ),
                        render: (_, record) => {
                          const menu = (
                            <Menu>
                              <Menu.Item
                                key="remove"
                                onClick={() => markRemoveEmp(record.uid)}
                              >
                                Remove
                              </Menu.Item>
                              <Menu.Item
                                key="edit"
                                onClick={() => handleOpenEdituserModal(record)}
                              >
                                Edit
                              </Menu.Item>
                            </Menu>
                          );

                          return (
                            <Dropdown overlay={menu} trigger={["click"]}>
                              <EllipsisOutlined
                                style={{
                                  fontSize: 24,
                                  cursor: "pointer",
                                  color: "#000",
                                }}
                              />
                            </Dropdown>
                          );
                        },
                      },
                    ]}
                    dataSource={sortedFilteredTableData}
                    bordered
                  />
                </div>
              </>
            )}
            <AddUserModal
              visible={addUserModalVisible}
              onClose={handleCloseAdduserModal}
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              onFinish={handleAddNewUser}
              literals={literals}
            />
            <EditUserModal
              visible={editUserModalVisible}
              onClose={handleCloseEdituserModal}
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              record={editModalRecord}
              literals={literals}
            />
            <SuccessModal
              open={libraryEnableDisable}
              onCancel={handleCloseLibraryEnableDisable}
              message="Library updated successfully"
            />
            <ErrorModal
              open={enableLibraryAccess}
              onCancel={handleCloseEnableLibraryAccess}
              message={
                literals?.toast_messages_text
                  ?.error_enabling_library_access_for_this_user
              }
            />
            <SuccessModal
              open={tokenSentSuccessfully}
              onCancel={handleCloseTokenSentSuccessfully}
              message={
                literals?.toast_messages_text
                  ?.user_registered_and_token_send_successfully
              }
            />
            <ErrorModal
              open={pleaseTryAgain}
              onCancel={handleClosePleaseTryAgain}
              message={
                literals?.toast_messages_text
                  ?.registration_failed_please_try_again
              }
            />
          </div>
        </Content>
      </Layout>
      <InstructionModal
        visible={helpModalVisible}
        onClose={handleClosehelpuserModal}
        modalContentRef={modalContentRef}
      />
    </Layout>
  );
}
