import { Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import ReactHtmlParser from "react-html-parser";
import {
  EnteriesHeaders,
  getContentEntriesEndpoint,
} from "../context/constants";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function InstructionModal({
  visible,
  onClose,
  modalContentRef,
}) {
  const [instruction, setInstruction] = useState([]);

  const content_type = "instructions";

  const locale = localStorage.getItem("locale");

  useEffect(() => {
    const fetchInstruction = async () => {
      try {
        const endpoint = getContentEntriesEndpoint(content_type, locale);
        const headers = EnteriesHeaders;
        const res = await axios.get(endpoint, { headers });
        setInstruction(res.data?.entries);
        if (modalContentRef.current) {
          modalContentRef.current.scrollTop = 0;
        }
      } catch (error) {
        console.log("Something went wrong");
      }
    };

    fetchInstruction();
  }, [visible, modalContentRef, locale]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width={1200}
      centered
      styles={{ maxHeight: "calc(100vh - 160px)", overflowY: "auto" }}
    >
      <button
        onClick={onClose}
        style={{
          border: "none",
          boxShadow: "none",
          backgroundColor: "#F3F5F5",
          position: "absolute",
          right: 5,
          top: 5,
        }}
      >
        <Icon icon="mingcute:close-line" width={24} />
      </button>
      {instruction && instruction.length > 0 && (
        <div
          ref={modalContentRef}
          id="instructionModalContent"
          className="content_modal"
        >
          <div>
            <h1>{instruction[0]?.title}</h1>
            <div>{ReactHtmlParser(jsonToHtml(instruction[0]?.overview))}</div>
            {instruction[0] &&
              instruction[0].modular_blocks &&
              instruction[0].modular_blocks.map((block, index) => (
                <div key={index}>
                  <h2
                    style={{
                      color: "#548235",
                      paddingTop: 20,
                    }}
                  >
                    {block?.step_by_step_instructions?.title}
                  </h2>
                  <p>
                    {ReactHtmlParser(
                      jsonToHtml(block?.step_by_step_instructions?.description)
                    )}
                  </p>
                  <p>
                    {ReactHtmlParser(
                      jsonToHtml(block?.step_by_step_instructions?.instruction)
                    )}
                  </p>
                  <img
                    src={
                      block?.step_by_step_instructions?.image_or_screenshot?.url
                    }
                    alt={
                      block?.step_by_step_instructions?.image_or_screenshot
                        ?.title
                    }
                    width="100%"
                    height="auto"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </Modal>
  );
}
